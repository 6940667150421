import { H2, Body1 } from "melodies-source/Text";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { SvgRight } from "melodies-source/Svgs/Right";
import { InfoCard } from "Components/InfoCard";
import { PublishButton } from ".";
import { IconPublishCard } from "Images/CardIcons";
import { useBuilderContext, useConcerts } from "Components";

export const Publish = () => {
  const nav = useNavigate();
  const [concertData, concertLoading, b, concerts] = useConcerts();
  const {
    builderId,
    publishStatus,
    config: { artistGroupId },
  } = useBuilderContext();

  const returnToAP = () => {
    const url = new URL(
      process.env.REACT_APP_ARTIST_PORTAL_SHOWS?.replace(
        "{id}",
        artistGroupId || ""
      ) || ""
    );
    if (publishStatus === "complete") {
      url.search = `b=${builderId}`;
    }
    window.location.href = url.toString();
  };

  return (
    <Container>
      <H2 style={{ marginRight: 50 }}>Your experience is looking good!</H2>
      <Body1>
        Personalize your event further by customizing merchandise, links,
        colors, and ways for your fans to connect:
      </Body1>
      <Button
        style={{ width: "fit-content", margin: "15px 0 20px" }}
        onClick={() => {
          nav("../editor");
        }}
      >
        Continue Customization
      </Button>
      <Body1>Or, if you are ready to use it:</Body1>
      <PublishButton
        style={{
          width: 200,
          color: "#fff",
        }}
        rightIcon={<SvgRight />}
        variant="outlined"
        nudgeRight
        status="published"
        callback={
          !concerts?.length
            ? () => returnToAP()
            : () => nav("../postpublish", { state: { created: true } })
        }
      />

      <InfoCard
        text="This experience has been saved as a draft. You can close and come back any time to edit. While in draft mode, an experience will not be available for use at a show."
        icon={<IconPublishCard />}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  max-width: 350px;
  gap: 15px;

  ${H2} {
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
  }
  ${Body1} {
    font-weight: 500;
  }
`;
