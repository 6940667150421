import { LogoKnockout } from "Images";
import styled from "styled-components";

export const Footer = () => {
  return (
    <Container>
      <LogoKnockout />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000000;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 32px;
`;
