import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { SvgCheck } from "../Svgs/Check";
import { Button } from "../Button";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem } from "../ListItem";
import { SelectMenu as SelectMenuBase, SelectMenuDiv } from "../SelectMenu";
import { Body2, Label, Selected, Caption } from "../Text";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";

export type Option = {
  label: string;
  value: any;
};

type CheckIconProps = {
  isSelected: boolean;
};
const CheckIconWrapper = styled.div<CheckIconProps>`
  ${(p) =>
    p.isSelected
      ? css`
          color: #ffffff;
        `
      : css`
          opacity: 0;
        `}
`;

const SelectMenu = styled(SelectMenuBase)<{ hasButton?: boolean }>`
  ${({ hasButton }) =>
    hasButton &&
    css`
      ${SelectMenuDiv} {
        padding: 10px 0 0;
      }
    `}
`;

type ListItemWrapperProps = {
  isFirst: boolean;
};
const ListItemWrapper = styled.div<ListItemWrapperProps>`
  ${(p) => !p.isFirst && css``}
`;

type SelectedValueProps = {
  hasError?: boolean;
};
const SelectedValue = styled.div<SelectedValueProps>`
  background-color: #ffffff;
  color: #333333;
  text-align: center;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: solid 1px ${(p) => p.theme.colors.black20};
  padding: 11px 12px;
  user-select: none;
  cursor: pointer;
  justify-content: space-between;
  margin-top: 4px;
  ${(p) =>
    p.hasError &&
    css`
      border: solid 1px ${(p) => p.theme.colors.error};
    `}
`;

type WrapperProps = {
  disabled?: boolean;
  secondary?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  color: #ffffff;
  ${Body2} {
    color: #999999;
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${(p) =>
    p.secondary &&
    css`
      ${Label} {
        color: #999999;
      }
      ${Body2} {
        font-family: var(--max-font-primary);
        font-weight: 300;
        font-size: 18px;
      }
      ${SelectedValue} {
        background: transparent;
        border-radius: 0px;
        padding: 6px 12px 5px 0;
        height: inherit;
        color: #ffffff;
        border: none;
        border-bottom: 1px solid #666666;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        transition: ${(p) => p.theme.transitions("border-color").hover};
        &:focus {
          border-bottom-color: #ffffff;
        }
      }
      ${Selected} {
        font-size: 20px;
        font-weight: 400;
      }
    `};
`;
export const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;

export const Icon = styled.div`
  margin-right: 9px;
  text-align: center;
  color: #ffffff;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #999999;
  margin-top: 10px;
  button {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-left: 5px;
    svg {
      width: 17px;
    }
  }
`;

type Props = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string | undefined | null;
  hasError?: boolean;
  icon?: JSX.Element;
  helperText?: string;
  buttonText?: string;
  onChange: (v: string) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  options: Option[];
  style?: any;
  secondary?: boolean;
};
export const Select = ({
  label,
  placeholder = "Select Option",
  value,
  icon,
  hasError,
  helperText,
  buttonText,
  onChange,
  onClick,
  options,
  disabled,
  ...props
}: Props) => {
  const [updatedText, setUpdatedText] = useState(false);
  const [text, setText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setUpdatedText(true);
  }, [text]);

  useEffect(() => {
    setUpdatedText(false);
  }, [isMenuOpen]);

  const onSelect = (v: string) => {
    setText(options.find((o) => o.value === v)?.label || "");
    onChange(v);
    setIsMenuOpen(false);
  };

  const filteredOptions = updatedText
    ? options.filter((o) => o.label.toLowerCase().includes(text.toLowerCase()))
    : options;

  return (
    <Wrapper {...props} disabled={disabled}>
      {label && <Label style={{ marginBottom: 0 }}>{label}</Label>}
      <div onClick={() => setIsMenuOpen(true)}>
        <SelectedValue {...{ hasError, disabled }}>
          <IconWrap>
            {icon && <Icon>{icon}</Icon>}
            {!value || value === "" ? (
              <Body2>{placeholder}</Body2>
            ) : (
              <Selected>
                {options.find((o) => o.value === value)?.label || value}
              </Selected>
            )}
            {/* <div style={{ width: "20px", marginLeft: 10 }}> */}
            <SvgDropdown />
            {/* </div> */}
          </IconWrap>
        </SelectedValue>
      </div>
      <SelectMenu
        hasButton={!!buttonText}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        {filteredOptions.map((o, index) => (
          <ListItemWrapper key={index} isFirst={index === 0}>
            <ListItem
              rightIcon={
                <CheckIconWrapper isSelected={o.value === value}>
                  <SvgCheck />
                </CheckIconWrapper>
              }
              isSelected={value === o.value}
              onClick={() => onSelect(o.value)}
            >
              {o.label}
            </ListItem>
          </ListItemWrapper>
        ))}
        {buttonText && (
          <ButtonWrap>
            <Button onClick={onClick} text leftIcon={<SvgAddAlt />}>
              {buttonText || "Add a custom option"}
            </Button>
          </ButtonWrap>
        )}
      </SelectMenu>
      {helperText && <Caption style={{ marginTop: 4 }}>{helperText}</Caption>}
    </Wrapper>
  );
};
