import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import { SvgCheck } from "../Svgs/Check";
import { Button } from "../Button";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem } from "../ListItem";
import { SelectMenu, SelectMenuDiv } from "../SelectMenu";
import { Label, Selected, Caption, H4 } from "../Text";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { TextInput } from "melodies-source/TextInput";

type Option = {
  label: string;
  value: string;
  id?: string | number;
};
type Options = {
  group: string;
  items: Option[];
};

type CheckIconProps = {
  isSelected: boolean;
};
const CheckIconWrapper = styled.div<CheckIconProps>`
  ${(p) =>
    p.isSelected
      ? css`
          color: #ffffff;
        `
      : css`
          opacity: 0;
        `}
`;

type WrapperProps = {
  disabled?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  color: #ffffff;
  ${Label} {
    color: #999999;
  }
  ${SelectMenuDiv} {
    padding: 0;
    max-height: 300px;
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
export const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  ${Selected} {
    font-size: 20px;
    font-weight: 400;
  }
`;

export const Icon = styled.div`
  margin-right: 9px;
  text-align: center;
  color: #ffffff;
`;

export const GroupName = styled(H4)`
  color: #999999;
  padding: 8px 0 0 12px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #999999;
  button {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-weight: 500;
    svg {
      margin-right: 6px;
    }
  }
`;

const InputWrapper = styled.div`
  input {
    pointer-events: none;
    text-overflow: ellipsis;
  }
`;

type Props = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string | undefined | null;
  hasError?: boolean;
  icon?: JSX.Element;
  helperText?: string;
  buttonText?: string;
  onChange: (v: string) => void;
  setCustom: VoidFunction;
  options: Options[];
  style?: any;
  secondary?: boolean;
};
export const SelectMerchMenu = ({
  label,
  placeholder = "Select Option",
  value,
  icon,
  hasError,
  helperText,
  buttonText,
  onChange,
  setCustom,
  options,
  disabled,
  secondary,
  ...props
}: Props) => {
  const [updatedText, setUpdatedText] = useState(false);
  const [text, setText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setUpdatedText(true);
  }, [text]);

  useEffect(() => {
    setUpdatedText(false);
  }, [isMenuOpen]);

  const onSelect = (v: Option) => {
    setText(v?.label || "");
    onChange(v.value);
    setIsMenuOpen(false);
  };

  return (
    <Wrapper {...props} disabled={disabled}>
      <InputWrapper onClick={() => setIsMenuOpen(true)}>
        <TextInput
          label={label}
          hasError={hasError}
          secondary={secondary}
          rightIcon={<SvgDropdown />}
          placeholder={placeholder}
          value={value || ""}
          onChange={() => {}}
        />
      </InputWrapper>
      <SelectMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <OptionsContainer>
          {options?.map((o: Options, index: number) => (
            <>
              <GroupName key={index}>{o.group}</GroupName>
              {o?.items?.map((item: Option, idx: number) => (
                <ListItem
                  key={idx}
                  rightIcon={
                    <CheckIconWrapper isSelected={item.value === value}>
                      <SvgCheck />
                    </CheckIconWrapper>
                  }
                  isSelected={value === item.value}
                  onClick={() => onSelect(item)}
                  style={{
                    paddingLeft: 32,
                    ...(item.value === value && { backgroundColor: "#262028" }),
                  }}
                >
                  {item.label}
                </ListItem>
              ))}
            </>
          ))}
        </OptionsContainer>
        {buttonText && (
          <ButtonWrap>
            <Button
              text
              leftIcon={<SvgAddAlt />}
              onClick={() => {
                setIsMenuOpen(false);
                setCustom();
              }}
            >
              {buttonText || "Add a custom option"}
            </Button>
          </ButtonWrap>
        )}
      </SelectMenu>
      {helperText && (
        <Caption hasError={hasError} style={{ marginTop: 4 }}>
          {helperText}
        </Caption>
      )}
    </Wrapper>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 255px;
  padding: 8px 0;
  ${scrollBarDarkCss};
`;
