import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { Slice, SliceInternal } from "../Slice";
import { IconCheckIn } from "Images/Icons";
import { IconVote } from "Images/Icons";
import { Body2, Error, H4 } from "melodies-source/Text";
import { Checkbox, Radio } from "melodies-source/Selectable";
import { SelectMerchMenu } from "melodies-source/SelectMerchMenu";
import useSpotify from "Components/hooks/useSpotify";
import AddSongModal from "Components/AddSongModal";
import { useState } from "react";
import { useBuilderContext, useConfigSlice } from "Components";
import { SvgMusicAlt } from "melodies-source/Svgs/MusicAlt";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { Fields, generatePreRegText } from "@max/common/creator";
import { Textarea } from "melodies-source/Textarea";
import { useModalContext } from "Components/ModalContext";
import { Link } from "melodies-source/Link";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { Warning } from "../Components/common";

const fieldSlices: Fields[] = [
  "checkInVote",
  "preRegistrationHeadline",
  "preRegistrationBody",
  "preRegistrationSubtitle",
  "preRegistrationButtonLabel",
  "voteType",
  "voteOptions",
  "songOptions",
  "sweepstakes",
  "sweepstakesPrize",
  "sweepstakesPrizeCustom",
  "sweepstakesPrizeTerms",
  "privacyPolicies",
  "privacyPolicyDeclined",
  "useCustomTermsAndPrivacyPolicy",
];

export const CheckInVote = () => {
  const [fields] = useConfigSlice(["checkInVote"]);

  return (
    <Slice
      fields={fieldSlices}
      content={Internal}
      title={fields?.checkInVote?.content === "vote" ? "Vote" : "Check In"}
      icon={
        fields?.checkInVote?.content === "vote" ? <IconVote /> : <IconCheckIn />
      }
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isValid,
  isActive,
  warnings,
}: SliceInternal) => {
  const [searchInput, setSearchInput] = useState<string[]>([]);
  const [openIndex, setOpenIndex] = useState(0);
  const { config } = useBuilderContext();
  const { setModal } = useModalContext();

  const { prizeOptions, preRegText } = generatePreRegText(config);

  const handleCustomOpen = () => {
    setField("sweepstakesPrize", "customPrize");
  };

  const handleSelectPrize = (v: string) => {
    setField("sweepstakesPrize", v);
    setField("sweepstakesPrizeCustom", "");
    setModal({ confirmCopyChange: {} });
  };

  const handleCheck = () => {
    setField("sweepstakesPrizeTerms", !fields.sweepstakesPrizeTerms?.content);
  };

  const token = useSpotify();

  const getOptions = async (searchInput: string) => {
    let artistName = config?.artistName;
    if (!searchInput) return [];
    if (token && artistName) {
      const artistParameters = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = new URL("https://api.spotify.com/v1/search");
      const params = new URLSearchParams({
        q: `${searchInput} artist:${artistName}`,
        type: "track",
      });
      url.search = params.toString();
      return fetch(url.toString(), artistParameters)
        .then((res) => res.json())
        .then((data) => {
          return data?.tracks?.items?.map((track: any) => ({
            value: track.name,
            label: track.name,
            caption: track.album.name,
            icon: (
              <SvgMusicAlt
                style={{ width: 15, height: 15, marginTop: "-5px" }}
              />
            ),
            spotifyId: track.id,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const isCheckIn = fields.checkInVote.content === "checkin";
  const customOpen = fields.sweepstakesPrize.content === "customPrize";

  return (
    <Wrapper>
      <CheckInVoteWrapper>
        <CheckinContainer>
          <TextInput
            placeholder={preRegText?.headline}
            label={fields.preRegistrationHeadline.label}
            helperText={
              validation.preRegistrationHeadline ||
              (warnings.preRegistrationHeadline && (
                <Warning>{warnings.preRegistrationHeadline}</Warning>
              )) ||
              "Limit 50 characters"
            }
            onChange={(v) => setField("preRegistrationHeadline", { en: v })}
            value={fields.preRegistrationHeadline.content?.en || ""}
            hasError={!!validation.preRegistrationHeadline}
          />
          <Textarea
            key={fields.checkInVote.content}
            placeholder={isCheckIn ? preRegText?.body : preRegText?.subtitle}
            label={
              isCheckIn
                ? fields.preRegistrationBody.label
                : fields.preRegistrationSubtitle.label
            }
            helperText={
              (isCheckIn
                ? validation.preRegistrationBody ||
                  (warnings.preRegistrationBody && (
                    <Warning>{warnings.preRegistrationBody}</Warning>
                  ))
                : validation.preRegistrationSubtitle ||
                  (warnings.preRegistrationSubtitle && (
                    <Warning>{warnings.preRegistrationSubtitle}</Warning>
                  ))) || "Limit 200 characters"
            }
            onChange={
              isCheckIn
                ? (v) => setField("preRegistrationBody", { en: v })
                : (v) => setField("preRegistrationSubtitle", { en: v })
            }
            value={
              isCheckIn
                ? fields.preRegistrationBody?.content?.en
                : fields.preRegistrationSubtitle?.content?.en || ""
            }
            hasError={
              isCheckIn
                ? !!validation.preRegistrationBody
                : !!validation.preRegistrationSubtitle
            }
          />
          {isCheckIn && (
            <TextInput
              placeholder="Save"
              helperText={
                validation.preRegistrationButtonLabel ||
                (warnings.preRegistrationButtonLabel && (
                  <Warning>{warnings.preRegistrationButtonLabel}</Warning>
                )) ||
                "Limit 30 characters"
              }
              label={fields.preRegistrationButtonLabel.label}
              onChange={(v) =>
                setField("preRegistrationButtonLabel", { en: v })
              }
              value={fields.preRegistrationButtonLabel?.content?.en || ""}
              hasError={!!validation.preRegistrationButtonLabel}
            />
          )}
        </CheckinContainer>

        {fields.checkInVote.content === "vote" && (
          <VoteWrapper>
            <H4>Select what your fans will be voting on:</H4>
            <Body2>
              *Fans will not be able to see the results of the vote.
            </Body2>
            <RadioContainer>
              <Radio
                variant="radio"
                value={fields.voteType.content === "song"}
                label="Song"
                onChange={() => {
                  setField("voteType", "song");
                  setModal({ confirmCopyChange: {} });
                }}
              />
              <Radio
                variant="radio"
                value={fields.voteType.content === "vote"}
                label="Something Else"
                onChange={() => {
                  setField("voteType", "vote");
                  setModal({ confirmCopyChange: {} });
                }}
              />
            </RadioContainer>
            {(validation.songOptions || validation.voteOptions) && (
              <Error style={{ marginTop: 8 }}>
                {validation.songOptions || validation.voteOptions}
              </Error>
            )}
            {fields.voteType.content === "song" && (
              <>
                {fields?.songOptions?.content?.map((o: any, idx: number) => {
                  return (
                    <VoteContainer key={idx}>
                      <AutocompleteAsync
                        text={o.name || searchInput?.[idx]}
                        setText={(v: string) => {
                          let copy = [...searchInput!] || [];
                          copy[idx] = v;
                          setSearchInput(copy);
                        }}
                        onChange={(v: any) => {
                          let update = [...fields.songOptions.content!];
                          update[idx] = {
                            name: v.label,
                            album: v.caption || "",
                            spotifyId: v.spotifyId,
                          };
                          setField("songOptions", update);
                        }}
                        label={`Song ${idx + 1}`}
                        placeholder="Search for a song"
                        getOptions={getOptions}
                        hideDropdown={false}
                        buttonText="Manually add a song"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        setCustom={() => {
                          setOpenIndex(idx + 1);
                        }}
                        hasError={!!(!!validation.songOptions && !o.name)}
                        helperText={!o.name ? validation.songOptions || "" : ""}
                      />

                      <Button
                        variant="primary"
                        text
                        style={{
                          visibility:
                            (idx === 0 || idx === 1) &&
                            fields?.songOptions?.content!.length < 3
                              ? "hidden"
                              : "visible",
                        }}
                        onClick={() => {
                          let update = [...fields.songOptions.content!];
                          update.splice(idx, 1);
                          setField("songOptions", update);
                          let listUpdate = [...searchInput];
                          listUpdate.splice(idx, 1);
                          setSearchInput(listUpdate);
                        }}
                      >
                        <SvgDelete style={{ width: 50, height: 50 }} />
                      </Button>
                    </VoteContainer>
                  );
                })}

                <Button
                  variant="primary"
                  text
                  style={{
                    textDecoration: "underline",
                    margin: "16px auto 0 0",
                    width: "fit-content",
                    display: fields.songOptions?.content?.find(
                      (song: any) => song.name === "",
                    )
                      ? "none"
                      : "flex",
                  }}
                  onClick={() => {
                    let update = [...fields?.songOptions?.content!];
                    update.push({ name: "", album: "" });
                    setField("songOptions", update);
                  }}
                >
                  + Add a song
                </Button>
              </>
            )}

            {fields.voteType.content === "vote" && (
              <>
                {fields.voteOptions.content?.map((o: any, idx: number) => {
                  return (
                    <VoteContainer key={idx}>
                      <TextInput
                        placeholder="Option"
                        label={`Option ${idx + 1} ${
                          idx === 0 ? "(15 char max)" : ""
                        }`}
                        onChange={(v) => {
                          let update = [...fields.voteOptions.content!];
                          update[idx] = { en: v };
                          setField("voteOptions", update);
                        }}
                        value={o.en}
                        hasError={!!(!!validation.voteOptions && !o.en)}
                        helperText={!o.en ? validation.voteOptions || "" : ""}
                      />
                      <Button
                        variant="primary"
                        text
                        style={{
                          visibility:
                            (idx === 0 || idx === 1) &&
                            fields?.voteOptions?.content!.length < 3
                              ? "hidden"
                              : "visible",
                        }}
                        onClick={() => {
                          let update = [...fields.voteOptions.content!];
                          update.splice(idx, 1);
                          setField("voteOptions", update);
                        }}
                      >
                        <SvgDelete style={{ width: 50, height: 50 }} />
                      </Button>
                    </VoteContainer>
                  );
                })}
                <Button
                  variant="primary"
                  text
                  style={{
                    margin: "16px auto 0 0",
                    width: "fit-content",
                    textDecoration: "underline",
                    display: fields.voteOptions?.content?.find(
                      (item: any) => item.en === "",
                    )
                      ? "none"
                      : "flex",
                  }}
                  onClick={() => {
                    let update = [
                      ...(fields?.voteOptions?.content || []),
                      { en: "" },
                    ];
                    setField("voteOptions", update);
                  }}
                >
                  + Add Item
                </Button>
              </>
            )}
          </VoteWrapper>
        )}

        <ConvertButton
          text
          leftIcon={<SvgClipboard />}
          onClick={() => {
            if (fields.checkInVote.content === "checkin") {
              setField("checkInVote", "vote");
              !fields.voteType.content && setField("voteType", "song");
            } else {
              setField("checkInVote", "checkin");
            }
            setModal({ confirmCopyChange: {} });
          }}
        >
          {fields?.checkInVote?.content === "vote"
            ? "Convert Experience to Check In"
            : "Convert Experience to Vote"}
        </ConvertButton>
      </CheckInVoteWrapper>

      <PrivacyWrapper>
        <H4>Privacy Policies</H4>
        <Body2>
          Add privacy policies necessary for the collection of user information,
          either by you or your affiliates.
        </Body2>
        {fields.useCustomTermsAndPrivacyPolicy?.content ? (
          <Body2>
            This feature is disabled when custom policies are enabled by admin.
          </Body2>
        ) : (
          fields.privacyPolicies?.content?.map((p, index) => (
            <Policy>
              <Link href={p.url} target="_blank">
                {p?.name} Privacy Policy
              </Link>
              <SvgEditAlt onClick={() => setModal({ privacy: { index } })} />
              <SvgDelete
                onClick={() =>
                  setModal({
                    confirm: {
                      header: "Delete Policy",
                      label: "Delete Policy",
                      body: "Are you sure you want to delete this policy?",
                      variant: "destructive",
                      onConfirm: () => {
                        setField(
                          "privacyPolicies",
                          fields.privacyPolicies?.content.filter(
                            (v, i) => i !== index,
                          ),
                        );
                        setModal({});
                      },
                    },
                  })
                }
              />
            </Policy>
          ))
        )}
        <Button
          style={{ marginTop: 25 }}
          variant="outlined"
          leftIcon={<SvgAddAlt />}
          onClick={() => setModal({ privacy: {} })}
          disabled={fields.useCustomTermsAndPrivacyPolicy?.content}
        >
          Add Privacy Policy
        </Button>
      </PrivacyWrapper>

      <SweepsWrapper>
        <H4>Will you offer a prize</H4>
        <RadioContainer>
          <Radio
            variant="radio"
            value={fields.sweepstakes.content === true}
            label="Yes"
            onChange={() => {
              if (!fields.sweepstakes.content) {
                if (fields.sweepstakesPrize.content && !customOpen) {
                  setModal({ confirmCopyChange: {} });
                }
                setField("sweepstakes", true);
              }
            }}
          />
          <Radio
            variant="radio"
            value={fields.sweepstakes.content === false}
            label="No"
            onChange={() => {
              if (fields.sweepstakes.content) {
                setField("sweepstakes", false);
                if (fields.sweepstakesPrize.content) {
                  setModal({ confirmCopyChange: {} });
                }
              }
            }}
          />
        </RadioContainer>
        {fields.sweepstakes.content && (
          <>
            <SelectMerchMenu
              value={
                fields.sweepstakesPrizeCustom.content?.en || customOpen
                  ? "Custom option"
                  : fields.sweepstakesPrize.content
              }
              onChange={handleSelectPrize}
              setCustom={handleCustomOpen}
              label={"Prize Selection"}
              buttonText="Custom option"
              placeholder="Select the prize you will offer"
              options={prizeOptions}
              hasError={!!validation.sweepstakesPrize}
              helperText={validation.sweepstakesPrize || ""}
            />
            {(customOpen || fields.sweepstakesPrizeCustom.content?.en) && (
              <TextInput
                secondary
                placeholder="What will the prize be?"
                label="Prize Description"
                onChange={(v) => setField("sweepstakesPrizeCustom", { en: v })}
                value={fields.sweepstakesPrizeCustom.content?.en}
                style={{ margin: "25px 0 5px" }}
                hasError={!!validation.sweepstakesPrizeCustom}
                helperText={validation.sweepstakesPrizeCustom || ""}
              />
            )}
            <Checkbox
              style={{ margin: "15px 0 0" }}
              value={fields.sweepstakesPrizeTerms?.content}
              onChange={handleCheck}
              label={
                <p
                  style={{ fontSize: 13, lineHeight: "160%", marginBottom: 15 }}
                >
                  I have reviewed and accept the{" "}
                  <Link
                    style={{ fontSize: 13, lineHeight: "160%" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `${process.env.REACT_APP_PUBLISHED_URL}/artist-terms`,
                        "_blank",
                      );
                    }}
                  >
                    terms of use
                  </Link>{" "}
                  and will only operate promotions (including sweepstakes) in
                  accordance with applicable laws. The{" "}
                  <Link
                    style={{ fontSize: 13, lineHeight: "160%" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setModal({ rules: true });
                    }}
                  >
                    official rules template
                  </Link>{" "}
                  is provided “as is” without warranty of any kind. SET/MAX
                  makes no representations that the official rules are legally
                  sufficient or otherwise appropriate for your promotion. You
                  are expected and encouraged to have your legal counsel review
                  and such promotion terms or rules and you hereby agree to
                  indemnify and hold harmless MAX from any liability arising out
                  of your promotions."
                </p>
              }
            />
            {validation.sweepstakesPrizeTerms && (
              <Error>{validation.sweepstakesPrizeTerms}</Error>
            )}
          </>
        )}
      </SweepsWrapper>

      <AddSongModal
        setField={setField}
        fields={fields}
        openIndex={openIndex}
        setOpenIndex={setOpenIndex}
      />
    </Wrapper>
  );
};

const ConvertButton = styled(Button)`
  padding: 0;
  text-decoration: underline;
  width: fit-content;

  ${({ theme }) => theme.mediaQueries.mobile} {
    align-self: center;
    margin-left: -12px;
  }
`;

const CheckInVoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 32px;
  grid-template-areas:
    "content sweeps"
    "privacy sweeps"
    "....... sweeps";
  ${(props) => props.theme.mediaQueries.mobile} {
    grid-template-areas:
      "content"
      "sweeps"
      "privacy";
    grid-template-columns: 1fr;
  }
`;

const VoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.1;
  color: #ffffff;
  padding-bottom: 20px;
`;

const SweepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.9;
  color: #ffffff;
  grid-area: sweeps;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-top: 24px;
    border-top: 1px solid #66666666;
    margin-top: 20px;
  }
`;

const VoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  & > button {
    width: 45px;
    margin: 23px 0px -23px 0;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
`;
const CheckinContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.1;
  gap: 20px;
  padding-bottom: 20px;
`;

const Policy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  svg {
    color: #ffffff;
    cursor: pointer;
    transition: ${(p) => p.theme.transitions("color").hover};
    margin-left: 6px;
    &:hover {
      color: #ffffff80;
    }
  }
`;

const PrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #66666666;
  padding-top: 16px;
  margin-top: 24px;
  color: #ffffff;
  ${Body2} {
    margin-bottom: 12px;
  }
  ${Link} {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  grid-area: privacy;
`;
