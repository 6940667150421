import React, { useState } from "react";
import styled from "styled-components";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import useSpotify from "Components/hooks/useSpotify";
import { Modal as ModalBase, ModalWrapper } from "melodies-source/Modal";
import { SpinnerRing } from "./LoadingHelpers/SpinnerRing";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import { Label } from "melodies-source/Text";
import { ListItem } from "melodies-source/ListItem";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "./Firebase";

type CustomRelease = {
  name: string;
  caption?: string;
  image: string;
  type?: string;
  icon?: any;
  releaseId: string;
};

const SpotifyModal = ({ onSelect, isOpen, setIsOpen }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedSongs, setSearchedSongs] = useState<CustomRelease[]>([]);
  const [searchedAlbums, setSearchedAlbums] = useState<CustomRelease[]>([]);
  const [checked, setChecked] = useState<CustomRelease>();
  const functions = getFunctions(firebaseApp);
  const albumEnricher = httpsCallable(
    functions,
    "setlivev3-services-streaming"
  );

  const handleCancel = () => {
    setIsOpen(false);
    setLoading(false);
    setSearchInput("");
    setSearchedSongs([]);
    setSearchedAlbums([]);
  };

  const handleSelect = async () => {
    setLoading(true);
    const socials = await albumEnricher({
      type: checked?.type === "album" ? "album" : "track",
      spotifyId: checked?.releaseId,
    });
    const d: any = socials?.data;
    onSelect({
      name: checked?.name,
      releaseId: checked?.releaseId,
      image: checked?.image,
      type: checked?.type,
      appleMusic: d?.appleMusic || "",
      amazon: d?.amazon || "",
      pandora: d?.pandora || "",
      spotify: d?.spotify || "",
      youtubeMusic: d?.youtubeMusic || "",
      deezer: d?.deezer || "",
      iHeartRadio: d?.iHeartRadio || "",
    });
    handleCancel();
  };

  const token = useSpotify();

  const Icon = ({ src }: any) => {
    return (
      <img
        style={{ width: 50, height: 50, padding: "0 0px 0", borderRadius: 4 }}
        src={src}
        alt="album cover"
      />
    );
  };

  const getOptions = async (searchInput: string) => {
    if (!searchInput) return [];
    if (token) {
      const artistParameters = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      return fetch(
        `https://api.spotify.com/v1/search?q=${searchInput}&type=track%2Calbum`,
        artistParameters
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("song data", data);
          let songData = data?.tracks?.items?.slice(0, 5).map((track: any) => ({
            name: track.name,
            icon: <Icon src={track.album.images[2].url} />,
            image: track.album.images[1].url,
            caption: `${track.artists[0].name} • Single`,
            type: "single",
            releaseId: track.id,
          }));
          setSearchedSongs(songData);

          let albumData = data?.albums?.items
            ?.slice(0, 5)
            .map((album: any) => ({
              name: album.name,
              icon: <Icon src={album.images[2].url} />,
              image: album.images[1].url,
              caption: `${album.artists[0].name} • Album`,
              type: "album",
              releaseId: album.id,
            }));
          setSearchedAlbums(albumData);

          return data?.tracks?.items?.map((track: any) => ({}));
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      header="Add a release"
      confirmation={{
        onConfirm: handleSelect,
        isDisabled: !checked || loading,
        label: loading ? "Processing" : "Confirm",
      }}
    >
      {loading ? (
        <ScrollContainer>
          <SpinnerContainer>
            <SpinnerRing
              textArray={["Finding Streaming Links...", "Adding Release ..."]}
              svg={
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30 3C44.9117 3 57 15.0883 57 30C57 44.9117 44.9117 57 30 57C15.0883 57 3 44.9117 3 30C3 15.0883 15.0883 3 30 3ZM33.2723 43.1006C32.4865 43.2963 31.6735 43.4231 30.84 43.4743L30 43.5V53.625C31.5815 53.625 33.1266 53.4696 34.621 53.1732L35.7321 52.9248L33.2723 43.1006ZM20.4558 39.546L13.2964 46.7055C16.0279 49.437 19.4251 51.5027 23.2239 52.6384L24.2696 52.9249L26.7261 43.0999C24.582 42.5655 22.6407 41.5185 21.0399 40.0966L20.4558 39.546ZM30 19.875C24.4081 19.875 19.875 24.4081 19.875 30C19.875 35.5919 24.4081 40.125 30 40.125C35.5919 40.125 40.125 35.5919 40.125 30C40.125 24.4081 35.5919 19.875 30 19.875ZM30 26.625C31.864 26.625 33.375 28.136 33.375 30C33.375 31.864 31.864 33.375 30 33.375C28.136 33.375 26.625 31.864 26.625 30C26.625 28.136 28.136 26.625 30 26.625ZM35.7304 7.0751L33.2739 16.9001C35.418 17.4345 37.3593 18.4815 38.9601 19.9034L39.5442 20.454L46.7036 13.2945C43.9721 10.563 40.5749 8.49728 36.7762 7.36158L35.7304 7.0751ZM30.0017 6.375C28.4202 6.375 26.8751 6.53039 25.3806 6.82673L24.2695 7.07519L26.726 16.9002C27.5125 16.7041 28.3264 16.577 29.1607 16.5258L30.0017 16.5V6.375Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </SpinnerContainer>
        </ScrollContainer>
      ) : (
        <>
          <AutocompleteAsync
            text={searchInput}
            secondary
            setText={(v: string) => setSearchInput(v)}
            onChange={() => {}}
            //@ts-ignore
            hideDropdown={true}
            label="Search for a Single, Album, EP, etc:"
            placeholder="Search"
            getOptions={getOptions}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 24,
            }}
          />
          <ScrollContainer>
            {!!searchedSongs?.length && (
              <Label disabled={true}>
                {searchedSongs?.length || 0} Matching Single
                {searchedSongs?.length === 1 ? "" : "s"}
              </Label>
            )}
            <SongWrapper>
              {searchedSongs?.map((song, i) => {
                return (
                  <ListItem
                    caption={song?.caption}
                    avatar={song?.icon}
                    checked={checked?.releaseId === song?.releaseId}
                    setValue={(data) => {
                      setChecked(data);
                    }}
                    data={song}
                    key={i}
                  >
                    {song?.name}
                  </ListItem>
                );
              })}
            </SongWrapper>
            {!!searchedAlbums?.length && (
              <Label disabled={true} style={{ marginTop: 20 }}>
                {searchedAlbums?.length || 0} Matching Album
                {searchedAlbums?.length === 1 ? "" : "s"}
              </Label>
            )}

            <SongWrapper>
              {searchedAlbums?.map((album, i) => {
                return (
                  <ListItem
                    caption={album?.caption}
                    avatar={album?.icon}
                    checked={checked?.releaseId === album?.releaseId}
                    setValue={(data) => {
                      setChecked(data);
                    }}
                    data={album}
                    key={i}
                  >
                    {album?.name}
                  </ListItem>
                );
              })}
            </SongWrapper>
          </ScrollContainer>
        </>
      )}
    </Modal>
  );
};

export default SpotifyModal;

const SpinnerContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    position: relative;
    height: 80%;
    min-height: 572px;
    ${({ theme }) => theme.mediaQueries.mobile} {
      min-height: 380px;
      margin: 40px auto;
    }
  }
`;

const ScrollContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${scrollBarDarkCss};
  padding: 16px 0 8px;
`;

const SongWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
`;
