import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import styled, { CSSProperties } from "styled-components";
import { Body2 } from "melodies-source/Text";
import { useClickOutside } from "hooks/useClickOutside";
import { AnimationConditional } from "Components/Animation";
import "./override.css";
import { SvgEdit } from "melodies-source/Svgs/Edit";

const introFrames = [
  { transform: "scale(0.3)", transformOrigin: "top left", timing: "ease" },
  { transform: "scale(1)" },
];

const outroFrames = [
  { transform: "scale(1)" },
  { transform: "scale(0.3)", transformOrigin: "top left" },
];

export const ColorPicker = ({
  label,
  value,
  onSelect,
  style,
}: {
  label?: string;
  value?: string;
  onSelect: (color: string) => void;
  style?: CSSProperties;
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const popover = useRef<HTMLDivElement>(null);
  const colorSwatch = useRef<HTMLDivElement>(null);

  const close = useCallback(() => setShowPicker(false), []);
  useClickOutside(popover, close, colorSwatch);

  return (
    <ColorDisplay style={style}>
      <ColorSwatch
        background={value}
        onClick={() => setShowPicker((show) => !show)}
        ref={colorSwatch}
      >
        <EditCircle>
          <SvgEdit />
        </EditCircle>
      </ColorSwatch>
      {!!label && <LabelText>{label}</LabelText>}
      <ColorPickerAnimation
        display={showPicker}
        duration={150}
        introKeyframes={introFrames}
        introEasing="ease-in-out"
        outroEasing="ease-in-out"
        outroKeyframes={outroFrames}
        alternativeRef={popover}
      >
        <ColorPickerWrapper open={showPicker} className="color-picker">
          <HexColorPicker
            color={value}
            onChange={onSelect}
            style={{ marginBottom: 10 }}
          />
          <HexColorInput color={value} onChange={onSelect} prefixed />
        </ColorPickerWrapper>
      </ColorPickerAnimation>
    </ColorDisplay>
  );
};

const ColorPickerAnimation = styled(AnimationConditional)`
  display: block !important;
  position: absolute;
  top: 46px;
  left: 46px;
  z-index: 10;
`;

const ColorDisplay = styled.div`
  position: relative;
  width: 110px;
  height: 149px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  padding: 10px 12px 14px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColorSwatch = styled.div<{ background?: string }>`
  width: 86px;
  height: 86px;
  flex-shrink: 0;
  border-radius: 6px;
  background: ${({ background }) => background || "#F2F5F7"};
  border: 1px solid #dddddd;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditCircle = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
`;

const ColorPickerWrapper = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LabelText = styled(Body2)`
  display: flex;
  margin-top: auto;
`;
