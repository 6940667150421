import React from "react";
import { Modal } from "melodies-source/Modal";
import { H4 } from "../melodies-source/Text";
import { Link } from "../melodies-source/Link";
import styled from "styled-components";
import { useModalContext } from "./ModalContext";
import { SvgLeft } from "melodies-source/Svgs/Left";

export const RulesModal = () => {
  const { setModal } = useModalContext();

  return (
    <Modal isOpen={true} onClose={() => setModal({})}>
      <Wrapper>
        <Content>
          <CloseButton onClick={() => setModal({})}>
            <SvgLeft />
          </CloseButton>
          <H4 style={{ textAlign: "center" }}>
            Music Audience Exchange, Inc.
            <br />
            SET.Live Sweepstakes Promotion
            <br />
            Official Rules
          </H4>
          <br />
          <p>
            <strong>
              NO PURCHASE NECESSARY TO ENTER OR WIN. PURCHASES DO NOT INCREASE
              ODDS OF WINNING.
            </strong>
          </p>
          <p>
            THESE OFFICIAL RULES ARE A CONTRACT WHICH ARE LEGALLY BINDING UPON
            SUBMISSION OF AN ENTRY IN THIS PROMOTION—READ THEM CAREFULLY BEFORE
            ENTERING. WITHOUT LIMITATION, THIS CONTRACT INCLUDES A RELEASE AND
            LICENSE FROM YOU, AND A LIMITATION OF CERTAIN OF YOUR RIGHTS AND
            REMEDIES. ODDS OF WINNING DEPEND ON NUMBER OF ELIGIBLE ENTRIES
            RECEIVED. THE FOLLOWING PROMOTION IS OPEN TO CONCERT VENUE ATTENDEES
            WHO ARE LEGAL RESIDENTS OF THE CONTIGUOUS UNITED STATES AND THE
            DISTRICT OF COLUMBIA ONLY (EXCLUDING ALASKA, HAWAII, PUERTO RICO,
            GUAM, THE US VIRGIN ISLANDS, AND OTHER UNITED STATES OVERSEAS
            TERRITORIES), AND WHO MEET THE ELIGIBILITY REQUIREMENTS (AS DEFINED
            BY THE RULES BELOW). VOID WHERE PROHIBITED.
          </p>
          <p>
            <p>
              <strong>1. Promotion and Sponsor</strong>
            </p>
            <br />
            Participation in the SET.Live Sweepstakes Promotion (the
            "Promotion") for the live event for which you have selected
            (“Concert”) constitutes Entrant's (as defined below) full and
            unconditional agreement to these Official Rules. Entrant is free to
            disclaim any prize and status as Entrant, and as a result will not
            be eligible to receive any prize. The Promotion is sponsored and
            administered by Music Audience Exchange, Inc., 2595 N. Dallas Pkwy.
            #440, Frisco, TX 75034 ("Sponsor"). Sponsor will have the exclusive
            authority to interpret these Official Rules and all decisions
            related to, as well as all interpretation of, these Official Rules
            by Sponsor are final and binding. While this Promotion may be
            promoted via social media websites, such websites are not affiliated
            with Sponsor or this Promotion. This Promotion is in no way
            sponsored, endorsed or administered by, or associated with Facebook,
            Twitter, Instagram, Snapchat or any other site or medium that might
            host content where information about this Promotion is posted or
            published (collectively "Content Hosts"). Entrant is providing
            information only to Sponsor and not to Content Hosts.
          </p>
          <p>
            <p>
              <strong>2. Promotion Period</strong>
            </p>
            <br />
            The Promotion begins when the Concert venue begins to admit Concert
            ticket holders to enter the venue on the Concert date the
            Sweepstakes is promoted and ends at the end of the Concert (the
            “Promotion Period”). To be eligible for the random drawing, Entries
            must be received before the main performance ends on the Concert
            date. Only entries submitted and received during the Promotion
            Period will be accepted. Sponsor is the official timekeeper of this
            promotion.
          </p>
          <p>
            <p>
              <strong>3. Eligibility</strong>
            </p>
            <br />
            You must be inside the Concert venue to enter. The Promotion is open
            only to concert venue attendees who are legal residents of the
            forty-eight continental United States and District of Columbia
            (excluding Alaska, Hawaii, Puerto Rico, Guam, the US Virgin Islands
            and other United States overseas territories) and at least aged 18
            or older. Employees of Sponsor or its parent and affiliate companies
            and suppliers, as well as the immediate family (spouse, parents,
            siblings and children) and household members of each such employee
            are not eligible. Legal entities are not eligible to enter.
            Eligibility is contingent upon agreement to, compliance with, and
            fulfillment of all requirements of these Official Rules.
            Participation in the Promotion in any way, including submission of
            any entry or the partial completion of any act of entry or any other
            steps taken to participate in the Promotion in any manner,
            constitutes full and unconditional agreement with all provisions of
            these Official Rules.
          </p>
          <p>
            <p>
              <strong>4. How to Enter</strong>
            </p>
            <br />
            NO PURCHASE NECESSARY. PURCHASES DO NOT INCREASE ODDS OF WINNING.
            There are two (2) methods of entry. You must be inside the Concert
            venue to enter. By participating in the Promotion in any way you
            will become an "Entrant." Each Entrant who enters using one of the
            below methods of entry will receive one (1) entry in the Promotion.
            Limit one (1) eligible entry per person and per email address.
            Sponsor is not responsible for the cost of any original Concert
            tickets travel and/or accommodations associated with attending
            Concert if Entrant is determined the winner. 1st Method of Entry:
            During the Promotion Period, you can enter the Promotion by scanning
            the applicable Set.live Promotion QR code at the Concert venue and
            providing any requested information at the resulting webpage to
            receive one (1) entry in the Promotion. 2nd Method of Entry: During
            the Promotion Period, go to Set.live website, select the link for
            Promotion entry and complete and submit the online form for one (1)
            entry. Entries by any method other than set forth herein are void.
            Any use of automated or programmed methods of effecting Entry is
            prohibited.
          </p>
          <p>
            <p>
              <strong>5. Conditions of Entry</strong>
            </p>
            <br />
            By entering or participating in this Promotion, Entrant agrees to be
            bound by these Official Rules and the decisions of Sponsor and its
            authorized representatives, which are final and binding in all
            matters concerning this Promotion. Entries not legitimately
            submitted in accordance with these Official Rules are void. Only
            qualified entries received by Sponsor during the Promotion Period
            are eligible for participation. Sponsor is not responsible for
            entries that are made but not received by Sponsor, and such entries
            are void. All entries become the property of Sponsor and will not be
            acknowledged or returned. Entrants may be required to provide the
            following information in order to be eligible for a Promotion prize:
            first and last name, email address, zip or postal code, phone number
            (including area code). All requested Information must be provided to
            participate in the Promotion and to be eligible to win a prize.
            Unintelligible entries; entries with incomplete, invalid, incorrect
            or noncurrent information; entries that are counterfeit, damaged or
            tampered with in any way; and entries with printing, production or
            typographical errors will be disqualified. Disqualified entries and
            entries that are late, lost, damaged, misdirected, stolen or not
            delivered are not the responsibility of Sponsor and will be
            disqualified. Sponsor is not responsible if a potential winner does
            not receive his or her prize because erroneous, expired, canceled,
            fraudulent or fake information was provided during Promotion. Proof
            of sent entry and/or entry receipt (such as a copy of a "thank you"
            screen or message) does not constitute proof of actual receipt of
            entry by Sponsor for purposes of this Promotion.
          </p>
          <p>
            Participation must be by Entrant. Participation by any other
            individual or entity, and/or originating at any other website, email
            address or phone number, including, but not limited to, commercial
            promotion, subscription notification and/or promotion entering
            service sites, is invalid and will be disqualified from this
            Promotion. Any attempt by any Entrant to obtain more than the stated
            number of entries by using multiple email addresses, phone numbers,
            identities, registrations or logins; entries through a sweepstakes
            club or similar entity; or any other unauthorized method of entry
            will void all of Entrant's entries and Entrant will be disqualified
            from the entire Promotion. Submitting mass entries or entries
            generated by an automated program, script or macro, or the use of
            any other similar device, program or method to enter this Promotion
            is prohibited and will result in disqualification.
          </p>
          <p>
            In the event of a dispute as to the identity of Entrant, the
            authorized account holder of the email address used to enter will be
            deemed to be an Entrant. A potential winner may be required to show
            proof of being the authorized account holder. The "authorized
            account holder" of an email address is the natural person assigned
            to that email address by a service provider or any other
            organization (business, educational institution, etc.) responsible
            for assigning accounts to that email address. If the identity of the
            individual who actually participated in the Promotion cannot be
            resolved to Sponsor's satisfaction, the affected individual's entry
            will be deemed ineligible.
          </p>
          <p>
            Sponsor reserves the right, in its sole discretion, to disqualify
            any individual it finds to be in violation of these Official Rules,
            tampering with the entry process or the operation of the Promotion
            (including, but not limited to, tampering with websites, email
            addresses or phone numbers), or acting in an unsportsmanlike or
            disruptive manner. Any attempt to undermine the operation of the
            Promotion may be a violation of criminal and civil law, and Sponsor
            reserves the right to seek damages to the fullest extent permitted
            by law from any person it finds to have made such an attempt.
          </p>
          <p>
            <p>
              <strong>6. Prize</strong>
            </p>
            <br />
            One (1) prize will be awarded. Prize winner will receive one (1)
            prize as identified on the landing page for the corresponding
            Concert, approximate retail value (“ARV”) of Prize is $350.00.
            Actual value of the prize may vary. All additional costs, including,
            but not limited to security fees, gratuities, tips, taxes, and other
            personal charges not specifically described as part of the prize
            above are not included in the prize. No compensation or substitution
            will be provided for any difference in prize value. Sponsor is in no
            way responsible for any cancellations, delays, postponements,
            diversions, substitutions, or any other act, omission, or result
            thereof, caused by any third party. All prizes must be accepted as
            awarded without substitution and are not, in whole or in part,
            assignable, transferable or available for resale. Prizes have no
            cash surrender value, except where required by law. Sponsor has made
            no warranty, representation or guarantee, express or implied, in
            fact or in law, with respect to any prize. This includes, without
            limitation, any warranty, representation or guarantee related to any
            prize's quality or fitness for a particular purpose. Prizes are
            subject to availability and Sponsor reserves the exclusive right to
            substitute a prize (or a component thereof) with another prize (or
            component thereof) of equal or greater value if the prize is not
            available for any reason, as determined in Sponsor's sole
            discretion. Federal, state, and local taxes and all other costs and
            expenses associated with acceptance and use of any prize not
            specified herein as being awarded are the winner's sole
            responsibility. Prize specific terms and conditions apply. If the
            winner elects not to partake in any portion of the prize, any
            remainder of the prize that is unused will be forfeited and will not
            be subject to further or additional compensation.
          </p>
          <p>
            Any prize details not specified above will be determined by Sponsor
            in its sole discretion. If a winner is disqualified, not eligible or
            for any other reason cannot accept the prize as stated, then Sponsor
            may randomly select a new winner from the pool of eligible Entrants.
            Where legal, the winner may be required to submit, without
            alteration and in the form provided by Sponsor, a completed and
            signed Affidavit of Eligibility and Liability & Publicity Release
            (the "Release"), along with a properly completed U.S. Department of
            the Treasury Internal Revenue Service Form W-9, within the required
            time period to be eligible to receive any prize (if applicable;
            required time period will be communicated in the Release). Only the
            number of each prize set forth in these Official Rules will be
            awarded. In the event that technical or any other reasons cause more
            than the stated number of prizes as set forth in these Official
            Rules to be available and/or claimed, Sponsor reserves the right to
            award only the stated number of prizes by a random drawing from
            among all legitimate, unawarded, eligible prize claims. If any prize
            event or component thereof is cancelled or otherwise does not occur
            for any reason whatsoever, the portion of the prize associated with
            that event or component thereof will not be awarded, and any prize
            or value already awarded in connection with that event will be
            forfeited and no additional compensation will be provided to the
            winner. If any prize event or component thereof is rescheduled or
            postponed, the portion of the prize associated with that event or
            component thereof will be awarded for the rescheduled date.
          </p>
          <p>
            <p>
              {" "}
              <strong>7. Winner Determination and Verification</strong>
            </p>
            <br />
            The process leading to the selection of a potential prize winner is
            within the sole discretion of Sponsor. Within a reasonable time
            after the end of each specified Promotion Period, each potential
            winner will be randomly drawn from the pool of all eligible entries
            received during the Promotion Period.
          </p>
          <p>
            Potential winners are each subject to eligibility verification, on
            an ongoing basis, by Sponsor, whose decisions are final and binding.
            An Entrant is not a winner of any prize, even if notified of being a
            winner and/or issued any item of value, unless and until Entrant's
            eligibility has been, and continues to be verified to the
            satisfaction of Sponsor.
          </p>
          <p>
            To be verified as eligible to receive a prize, each potential winner
            must fulfill all requirements and continue to comply with all terms
            and conditions of these Official Rules, and not be disqualified for
            any reason. Each potential winner will be notified by email (subject
            to Sponsor's discretion). If a potential winner of any prize cannot
            be contacted using the information provided or fails to respond to
            Sponsor's attempt to notify them of selection within 1 hours; if any
            prize or notification is returned as undeliverable or yields an
            error or undeliverable type "bounce back" email (regardless if the
            message is ultimately delivered to the recipient); if the potential
            winner fails to sign and return the Release or any other required
            documentation or fails to comply with these Official Rules; if a
            potential winner is unable or unwilling to accept the prize as
            stated; or if the potential winner cannot be verified as eligible
            for any other reason at any time before, during or after the
            Promotion Period, the potential winner is disqualified and forfeits
            the prize and an alternate will be selected.
          </p>
          <p>
            In the event that an Entrant or potential winner is disqualified or
            fails to claim a prize for any reason, the foregoing process will be
            repeated until all prizes are awarded OR until three (3) attempts
            have been made to award each prize and each attempt has failed OR
            Sponsor determines that there is insufficient time remaining before
            the date of the prize to be able to award such prize to an alternate
            winner. IF ANY PRIZE REMAINS UNAWARDED FOR ANY REASON AFTER THREE
            ATTEMPTS OR BECAUSE THERE IS INSUFFICIENT REMAINING TIME TO AWARD
            THE PRIZE, THAT PRIZE WILL NOT BE AWARDED IN THIS PROMOTION. If any
            winner is awarded and claims a prize, a winner list may be obtained
            by printing the requestor's name, address, city, state or
            province/territory and zip or postal code and the name of the artist
            attached to the promotion on a 3x5" postcard and mailing it to Music
            Audience Exchange, Inc., Attn: Winner List, 2595 N. Dallas Pkwy.
            #440, Frisco, TX 75034. Limit one (1) request per person, per
            household.
          </p>
          <p>
            Odds of winning any prize depend on the number of eligible entries
            received.
          </p>
          <p>
            <p>
              <strong>8. Limitations of Liability</strong>
            </p>
            <br />
            By entering or participating in this Promotion in any way, each
            winner agrees to release, discharge, indemnify and hold harmless
            Sponsor, Content Hosts, and each of their respective subsidiaries,
            affiliates, vendors, advertising or promotional agencies and
            distributors, and each of their respective parent companies and each
            such company's officers, directors, employees and agents, as well as
            the immediate family (spouse, parents, siblings and children) and
            household members of each such individual (collectively, the
            "Released Parties") from and against any loss, damage, injury,
            claim, or cause of action related in any way whatsoever to this
            Promotion, these Official Rules, or the rights, consents and
            licenses granted to Sponsor under these Official Rules, including,
            but not limited to, infringement of any right of publicity or
            intellectual property; threatened or actual injury, loss or damage
            to any person, including death and disability; defamation or
            portrayal in a false light (intentional and unintentional); invasion
            of privacy; and damage to or loss of property, arising out of such
            Entrant's participation in the Promotion or receipt or use, non-use
            or misuse of any prize. In no event will Released Parties be
            responsible or liable for any damages or losses of any kind,
            including indirect, incidental, consequential or punitive damages
            arising out of this Promotion. (Some jurisdictions may not allow the
            limitations or exclusion of liability for incidental or
            consequential damages or exclusion of implied warranties. Check
            local laws for restrictions regarding these limitations or
            exclusions.)
          </p>
          <p>
            Entrants waive any right to claim ambiguity in these Official Rules.
            The Released Parties are not responsible for: (1) technical failures
            of any kind; (2) incorrect or inaccurate information, whether caused
            by Entrants, any internet or email service provider, any promotional
            or advertising agency, printing or computing errors, or by any of
            the equipment or programming associated with or utilized in the
            Promotion; (3) unauthorized human intervention in any part of the
            entry process or the Promotion; (4) technical or human error that
            may occur in the administration of the Promotion, the processing of
            entries or the announcement of the winner and prize; (5) any injury
            or damage to persons or property that may be caused, directly or
            indirectly, in whole or in part, by Entrant's participation in the
            Promotion; (6) the receipt, use or misuse of any prize; or (7) any
            other errors in any materials, information or announcements
            associated with the Promotion. If for any reason an Entrant's entry
            is confirmed to have been erroneously deleted, lost or otherwise
            destroyed or corrupted by Sponsor, Entrant's sole remedy is another
            entry in the Promotion, subject to availability and provided that
            the Promotion has not then expired.
          </p>
          <p>
            <p>
              <strong>9. LICENSE[S] FROM ENTRANT</strong>
            </p>
            <br />
            Except where prohibited by law, participation in the Promotion in
            any way and/or acceptance of, or participation in, any prize
            constitutes each Entrant's irrevocable consent for, and grant of a
            non-exclusive, sub-licensable and assignable license to Sponsor to
            use, publish, post, or display Entrant’s name, likeness, photograph,
            voice, opinions, statements, prize information, biographical
            information, and/or hometown and state, province, or territory,
            regardless of whether altered, modified, distorted, edited, or used
            alone or with other material, in Sponsor’s sole discretion, for
            promotional, advertising, trade, and publicity purposes in any
            medium now known or later discovered, worldwide and in perpetuity,
            without review or approval by Entrant, and without further notice,
            payment, or consideration of any kind to Entrant. All rights,
            consents and licenses granted to Sponsor under these Official Rules
            will survive the termination of this Promotion. Entrant's consent
            and license may only be revoked in writing, and the mere ending of
            this Promotion or Promotion Period is not sufficient to revoke such
            consent or license.
          </p>
          <p>
            <p>
              <strong>10. Right to Modify or Cancel</strong>
            </p>
            <br />
            The invalidity or unenforceability of any provision of these
            Official Rules will not affect the validity or enforceability of any
            other provision. In the event any provision is found invalid or
            unenforceable, these Official Rules will otherwise remain in effect
            in accordance with their terms as if the invalid or unenforceable
            provision was never included. Sponsor's failure to enforce any term
            of these Official Rules does not constitute a waiver of that
            provision. Sponsor reserves the right to cancel, suspend and/or
            modify the Promotion, or any part of it, if any mistake, clerical or
            typographical error, omission, fraud, technical failure, tampering,
            computer virus or other factors, technical or otherwise, beyond
            Sponsor's reasonable control, impairs Sponsor's ability to properly
            conduct the Promotion, as determined by Sponsor in its sole
            discretion, subject to any applicable law or regulation. In such an
            event, Sponsor reserves the right, but not the obligation, to award
            any applicable prize(s) by random drawing from among the eligible
            entries received up to the time of the cancellation, suspension, or
            modification of the Promotion. Inclusion in such random drawing is
            Entrant's exclusive remedy under such circumstances.
          </p>
          <p>
            In the event of an inconsistency between these Official Rules and
            any disclosure or other statement contained in any Promotion-related
            materials, including, but not limited to, a Promotion entry form or
            any point-of-sale, radio, television, print, or online advertising,
            these Official Rules will prevail and govern.
          </p>
          <p>
            <p>
              <strong>11. Disputes</strong>
            </p>
            <br />
            Except where prohibited by law, Entrant agrees that: (1) all
            disputes, claims, and causes of action arising out of or connected
            with this Promotion or any prize awarded will be resolved
            individually, without resort to any form of class action, and
            exclusively by the appropriate federal or state court located in
            Collin County, Texas; and (2) all claims, judgments and awards will
            be limited to actual out-of-pocket costs incurred, including costs
            associated with entering this Promotion, but in no event attorneys'
            fees. All issues and questions concerning the construction,
            validity, interpretation, and enforceability of these Official
            Rules, or the rights or obligations of Entrant or Sponsor in
            connection with the Promotion, are governed by and construed in
            accordance with the laws of the State of Texas, without giving
            effect to any choice of law or conflict of law rules, no matter what
            jurisdiction, that would cause the application of the laws of any
            jurisdiction other than as stated.
          </p>
          <p>
            <strong>12. Privacy:</strong> By entering this Promotion, Entrant
            agrees to Sponsor's manner of collection, use, retention, and
            disclosure of Entrant's Information submitted in connection with the
            Promotion. Information collected from Entrant is subject to
            Sponsor's privacy policy, which can be found at{" "}
            <a
              target="_blank"
              href="https://www.musicaudienceexchange.com/privacy-policy"
              rel="noreferrer"
            >
              https://www.musicaudienceexchange.com/privacy-policy
            </a>
            .
          </p>
        </Content>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 501;
  overflow-y: scroll;
  color: #000;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 24px;
  color: black;
  z-index: 510;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  color: #000;
  ${Link} {
    color: #1b0076 !important;
    display: inline-block;
    text-decoration: underline;
  }
  ${H4} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    word-break: break-all;
  }
  p {
    font-size: 15;
    font-weight: 400;
    line-height: 22px;
    color: ${(props) => props.theme.colors.black80};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      font-size: 15;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colors.black80};
    }
  }
`;
