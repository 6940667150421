import { Preview, useConfigSlice } from "Components";
import { MouseEventHandler, useEffect } from "react";
import styled from "styled-components";
import { useBuilderContext } from "Components/BuilderContext";
import { useNavigate } from "react-router-dom";
import { Button } from "melodies-source/Button";
import { SvgRight } from "melodies-source/Svgs/Right";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { IconThemeCard } from "Images/CardIcons";
import { H2 } from "melodies-source/Text";
import { InfoCard } from "Components/InfoCard";
import { getColorConfig, EventTypes } from "@max/common";

export const Theme = () => {
  const nav = useNavigate();
  const { config } = useBuilderContext();
  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "type",
    "colors",
  ]);

  const handleClick = (option: EventTypes) => {
    setField("type", option);
  };

  const selected = fields.type.content;
  const { imageIndex, imageColors } = getColorConfig(config);

  useEffect(() => {
    if (!fields.colors?.content?.[imageIndex]?.[selected]) {
      handleClick(selected || 0);
    }
  }, []);

  return (
    <Container>
      <Content>
        <H2>First, pick a template and color scheme</H2>
        <GridContainer>
          {(
            Object.keys(EventTypes).filter((v) =>
              isNaN(Number(v))
            ) as (keyof typeof EventTypes)[]
          ).map((key) => {
            const type = EventTypes[key];
            const colors =
              fields.colors.content?.[imageIndex]?.[type] ||
              imageColors?.[type]?.[0];
            return (
              <Option
                key={`preview-${key}`}
                override={{
                  type,
                  colors,
                }}
                selected={selected === type}
                onClick={() => handleClick(type)}
              />
            );
          })}
        </GridContainer>
        <Button
          disabled={!isValid}
          onClick={() => {
            save();
            nav(`../location`);
          }}
          rightIcon={<SvgRight />}
          nudgeRight
          style={{ width: 180, marginTop: 40 }}
        >
          Next
        </Button>
        <InfoCard
          text="After initial setup, you can change templates, colors, and photos to fit your style."
          icon={<IconThemeCard />}
        />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;
const Content = styled.div`
  color: #ffffff;
  max-width: 380px;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 120px;
  grid-template-rows: repeat(2, 242px);
  row-gap: 24px;
  column-gap: 24px;
  margin: 24px 0 0;
`;

const Prev = styled(Preview)`
  transform: scale(0.303);
  transform-origin: top left;
  width: 396px;
  height: 800px;
  min-height: 800px;
`;

const Option = ({
  selected,
  override,
  ...props
}: {
  onClick: MouseEventHandler<HTMLDivElement>;
  selected: boolean;
  override: any;
}) => {
  return (
    <Container>
      <Overlay {...props}>
        <OverlayHelper style={{ opacity: selected ? 1 : 0 }}>
          <SvgCheck />
        </OverlayHelper>
        <Prev {...{ override, isThumbnail: true }} />
      </Overlay>
      <Shadow />
    </Container>
  );
};

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
  height: 242px;
  overflow: hidden;
  z-index: 1;
`;

const Shadow = styled.div`
  display: block;
  position: absolute;
  top: 1px;
  right: 2px;
  bottom: 1px;
  left: 2px;
  border-radius: 20px;
  box-shadow: 0px 0px 45px 8px rgba(255, 255, 255, 0.26);
  z-index: 0;
`;

const OverlayHelper = styled.div`
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  z-index: 1;
  overflow: hidden;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 1px;
  bottom: 0;
  left: 1px;
  border-color: #ffffff;
  border-style: solid;
  border-top-width: 5px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-left-width: 6px;
  svg {
    width: 80px;
    height: 80px;
    opacity: 0.7;
    color: #000000;
  }
`;
