import React, { useState } from "react";
import styled, { css } from "styled-components";
import { SvgCheckLarge } from "../Svgs/CheckLarge";
import { SvgAddLarge } from "../Svgs/AddLarge";
import { SvgRefreshLarge } from "../Svgs/RefreshLarge";
import { LinkText, Subtitle2, Subtitle1 } from "melodies-source/Text";

type DataProps = {
  image: string;
  name?: string;
  type?: string;
  releaseId?: string;
};
export type GalleryInputProps = {
  name?: string;
  hasButton?: boolean;
  data: DataProps[];
  checked?: string;
  setValue: (v: any) => void;
  onClick?: () => void;
  replaceItem?: () => void;
  style?: any;
};
export const GallerySelect = ({
  data,
  name,
  hasButton,
  checked,
  setValue,
  replaceItem,
  onClick,
  ...props
}: GalleryInputProps) => {
  return (
    <Wrapper {...props}>
      {data
        //  ?.filter?.((d) => !!d.name)
        ?.map((d, i) => {
          return (
            <div>
              <ImageContainer
                onClick={() => {
                  setValue(d);
                }}
                isSelected={d?.releaseId === checked}
                image={d?.image}
                hasCaption={!!name}
              >
                {d?.releaseId === checked && (
                  <SvgCheckLarge
                    style={{
                      position: "absolute",
                      width: "50%",
                      height: "50%",
                      zIndex: 10,
                      margin: "auto",
                      color: "#000000",
                    }}
                  />
                )}
              </ImageContainer>
              <GalleryWrap>
                <Subtitle2>{d?.name}</Subtitle2>
                <Subtitle2>{d?.type}</Subtitle2>
                {i === data.length - 1 && !hasButton && (
                  <Subtitle1
                    style={{ cursor: "pointer" }}
                    onClick={replaceItem}
                  >
                    Replace{" "}
                    <SvgRefreshLarge
                      style={{ width: 19, margin: "5px 0 -5px 0" }}
                    />
                  </Subtitle1>
                )}
              </GalleryWrap>
            </div>
          );
        })}
      {hasButton && (
        <AddImage onClick={onClick}>
          <AddWrap>
            <SvgAddLarge />
            <LinkText>
              Add your <br /> own
            </LinkText>
          </AddWrap>
        </AddImage>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  max-width: 750px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
`;
export const AddImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 6px;
  border: 1px solid #ffffff;
  cursor: pointer;
`;
export const GalleryWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 8px;
  ${Subtitle2} {
    color: #ffffff;
  }
`;

const AddWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${LinkText} {
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
  }
  svg {
    color: #ffffff;
    margin-bottom: 1px;
  }
`;
export const ImageContainer = styled.div<{
  isSelected?: boolean;
  image?: string;
  hasCaption?: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  aspect-ratio: 1/1;
  background: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 6px;

  ${({ image, isSelected }) =>
    isSelected &&
    image &&
    css`
      background: -webkit-linear-gradient(
          rgba(255, 255, 255, 0.7),
          rgba(253, 255, 255, 0.4)
        ),
        url(${image});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
`;
