import { Logo } from "../Logo";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserMenu } from "./Components";
import { useState } from "react";
import { SvgMenuLarge } from "melodies-source/Svgs/MenuLarge";

export const MenuBar = () => {
  const [menu, setMenu] = useState(false);

  const navigate = useNavigate();
  return (
    <Container>
      <Left>
        <MenuIcon onClick={() => setMenu(!menu)} />
        <Logo onClick={() => navigate("/")} />
      </Left>
      <Right>
        <UserMenu />
      </Right>
    </Container>
  );
};

const Left = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 2px 0;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Container = styled.div`
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #e6e9eb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 8px 12px;
    ${Left} {
      svg {
        height: 20px;
      }
    }
  }
`;

const MenuIcon = styled(SvgMenuLarge)`
  margin: 0 12px 0 0;
  cursor: pointer;
`;
