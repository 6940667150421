import { Modal } from "melodies-source/Modal";
import { useModalContext } from "./ModalContext";
import { useBuilderContext } from "./BuilderContext";
import { Body1 } from "melodies-source/Text";
import { CopyField, setTranslatableFields } from "Utils/helpers";

export type CopyChangeModalProps = {
  fields?: CopyField[];
};

export const CopyChangeModal = () => {
  const { setModal, modal } = useModalContext();
  const { setConfig, config } = useBuilderContext();

  const { fields } = modal.confirmCopyChange || {};

  const handleConfirm = () => {
    setTranslatableFields(config, setConfig, fields);
    setModal({});
  };

  return (
    <Modal
      header="Update show text?"
      isOpen
      onClose={() => setModal({})}
      variant="small"
      confirmation={{
        onConfirm: handleConfirm,
        label: "Update Copy",
        cancelLabel: "No Thanks",
      }}
    >
      <div style={{ display: "flex", margin: "32px 0 16px" }}>
        <Body1>
          Would you like us to generate new text based on your updated
          selection? If we do, it will replace previously edited copy.
        </Body1>
      </div>
    </Modal>
  );
};
