import {
  EditIconButton,
  EditableContent,
  useBuilderContext,
  useConcerts,
  useConfigSlice,
} from "Components";
import { H2, H3, H4, Label } from "melodies-source/Text";
import { ListItem } from "melodies-source/ListItem";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { Datepicker } from "melodies-source/DatePicker";
import { DateTime } from "luxon";
import { IconLocationCard } from "Images/CardIcons";
import { SvgBack } from "melodies-source/Svgs/Back";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { SvgRight } from "melodies-source/Svgs/Right";
import {
  Autocomplete as AutocompleteBase,
  Option as OptionProps,
} from "melodies-source/Autocomplete";
import { PlacesAutocomplete } from "Components/PlacesAutocomplete";
import { InfoCard } from "Components/InfoCard";
import { TextInput } from "melodies-source/TextInput";
import { SvgCalendar } from "melodies-source/Svgs/Calendar";
import {
  GOOGLE_PLACES_ESTABLISHMENT_TYPES,
  getTimeZoneIdByGeocode,
} from "Utils/maps";
import { Concert } from "@max/common";

const EditStates = ["time", "venue", "location", "places", "listing"] as const;
type EditState = (typeof EditStates)[number];
type UpdatedOption = Omit<OptionProps, "data"> & {
  data?: Concert & { luxonDate: DateTime };
};

export const Location = () => {
  const [manualEntry, setManualEntry] = useState(false);
  const [edit, setEdit] = useState<EditState | null>(null);
  const [concertData, concertLoading] = useConcerts();

  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "startTime",
    "concert",
  ]);
  const nav = useNavigate();
  const [preselected, setPreselected] = useState(false);

  const concerts: UpdatedOption[] =
    concertData
      ?.map((c) => {
        // @ts-ignore
        const luxonDate = DateTime.fromJSDate(c.date.toDate()).setZone(
          c.timeZone,
        );
        return {
          icon: <SvgLocation />,
          label: c.venue,
          caption: c.address || `${c.city}, ${c.state}`,
          caption2: luxonDate.toFormat("cccc, LLL dd • h:mm a ZZZZ"),
          value: c.id,
          data: { ...c, luxonDate },
        };
      })
      ?.sort(
        (a, b) => a.data.luxonDate.toMillis() - b.data.luxonDate.toMillis(),
      ) || [];

  const handleListingChange = (opt: UpdatedOption) => {
    let update = { ...opt.data, address: opt.caption } || {};
    //@ts-ignore
    delete update.luxonDate;
    //@ts-ignore
    setField("concert", update);
    setField("startTime", opt.data?.luxonDate);
  };

  const handlePlaceSelect = async (
    place: google.maps.GeocoderResult,
    placeName: string,
  ) => {
    if (place && placeName) {
      const { lat, lng } = place.geometry.location;
      //@ts-ignore
      let update: Concert = {
        locationAddedManually: true,
        address: place.formatted_address,
        coords: [lat(), lng()],
        source: "manual",
        geocode: JSON.parse(JSON.stringify(place)),
        state: "",
        city: "",
        country: "",
        title: "",
        venue_id: "",
        artists: [],
        id: "",
        deletedAt: null,
      };
      const timeZone = await getTimeZoneIdByGeocode(lat(), lng());
      if (timeZone) {
        update.timeZone = timeZone;
      }
      const checkPlaceType = place.types.findIndex((type) =>
        GOOGLE_PLACES_ESTABLISHMENT_TYPES.includes(type),
      );
      if (checkPlaceType > -1) {
        update.venue = placeName;
        update.addressType = "establishment";
      } else {
        //@ts-ignore
        update.venue = place.formatted_address.split(",")[0];
        update.addressType = "address";
      }
      setField("concert", update);
      setField("startTime", fields?.startTime?.content?.setZone(timeZone));
      setEdit(null);
    }
  };

  useEffect(() => {
    if (concertLoading) {
      return;
    }
    if (!fields.concert.content) {
      if (!!concerts?.length) {
        handleListingChange(concerts[0]);
        setPreselected(true);
      } else {
        setManualEntry(true);
        setEdit("location");
      }
    }
  }, [concertData]);

  const addressType = fields.concert.content?.addressType;
  const manualLocation = fields.concert.content?.locationAddedManually;
  const nowDate = DateTime.now().setZone(fields.concert.content?.timeZone);

  return (
    <Container>
      <Button
        text
        leftIcon={<SvgBack />}
        style={{ width: "fit-content", margin: "0 0 20px -15px" }}
        onClick={() => nav("../theme")}
        nudgeLeft
      >
        Back
      </Button>
      {manualEntry || manualLocation ? (
        <>
          <H2 style={{ whiteSpace: "nowrap" }}>
            What show will this be used at?
          </H2>
          <EditableContent
            secondary
            name="location"
            edit={edit}
            setEdit={setEdit}
            label="Location"
            onCancel={manualLocation ? () => setEdit(null) : undefined}
            editState={
              <PlacesAutocomplete
                placeholder="Enter a venue or address..."
                onChange={handlePlaceSelect}
                style={{ marginTop: -4 }}
                secondary
              />
            }
          >
            {addressType !== "address" ? (
              <div>
                <H3>{fields.concert.content?.venue}</H3>
                <H4>{fields.concert.content?.address}</H4>
              </div>
            ) : (
              <div>
                <H3>{fields.concert.content?.address?.split(",")[0]}</H3>
                <H4>
                  {fields.concert.content?.address?.slice(
                    fields.concert.content?.address.indexOf(",") + 1,
                  )}
                </H4>
              </div>
            )}
          </EditableContent>

          {addressType === "address" && (
            <EditableContent
              label="Venue Name"
              name="venue"
              edit={edit}
              setEdit={setEdit}
              secondary
              onConfirm={() => setEdit(null)}
              onCancel={() => setEdit(null)}
              editState={
                <TextInput
                  secondary
                  onChange={(v) =>
                    //@ts-ignore
                    setField("concert", { ...fields.concert.content, venue: v })
                  }
                  value={fields.concert.content?.venue}
                  placeholder="Enter venue name..."
                />
              }
            >
              {fields.concert.content?.venue && (
                <H3>{fields.concert.content?.venue}</H3>
              )}
            </EditableContent>
          )}

          <Datepicker
            label="Date and time"
            minDate={nowDate}
            value={fields.startTime.content || nowDate}
            onChange={(v) => setField("startTime", v)}
            onClose={() => setEdit(null)}
            onOpen={() => setEdit("time")}
            style={{ margin: "16px 0 0" }}
            disabled={!!(edit && edit !== "time")}
            renderInput={
              <>
                <Label style={{ color: "#ffffff80", marginBottom: 4 }}>
                  Date and time
                </Label>
                <H3 style={{ display: "flex", alignItems: "center" }}>
                  <SvgCalendar
                    style={{ marginRight: 12, color: "#ffffff80" }}
                  />
                  {fields.startTime.content?.toFormat(
                    "LL/dd/yyyy • h:mm a (ZZZZ)",
                  )}
                  <IconButton
                    style={{
                      position: "relative",
                      marginLeft: 12,
                      bottom: "initial",
                    }}
                    disabled={!!(edit && edit !== "time")}
                  >
                    <SvgEditAlt />
                  </IconButton>
                </H3>
              </>
            }
          />
        </>
      ) : (
        <EditableContent
          name="listing"
          edit={edit}
          setEdit={setEdit}
          secondary
          disableEdit
          editState={
            <>
              <H2>Select a show or manually add a new show:</H2>
              <AutocompleteBase
                label="Show Search"
                secondary
                onChange={(opt) => {
                  handleListingChange(opt);
                  setEdit(null);
                }}
                setCustom={() => {
                  setEdit("location");
                  setManualEntry(true);
                }}
                options={concerts || []}
                placeholder="Search by city or venue..."
                buttonText="Manually add a show"
                style={{ marginTop: 16 }}
              />
            </>
          }
        >
          <Container>
            <H2>
              {preselected
                ? "We've found your next show to start with:"
                : "Select a show or manually add a new show:"}
            </H2>
            <ShowWrapper>
              <MapIcon>
                <SvgLocation />
              </MapIcon>
              <ListItem
                style={{
                  borderLeft: "1px solid #383441",
                  padding: "0 0 0 16px",
                }}
                isLarge={true}
                caption={fields.concert.content?.address}
                caption2={fields.startTime.content
                  ?.setZone(fields.concert.content?.timeZone)
                  .toFormat("cccc, LLL dd • h:mm a (ZZZZ)")}
              >
                {fields?.concert?.content?.venue || ""}
              </ListItem>
            </ShowWrapper>
            <EditButton
              variant="round"
              onClick={() => {
                setEdit("listing");
                setPreselected(false);
              }}
              leftIcon={<SvgEditAlt />}
              style={{ fontWeight: "500", padding: "7px 20px 7px 15px" }}
            >
              Choose different show
            </EditButton>
          </Container>
        </EditableContent>
      )}

      <Button
        style={{ width: 180, marginTop: 32 }}
        rightIcon={<SvgRight />}
        disabled={!isValid || !!edit?.length}
        onClick={() => {
          save();
          nav(`../checkinvote`);
        }}
        nudgeRight
      >
        Next
      </Button>
      <InfoCard
        text="You can change the show, or add additional shows at the end of the setup process."
        style={{ marginTop: 40 }}
        icon={<IconLocationCard />}
      />
    </Container>
  );
};

const IconButton = styled(EditIconButton)`
  position: absolute;
  bottom: 4px;
  right: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  max-width: 400px;

  ${H2} {
    margin-bottom: 8px;
  }
`;

const ShowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const MapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #383441;
  border-radius: 50px;
  padding: 6px;
  margin-right: 15px;
  svg {
    height: 32px;
    width: 32px;
  }
`;

const EditButton = styled(Button)`
  width: auto;
  height: auto;
  padding: 3px 18px 3px 12px;
  line-height: 20px;
  align-self: flex-start;
  margin-left: 74px;
  margin-top: 20px;
  & > div {
    margin-right: 6px;
  }
`;
