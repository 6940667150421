import React from "react";
import styled, { css } from "styled-components";
import { SvgCheck } from "../Svgs/Check";

const CheckIcon = styled(SvgCheck)`
  height: 14px;
  width: 14px;
  margin-top: 3px;
`;

export const CheckIconContainer = styled.div`
  height: 14px;
  width: 14px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  background: transparent;
  margin: 3px;
  ${(props: any) =>
    props.isSelected &&
    !props.disabled &&
    css`
      background: #ffffff;
      border: 1px solid white;
      cursor: default;
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {
  isSelected: boolean;
  icon?: any;
  disabled?: boolean;
  style?: any;
};
export const CheckboxInput = ({
  isSelected,
  icon = null,
  disabled,
  ...props
}: Props) => {
  return (
    <CheckIconContainer {...{ isSelected, disabled, ...props }} tabIndex={0}>
      {isSelected &&
        !disabled &&
        (icon || (
          <IconContainer>
            <CheckIcon />
          </IconContainer>
        ))}
    </CheckIconContainer>
  );
};

export const IconContainer = styled.div`
  text-align: center;
  color: #000000;
`;
