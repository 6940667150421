import { Body1, H3, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Slice, SliceInternal } from "../Slice";
import { DateTime } from "luxon";
import { Datepicker as DatepickerBase } from "melodies-source/DatePicker";
import { SvgCalendarLarge } from "melodies-source/Svgs/CalendarLarge";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import {
  EditIconButton,
  EditableContent,
  EditableContentContainer,
  useBuilderContext,
} from "Components";
import { SvgCalendar } from "melodies-source/Svgs/Calendar";
import {
  GoogleMap,
  LoadScriptProps,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { PlacesAutocomplete } from "Components/PlacesAutocomplete";
import {
  GOOGLE_MAP_PIN_ICON,
  GOOGLE_PLACES_ESTABLISHMENT_TYPES,
  getTimeZoneIdByGeocode,
} from "Utils/maps";

const EditStates = [
  "time",
  "venue",
  "address",
  "places",
  "listing",
  "displayedName",
] as const;
type EditState = (typeof EditStates)[number];

const libraries = ["places"] as LoadScriptProps["libraries"];

const googleMapOptions = {
  mapContainerStyle: {
    width: "100%",
    height: "100%",
  },
  zoom: 10,
  options: {
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    scrollwheel: false,
    keyboardShortcuts: false,
    gestureHandling: "none",
    mapId: "94f76648483db241",
  },
};

export const Location = () => {
  return (
    <Slice
      fields={["concert", "startTime", "displayedName"]}
      content={Internal}
      title="Show Details"
      icon={<SvgCalendarLarge />}
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isValid,
  isActive,
  activeSlice,
  request,
}: SliceInternal) => {
  const [edit, setEdit] = useState<EditState | null>(null);
  const [isMarkerVisible, setIsMarkerVisible] = useState<boolean>(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
    libraries: libraries,
  });
  const {
    baseData: { artistName },
  } = useBuilderContext();

  useEffect(() => {
    if (!activeSlice) {
      request();
    }
  }, []);

  const showMarker = () => {
    setTimeout(() => {
      setIsMarkerVisible(true);
    }, 100);
  };

  const handlePlaceSelect = async (
    place: google.maps.GeocoderResult,
    placeName: string,
  ) => {
    if (place && placeName) {
      const { lat, lng } = place.geometry.location;
      //@ts-ignore
      let update: Concert = {
        locationAddedManually: true,
        address: place.formatted_address,
        coords: [lat(), lng()],
        geocode: JSON.parse(JSON.stringify(place)),
        source: "manual",
        state: "",
        city: "",
        country: "",
        title: "",
        venue_id: "",
        artists: [],
        id: fields?.concert?.content?.id || "",
        deletedAt: null,
      };
      const timeZone = await getTimeZoneIdByGeocode(lat(), lng());
      if (timeZone) {
        update.timeZone = timeZone;
      }
      const checkPlaceType = place.types.findIndex((type) =>
        GOOGLE_PLACES_ESTABLISHMENT_TYPES.includes(type),
      );
      if (checkPlaceType > -1) {
        update.venue = placeName;
        update.addressType = "establishment";
      } else {
        update.venue = null;
        update.addressType = "address";
      }
      setField("concert", update);
      setField("startTime", fields?.startTime?.content?.setZone(timeZone));
      setEdit(null);
    }
  };

  const coords = {
    lat: fields.concert.content?.coords[0] || 0,
    lng: fields.concert.content?.coords[1] || 0,
  };

  const nowDate = DateTime.now().setZone(fields.concert.content?.timeZone);

  return (
    <Wrapper>
      <Fields>
        <EditableContent
          name="displayedName"
          edit={edit}
          setEdit={setEdit}
          label="Displayed Artist Name"
          onConfirm={() => setEdit(null)}
          onCancel={() => setEdit(null)}
          editState={
            <TextInput
              onChange={(v) => setField("displayedName", v)}
              value={fields?.displayedName?.content || artistName}
            />
          }
          style={{ marginTop: 0 }}
        >
          {fields?.displayedName?.content ||
            (artistName && (
              <H3>{fields?.displayedName?.content || artistName}</H3>
            ))}
        </EditableContent>
        <EditableContent
          name="venue"
          edit={edit}
          setEdit={setEdit}
          label="Venue Name"
          onConfirm={() => setEdit(null)}
          onCancel={() => setEdit(null)}
          editState={
            <TextInput
              onChange={(v) =>
                setField("concert", { ...fields.concert.content!, venue: v })
              }
              value={fields.concert.content?.venue}
            />
          }
        >
          {fields.concert.content?.venue &&
            ((fields?.concert?.content?.venue.length || 0) > 20 ? (
              <Body1>{fields?.concert?.content?.venue}</Body1>
            ) : (
              <H3>{fields?.concert?.content?.venue}</H3>
            ))}
        </EditableContent>

        <EditableContent
          label="Location"
          name="location"
          edit={edit}
          setEdit={setEdit}
          onCancel={() => setEdit(null)}
          editState={
            <PlacesAutocomplete
              placeholder="Enter a venue or address..."
              onChange={handlePlaceSelect}
              style={{ marginTop: -4 }}
            />
          }
        >
          {fields.concert.content?.address && (
            <Body1>
              {fields.concert.content?.address &&
                ((fields.concert.content?.address.length || 0) > 20 ? (
                  <>
                    {fields.concert.content?.address?.split(",")[0]}
                    <br />
                    {fields.concert.content?.address?.slice(
                      fields.concert.content?.address?.indexOf(",") + 1,
                    )}
                  </>
                ) : (
                  fields.concert.content?.address
                ))}
            </Body1>
          )}
        </EditableContent>

        <Datepicker
          label="Date and time"
          minDate={nowDate}
          value={fields.startTime.content || nowDate}
          onChange={(v) => {
            setField("startTime", v.setZone(fields.concert.content?.timeZone));
          }}
          onClose={() => setEdit(null)}
          onOpen={() => setEdit("time")}
          disabled={!!(edit && edit !== "time")}
          renderInput={
            <>
              <Label style={{ color: "#ffffff80", marginBottom: 4 }}>
                Date and time
              </Label>
              <Body1 style={{ display: "flex", alignItems: "center" }}>
                <SvgCalendar style={{ marginRight: 12, color: "#ffffff80" }} />
                {fields.startTime.content?.toFormat(
                  "LL/dd/yyyy • h:mm a (ZZZZ)",
                )}
                <EditIconButton
                  style={{ marginLeft: 8 }}
                  disabled={!!(edit && edit !== "time")}
                >
                  <SvgEditAlt />
                </EditIconButton>
              </Body1>
            </>
          }
        />
      </Fields>
      <Map>
        {isLoaded && isActive && fields.concert.content?.coords && (
          <GoogleMap
            {...googleMapOptions}
            center={coords}
            onLoad={() => showMarker()}
          >
            {isMarkerVisible && (
              <MarkerF
                position={coords}
                icon={{
                  ...GOOGLE_MAP_PIN_ICON,
                  anchor: new window.google.maps.Point(9, 19),
                }}
                clickable={false}
                animation={window.google.maps.Animation.DROP}
              />
            )}
          </GoogleMap>
        )}
      </Map>
    </Wrapper>
  );
};

const Datepicker = styled(DatepickerBase)`
  margin-top: 16px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  color: #ffffff;
  display: grid;
  grid-template-columns: 60% 1fr;
  column-gap: 16px;
  padding-right: 26px;
  ${(props) => props.theme.mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    padding-right: 0;

    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  ${EditableContentContainer} {
    margin-top: 16px;
  }
`;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  padding-top: 80%;
  background-color: #ffffff;
  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-top: 50%;
  }
`;
