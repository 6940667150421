import { BuilderConfig } from "@max/common";
import { object, string } from "yup";
const stringUrl = string().matches(
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g,
  "Please enter a valid URL.",
);
const schema = object().shape({
  fields: object().shape({
    postShowEmailHeadline: object().when("postShowEmailType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("This field cannot be left blank."),
          }),
        }),
    }),
    postShowEmailSubject: object().when("postShowEmailType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("This field cannot be left blank."),
          }),
        }),
    }),
    postShowEmailBody: object().when("postShowEmailType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string(),
          }),
        }),
    }),
    postShowEmailCta: object().when("postShowEmailCTAType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: stringUrl.required("This field cannot be left blank."),
          }),
        }),
    }),
    postShowEmailCtaLabel: object().when("postShowEmailCTAType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("This field cannot be left blank."),
          }),
        }),
    }),
    postShowEmailCustomHtml: object().when("postShowEmailType.content", {
      is: "html",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("This field cannot be left blank."),
          }),
        }),
    }),
    postShowEmailMerchHeadline: object().when(
      "postShowEmailMerchEnabled.content",
      {
        is: true,
        then: (schema) =>
          schema.shape({
            content: object().shape({
              en: string().required("This field cannot be left blank."),
            }),
          }),
      },
    ),
    postShowEmailMerchCtaLabel: object().when(
      [
        "postShowEmailMerchDisableCta.content",
        "postShowEmailMerchEnabled.content",
      ],
      {
        is: (disableCta: any, merchEnabled: any) => merchEnabled && !disableCta,
        then: (schema) =>
          schema.shape({
            content: object().shape({
              en: string().required("This field cannot be left blank."),
            }),
          }),
      },
    ),
    postShowEmailReleaseHeadline: object().when(
      "postShowEmailReleaseEnabled.content",
      {
        is: true,
        then: (schema) =>
          schema.shape({
            content: object().shape({
              en: string().required("This field cannot be left blank."),
            }),
          }),
      },
    ),
    sweepstakesRulesCustomUrl: object().when("sweepstakesRulesType.content", {
      is: "custom",
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: stringUrl.required("Enter Sweepstakes Rules URL."),
          }),
        }),
    }),
    customMerchTabName: object().when("useCustomMerchTabName.content", {
      is: true,
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("You must enter a tab name."),
          }),
        }),
    }),
    customMainTabName: object().when("useCustomMainTabName.content", {
      is: true,
      then: (schema) =>
        schema.shape({
          content: object().shape({
            en: string().required("You must enter a tab name."),
          }),
        }),
    }),
  }),
});

export const validateField = (field: string, config: BuilderConfig) => {
  try {
    schema.validateSyncAt(field, config);
    return false;
  } catch (err: any) {
    console.log("err.message", err);
    return err.message;
  }
};
