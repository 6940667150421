import { Body1, Body2, H4, Td } from "melodies-source/Text";
import { TextInput as StyledTextInput } from "melodies-source/TextInput";
import { Textarea as StyledTextarea } from "melodies-source/Textarea";
import styled from "styled-components";
import { Slice, SliceInternal } from "../Slice";
import { SvgMail } from "melodies-source/Svgs/Mail";
import { Checkbox, Radio } from "melodies-source/Selectable";
import { SectionDivider } from "../Components/common";
import { useUser } from "auth";
import { Select } from "melodies-source/Select";
import { useBuilderContext } from "Components";
import { Fields, PostShowEmailType } from "@max/common";
import { useEffect } from "react";
import { useDataOverrideContext } from "Routes/Builder/DataOverrideContext";

export const PostShowEmail = () => {
  return (
    <Slice
      fields={[
        "displayedName",
        "hideMerchandise",
        "merchandise",
        "postRegistration",
        "postRegistrationAlbum",
        "postShowEmailType",
        "postShowEmailCTAType",
        "postShowEmailHeadline",
        "postShowEmailSubject",
        "postShowEmailBody",
        "postShowEmailCtaLabel",
        "postShowEmailCta",
        "postShowEmailCustomHtml",
        "postShowEmailMerchEnabled",
        "postShowEmailMerchHeadline",
        "postShowEmailMerchCtaLabel",
        "postShowEmailMerchDisableCta",
        "postShowEmailReleaseEnabled",
        "postShowEmailReleaseHeadline",
      ]}
      content={Internal}
      title="Post Show Email"
      icon={<SvgMail />}
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isValid,
  isActive,
}: SliceInternal) => {
  const { config } = useBuilderContext();
  const { setDisplayEmail } = useDataOverrideContext();
  const user = useUser();
  const type = fields.postShowEmailType.content;
  const ctaType = fields.postShowEmailCTAType.content;

  const disableRadioOptions = type === "html" && !user?.claims?.isAdmin;

  const updateFields = (fields: { [K in Fields]?: string | boolean }) => {
    for (const [field, value] of Object.entries(fields)) {
      setField(
        field as keyof typeof fields,
        typeof value !== "string" ||
          ["type", "enable"].some((v) => field.toLowerCase().includes(v))
          ? value
          : { en: value },
      );
    }
  };

  const contentDefaults = {
    postShowEmailSubject: "Share Your Concert Highlight with Us",
    postShowEmailBody: `We'd love to hear about your show highlight. Your feedback will help ${
      fields.displayedName.content || config.artistName
    } plan the next tour.`,
    postShowEmailCtaLabel: "Share Your Experience",
    postShowEmailCta: `https://set.fan/postshow-${config.artistGroupId}`,
  };

  const handleEmailType = (val: PostShowEmailType) => {
    if (disableRadioOptions) {
      return;
    }
    const shared = {
      postShowEmailType: val,
      postShowEmailMerchEnabled: false,
      postShowEmailReleaseEnabled: false,
    };
    if (["default", "custom"].includes(val)) {
      updateFields({
        postShowEmailCTAType: "survey",
        postShowEmailHeadline: "Thanks for coming to the show!",
        ...shared,
        ...contentDefaults,
      });
    }
    if (["disabled", "html"].includes(val)) {
      updateFields({
        postShowEmailCTAType: "disabled",
        ...shared,
      });
    }
  };

  const handleCtaTypeChange = (v: string) => {
    setField("postShowEmailCTAType", v);
    if (v === "survey") {
      updateFields(contentDefaults);
    } else {
      updateFields({
        postShowEmailSubject: "Thanks for coming to the show!",
        postShowEmailCtaLabel: "",
        postShowEmailCta: "",
        postShowEmailBody: "",
      });
    }
  };

  useEffect(() => {
    if (isActive && ["default", "custom"].includes(type || "")) {
      setDisplayEmail(true);
      return () => {
        setDisplayEmail(false);
      };
    }
  }, [isActive, type]);

  useEffect(() => {
    if (
      fields.postShowEmailType.content === "default" &&
      !fields.postShowEmailHeadline.content?.en
    ) {
      handleEmailType("default");
    }
  }, []);

  return (
    <Container>
      <TopSection>
        <Column style={{ rowGap: 0 }}>
          <Td style={{ marginBottom: 10 }}>
            Select what you want your fans to see
          </Td>
          <Radio
            label="Default"
            onChange={() => type !== "default" && handleEmailType("default")}
            value={type === "default"}
            disabled={disableRadioOptions}
          />
          <Radio
            label="No Email"
            onChange={() => type !== "disabled" && handleEmailType("disabled")}
            value={type === "disabled"}
            disabled={disableRadioOptions}
          />
          <Radio
            label="Custom"
            onChange={() => type !== "custom" && handleEmailType("custom")}
            value={type === "custom"}
            disabled={disableRadioOptions}
          />
          {(user.claims?.isAdmin || type === "html") && (
            <Radio
              label="HTML"
              onChange={() => type !== "html" && handleEmailType("html")}
              value={type === "html"}
              disabled={!user.claims?.isAdmin}
            />
          )}
        </Column>
        <Column>
          <Body1>
            {type !== "disabled"
              ? "Post-show emails are automatically sent out when your show ends. All fans that registered through SET.Live will receive an email."
              : "No post-show emails will be sent."}
          </Body1>
        </Column>
      </TopSection>
      {type === "html" && (
        <Section style={{ gridTemplateColumns: "75%", marginTop: 16 }}>
          <TextInput
            label="Subject"
            placeholder="Enter a subject..."
            onChange={(v) => setField("postShowEmailSubject", { en: v })}
            value={fields.postShowEmailSubject?.content?.en}
            disabled={!user.claims?.isAdmin}
            {...(validation.postShowEmailSubject && {
              helperText: validation.postShowEmailSubject,
              hasError: true,
            })}
          />
          <TextArea
            label="Custom HTML"
            placeholder="Add custom HTML here..."
            onChange={(v) => setField("postShowEmailCustomHtml", { en: v })}
            value={fields.postShowEmailCustomHtml.content?.en}
            style={{ gridColumn: "1 / span 2" }}
            disabled={!user.claims?.isAdmin}
            {...(validation.postShowEmailCustomHtml && {
              helperText: validation.postShowEmailCustomHtml,
              hasError: true,
            })}
          />
        </Section>
      )}
      {type === "custom" && (
        <>
          <Section>
            <Column style={{ marginTop: 16 }}>
              <TextInput
                label="Subject"
                placeholder="Enter a subject..."
                onChange={(v) => setField("postShowEmailSubject", { en: v })}
                value={fields.postShowEmailSubject?.content?.en}
                {...(validation.postShowEmailSubject && {
                  helperText: validation.postShowEmailSubject,
                  hasError: true,
                })}
              />
              <TextInput
                label="Headline"
                placeholder="Enter a headline..."
                onChange={(v) => setField("postShowEmailHeadline", { en: v })}
                value={fields.postShowEmailHeadline.content?.en}
                {...(validation.postShowEmailHeadline && {
                  helperText: validation.postShowEmailHeadline,
                  hasError: true,
                })}
              />
              <TextArea
                label="Body text (optional)"
                placeholder="Enter body content..."
                onChange={(v) => setField("postShowEmailBody", { en: v })}
                value={fields.postShowEmailBody.content?.en}
              />
            </Column>
            <Column style={{ marginTop: 16 }}>
              <Select
                label="Email action type"
                placeholder="Call to action type"
                onChange={handleCtaTypeChange}
                options={[
                  { label: "Post Show Survey", value: "survey" },
                  { label: "Custom Label & URL", value: "custom" },
                  { label: "Disabled", value: "disabled" },
                ]}
                value={ctaType}
              />
              {ctaType !== "disabled" && (
                <>
                  <TextInput
                    label="Button label"
                    placeholder="Enter a button label..."
                    onChange={(v) =>
                      setField("postShowEmailCtaLabel", { en: v })
                    }
                    value={fields.postShowEmailCtaLabel?.content?.en}
                    helperText="Limit 30 characters"
                    {...(validation.postShowEmailCtaLabel && {
                      helperText: validation.postShowEmailCtaLabel,
                      hasError: true,
                    })}
                  />
                  <TextInput
                    label="Button URL"
                    placeholder="Enter a url..."
                    onChange={(v) => setField("postShowEmailCta", { en: v })}
                    value={fields.postShowEmailCta?.content?.en}
                    {...(validation.postShowEmailCta && {
                      helperText: validation.postShowEmailCta,
                      hasError: true,
                    })}
                    disabled={ctaType !== "custom"}
                  />
                </>
              )}
            </Column>
          </Section>

          {!!fields.merchandise?.content?.map(({ isSelected }) => !!isSelected)
            .length &&
            !fields.hideMerchandise.content && (
              <>
                <SectionDivider />
                <Section>
                  <Column
                    style={{
                      gridColumn: "1 / span 2",
                      rowGap: 0,
                    }}
                  >
                    <Checkbox
                      label={<H4 style={{ lineHeight: "22px" }}>Show Merch</H4>}
                      onChange={(v) => {
                        setField("postShowEmailMerchEnabled", v);
                        if (v) {
                          updateFields({
                            ...(!fields.postShowEmailMerchHeadline.content
                              ?.en && {
                              postShowEmailMerchHeadline:
                                "Get Merch from our Show",
                            }),
                            ...(!fields.postShowEmailMerchCtaLabel.content
                              ?.en && {
                              postShowEmailMerchCtaLabel:
                                "Visit our Merch Store",
                            }),
                            postShowEmailMerchDisableCta: false,
                          });
                        }
                      }}
                      value={fields.postShowEmailMerchEnabled.content}
                    />
                    {fields.postShowEmailMerchEnabled.content && (
                      <Body2>
                        The items you select from the merchandise section will
                        appear in the email.
                      </Body2>
                    )}
                  </Column>
                  {fields.postShowEmailMerchEnabled.content && (
                    <Column>
                      <TextInput
                        label="Headline"
                        placeholder="Enter a headline..."
                        onChange={(v) =>
                          setField("postShowEmailMerchHeadline", { en: v })
                        }
                        value={fields.postShowEmailMerchHeadline.content?.en}
                        {...(validation.postShowEmailMerchHeadline && {
                          helperText: validation.postShowEmailMerchHeadline,
                          hasError: true,
                        })}
                      />
                      <Column style={{ rowGap: 0 }}>
                        <Checkbox
                          label={
                            <H4 style={{ lineHeight: "22px" }}>
                              Enable Merch Store Button
                            </H4>
                          }
                          onChange={(v) => {
                            setField("postShowEmailMerchDisableCta", !v);
                            if (
                              !v &&
                              !fields.postShowEmailMerchCtaLabel.content?.en
                            ) {
                              updateFields({
                                postShowEmailMerchCtaLabel:
                                  "Visit our Merch Store",
                              });
                            }
                          }}
                          value={!fields.postShowEmailMerchDisableCta.content}
                        />
                        {!fields.postShowEmailMerchDisableCta.content && (
                          <Body2>
                            Choose the text you want displayed on the button
                            that links to your store.
                          </Body2>
                        )}
                      </Column>
                      {config.merchandiseUrl &&
                        !fields.postShowEmailMerchDisableCta.content && (
                          <TextInput
                            label="Button label"
                            placeholder="Enter a button label..."
                            onChange={(v) =>
                              setField("postShowEmailMerchCtaLabel", { en: v })
                            }
                            value={
                              fields.postShowEmailMerchCtaLabel.content?.en
                            }
                            helperText="Limit 30 characters"
                            {...(validation.postShowEmailMerchCtaLabel && {
                              helperText: validation.postShowEmailMerchCtaLabel,
                              hasError: true,
                            })}
                          />
                        )}
                    </Column>
                  )}
                </Section>
              </>
            )}
          {fields.postRegistration.content === "promo" && (
            <>
              <SectionDivider />
              <Section>
                <Column
                  style={{
                    gridColumn: "1 / span 2",
                    rowGap: 0,
                  }}
                >
                  <Checkbox
                    label={
                      <H4 style={{ lineHeight: "22px" }}>
                        Show Featured Release
                      </H4>
                    }
                    onChange={(v) => {
                      setField("postShowEmailReleaseEnabled", v);
                      if (
                        v &&
                        !fields.postShowEmailReleaseHeadline.content?.en
                      ) {
                        updateFields({
                          postShowEmailReleaseHeadline: `Stream ${fields.postRegistrationAlbum.content?.name}`,
                        });
                      }
                    }}
                    value={fields.postShowEmailReleaseEnabled.content}
                  />
                  {fields.postShowEmailReleaseEnabled.content && (
                    <Body2>
                      The release you selected for your show will be displayed
                      in your email.
                    </Body2>
                  )}
                </Column>
                {fields.postShowEmailReleaseEnabled.content && (
                  <Column>
                    <TextInput
                      label="Headline"
                      placeholder="Enter a headline..."
                      onChange={(v) =>
                        setField("postShowEmailReleaseHeadline", { en: v })
                      }
                      value={fields.postShowEmailReleaseHeadline.content?.en}
                      {...(validation.postShowEmailReleaseHeadline && {
                        helperText: validation.postShowEmailReleaseHeadline,
                        hasError: true,
                      })}
                    />
                  </Column>
                )}
              </Section>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  align-content: start;
`;

const TextInput = styled(StyledTextInput)``;
const TextArea = styled(StyledTextarea)``;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  & + & {
    margin-top: 16px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  & + & {
    margin-top: 16px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 80%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;

  ${SectionDivider} {
    margin: 26px 0 20px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${SectionDivider} {
      margin: 24px 0 16px;
    }
  }
`;
