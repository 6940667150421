import * as React from "react";
import { SVGProps } from "react";
export const SvgCalendarLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5,5 L5,6 C5,7.1045695 5.8954305,8 7,8 C8.0543618,8 8.91816512,7.18412221 8.99451426,6.14926234 L9,6 L9,5 L15,5 L15,6 C15,7.0543618 15.8158778,7.91816512 16.8507377,7.99451426 L17,8 C18.0543618,8 18.9181651,7.18412221 18.9945143,6.14926234 L19,6 L19,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,6 C3,5.44771525 3.44771525,5 4,5 L5,5 Z M19,11 L5,11 L5,19 L19,19 L19,11 Z M18,15 L18,18 L15,18 L15,15 L18,15 Z M7,3 C7.55228475,3 8,3.44771525 8,4 L8,6 C8,6.55228475 7.55228475,7 7,7 C6.44771525,7 6,6.55228475 6,6 L6,4 C6,3.44771525 6.44771525,3 7,3 Z M17,3 C17.5522847,3 18,3.44771525 18,4 L18,6 C18,6.55228475 17.5522847,7 17,7 C16.4477153,7 16,6.55228475 16,6 L16,4 C16,3.44771525 16.4477153,3 17,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
