import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Caption, Label } from "../Text";

export const HTMLTextearea = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--max-font-primary);
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  border-radius: 6px;
  background: #ffffff;
  border: solid 1px #cccccc;
  border-radius: 6px;
  padding: 10px 12px;

  resize: none;
  height: 86px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;

  ::placeholder {
    font-size: 15px;
    color: #999999;
    ${(p: any) =>
      p.isDisabled &&
      css`
        color: #e6e9eb;
      `};
  }
  :focus {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${(p: any) =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: #999999;
      border: solid 1px #e6e9eb;
      ${p.secondary &&
      css`
        color: rgba(255, 255, 255, 0.35);
      `}
    `};

  ${(p: any) =>
    p.hasError &&
    css`
      padding-right: 50px;
      border: solid 1px ${(p) => p.theme.colors.error};
    `};
  /* ${Caption} {
    ${(p: any) =>
    p.hasError &&
    css`
      color: #e71e3d;
    `}
  } */
`;

export const TextAreaLabelWrapper = styled.div`
  width: calc(100% - 24px);
  height: 36px;
  padding-bottom: 5px;
  background-color: white;
  position: absolute;
  top: 1px;
  ${(props: any) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
`;
type Wrapper = {
  secondary?: boolean;
  disabled?: boolean;
};
export const TextareaWrapper = styled.div<Wrapper>`
  ${Label} {
    color: #ffffff;
    ${(p) =>
      p.secondary &&
      css`
        color: rgba(255, 255, 255, 0.35);
      `}
  }
`;

type Props = {
  label?: any;
  placeholder?: string;
  value: any;
  onChange: (v: string) => void;
  hasError?: boolean;
  helperText?: ReactNode;
  onBlur?: any;
  style?: any;
  secondary?: boolean;
  disabled?: boolean;
};

export const Textarea = ({
  label = null,
  placeholder = "",
  value,
  onChange,
  hasError = false,
  helperText,
  onBlur = null,
  secondary,
  disabled,
  ...props
}: Props) => {
  return (
    <TextareaWrapper {...{ disabled, secondary }} {...props}>
      {label && <Label>{label}</Label>}
      <div style={{ marginTop: "4px" }}>
        <HTMLTextearea
          {...{ onBlur, hasError, placeholder, disabled, secondary }}
          value={value}
          onChange={(evt) => {
            onChange(evt.target.value);
          }}
        />
        {helperText &&
          (typeof helperText === "string" ? (
            <Caption style={{ marginTop: "4px" }} hasError={hasError}>
              {helperText}
            </Caption>
          ) : (
            helperText
          ))}
      </div>
    </TextareaWrapper>
  );
};
