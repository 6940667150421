import styled, { css, keyframes } from "styled-components";
import loadingBg from "../../Images/bg-main.jpg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SpinnerRing } from "./SpinnerRing";
import {
  IconArtistData,
  IconExperience,
  IconDesign,
  IconMerch,
  IconHeaderPhoto,
  IconStreaming,
  IconRecentReleases,
  IconUpcomingShows,
} from "Images/LoadingIcons";

export const LoadingScreen = () => {
  const { pathname } = useLocation();
  const hasMessage = !pathname?.includes("editor");

  const messageArray = [
    "Loading Artist Data ...",
    "Finding Upcoming Shows ...",
    "Selecting Header Photos ...",
    "Generating Designs ...",
    "Finding Recent Releases ...",
    "Searching for Streaming Links ...",
    "Loading Merchandise ...",
    "Creating Default Experience ...",
  ];
  const iconArray = [
    <IconArtistData />,
    <IconUpcomingShows />,
    <IconHeaderPhoto />,
    <IconDesign />,
    <IconRecentReleases />,
    <IconStreaming />,
    <IconMerch />,
    <IconExperience />,
  ];
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((second) =>
        second < messageArray?.length - 1
          ? second + 1
          : messageArray?.length - 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container background={loadingBg}>
      <img src={loadingBg} alt="background gradient" />
      <div
        style={{
          width: "300px",
          aspectRatio: 1,
        }}
      >
        <SpinnerRing
          svg={iconArray[seconds]}
          text={
            hasMessage && (
              <AnimatedText text={messageArray[seconds]}>
                {messageArray[seconds]}
              </AnimatedText>
            )
          }
        />
      </div>
    </Container>
  );
};

const Container = styled.div<{ background: any }>`
  ${(p) =>
    p.background &&
    css`
      background: ${p.background};
    `}
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & > div {
    // transform: rotate(90deg);
    margin-top: -100px;
  }
`;

const animate2 = keyframes`
   50%   { width: 100%; }
  100%  { width: 0%;   }
`;

const AnimatedText = styled.p<{ text: string }>`
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #000000;

  ${({ text }) =>
    text &&
    css`
      &:before {
        content: "${text}";
        position: absolute;
        overflow: hidden;
        white-space: nowrap;
        width: 0;
        height: 110%;
        color: #ffffff;
        animation-name: ${animate2};
        animation-duration: 3s;
        animation-iteration-count: infinite;
      }
    `}
`;
const Svg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
`;
