import { BuilderConfig } from "@max/common";
import { object, string } from "yup";

const warnings = object().shape({
  fields: object().shape({
    preRegistrationHeadline: object().shape({
      content: object().shape({
        en: string().max(50, "We recommend using 50 characters or less."),
      }),
    }),
    preRegistrationBody: object().shape({
      content: object().shape({
        en: string().max(200, "We recommend using 200 characters or less."),
      }),
    }),
    preRegistrationButtonLabel: object().shape({
      content: object().shape({
        en: string().max(30, "We recommend using 30 characters or less."),
      }),
    }),
    preRegistrationSubtitle: object().shape({
      content: object().shape({
        en: string().max(200, "We recommend using 200 characters or less."),
      }),
    }),
    postRegistrationHeadline: object().shape({
      content: object().shape({
        en: string().max(50, "We recommend using 50 characters or less."),
      }),
    }),
    postRegistrationBody: object().shape({
      content: object().shape({
        en: string().max(200, "We recommend using 200 characters or less."),
      }),
    }),
    postRegistrationButtonLabel: object().shape({
      content: object().shape({
        en: string().max(15, "We recommend using 15 characters or less."),
      }),
    }),
    postRegistrationLinkLabel: object().shape({
      content: object().shape({
        en: string().max(30, "We recommend using 30 characters or less."),
      }),
    }),
    postShowEmailSubject: object().shape({
      content: object().shape({
        en: string().max(50, "We recommend using 50 characters or less."),
      }),
    }),
    postShowEmailCtaLabel: object().shape({
      content: object().shape({
        en: string().max(30, "We recommend using 30 characters or less."),
      }),
    }),
    postShowEmailMerchCtaLabel: object().shape({
      content: object().shape({
        en: string().max(30, "We recommend using 30 characters or less."),
      }),
    }),
  }),
});

export const checkWarnings = (field: string, config: BuilderConfig) => {
  try {
    warnings.validateSyncAt(field, config);
    return false;
  } catch (err: any) {
    return err.message;
  }
};
