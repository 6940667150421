import styled, { css } from "styled-components";
import { LinkText } from "../Text";

type LinkProps = {
  disabled?: boolean;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
};
export const Link = styled(LinkText).attrs({ as: "a" })<LinkProps>`
  color: var(--max-action);
  cursor: pointer;
  text-decoration: underline;
  transition: ${(p) => p.theme.transitions("color").hover};
  &:hover {
    color: #ffffff80;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
      pointer-events: none;
      cursor: default;
    `}
`;
