import styled from "styled-components";
import { Wizard } from "./Wizard";
import { BuilderProvider } from "Components";
import { DataOverrideProvider } from "./DataOverrideContext";
import { MenuBar, Footer } from "Components";
import { ModalProvider } from "Components/ModalContext";

export const Builder = () => {
  return (
    <BuilderProvider>
      <DataOverrideProvider>
        <ModalProvider>
          <Container>
            <MenuBar />
            <Wizard />
            <Footer />
          </Container>
        </ModalProvider>
      </DataOverrideProvider>
    </BuilderProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
  flex-grow: 1;
  padding-top: 68px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-top: 40px;
  }
`;
