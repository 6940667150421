import * as React from "react";
import { SVGProps } from "react";
export const SvgAddLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.5 4 13.5 10.5 20 10.5 20 13.5 13.5 13.5 13.5 20 10.5 20 10.5 13.5 4 13.5 4 10.5 10.5 10.5 10.5 4z"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
