import { EditIconButton, firestore, useBuilderContext } from "Components";
import { Textarea } from "melodies-source/Textarea";
import { useState } from "react";
import styled, { css } from "styled-components";
import { FlexColumn, FlexRow } from "./common";
import { Label } from "melodies-source/Text";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { doc, setDoc } from "firebase/firestore";
import { Modal } from "melodies-source/Modal";
import { Button } from "melodies-source/Button";
import { Spinner } from "melodies-source/Spinner";

export const Disclaimers = () => {
  const [editIdx, setEditIdx] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { config, builderId, baseData } = useBuilderContext();

  const disclaimers = config.customRegister?.en.disclaimers || [];

  const handleUpdate = async (disc: string, idx: number) => {
    if (!baseData.draftId || !disclaimers[idx]) {
      return;
    }
    setLoading(true);
    const updatedDisclaimers = [...disclaimers];
    updatedDisclaimers.splice(idx, 1, disc);
    console.log("updatedDisclaimers", updatedDisclaimers);
    try {
      await setDoc(
        doc(
          firestore,
          "set_fresh_builder_events",
          builderId,
          "versions",
          baseData.draftId,
        ),
        { customRegister: { en: { disclaimers: updatedDisclaimers } } },
        { merge: true },
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setEditIdx(null);
    }
  };

  return (
    <>
      <Container>
        {config.customRegister?.en.disclaimers?.map((d, index) => (
          <EditContainer>
            <DisclaimerContainer>
              <Label dangerouslySetInnerHTML={{ __html: d }} />
            </DisclaimerContainer>
            <Actions>
              <EditIconButton onClick={() => setEditIdx(index)}>
                <SvgEditAlt />
              </EditIconButton>
            </Actions>
          </EditContainer>
        ))}
      </Container>
      {editIdx !== null && (
        <EditDisclaimer
          value={disclaimers[editIdx]}
          onSave={(v) => handleUpdate(v, editIdx)}
          onClose={() => setEditIdx(null)}
          loading={loading}
        />
      )}
    </>
  );
};

type EditDisclaimerProps = {
  value: string;
  onSave: (disclaimer: string) => void;
  onClose: VoidFunction;
  loading: boolean;
};

const EditDisclaimer = ({
  value,
  onClose,
  onSave,
  loading,
}: EditDisclaimerProps) => {
  const [disclaimer, setDisclaimer] = useState(value);

  return (
    <Modal header="Edit Disclaimer" isOpen onClose={onClose}>
      <Content>
        <StyledTextarea
          value={disclaimer}
          onChange={setDisclaimer}
          placeholder="Add disclaimer..."
        />
      </Content>
      <FlexRow style={{ justifyContent: "flex-end", gap: 20 }}>
        <Button text onClick={onClose} style={{ minWidth: 120 }}>
          Cancel
        </Button>
        <Button
          onClick={() => onSave(disclaimer)}
          disabled={loading}
          style={{ minWidth: 120 }}
        >
          {loading ? <Spinner /> : "Save"}
        </Button>
      </FlexRow>
    </Modal>
  );
};

const Content = styled(FlexColumn)`
  padding: 32px 0;
`;

const Actions = styled(FlexColumn)`
  background-color: rgba(255, 255, 255, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 6px 6px 0;
  padding-top: 8px;
  gap: 8px;
  height: 100%;
  align-items: center;
`;

const StyledTextarea = styled(Textarea)`
  & > div {
    margin-top: 0 !important;
  }

  textarea {
    height: 320px;
  }
`;

const DisclaimerContainer = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 6px 0 0 6px;
  background-color: rgba(255, 255, 255, 0.08);
  min-height: 140px;

  ${Label} {
    font-weight: 400;
  }
  a {
    color: #fff;
  }
`;

const Container = styled.div`
  display: grid;
  gap: 24px;

  button {
    justify-self: flex-start;
    min-width: 200px;
  }
`;

const EditContainer = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template: auto / calc(80% - 34px) 34px;

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template: auto / 1fr 34px;
  }
`;
