import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

type ContainerProps = {
  value: boolean;
  disabled: boolean;
};
const Container = styled.div<ContainerProps>`
  width: 42px;
  height: 22px;
  background: #3795bd;
  border: 1px solid #3795bd;
  border-radius: 14px;
  user-select: none;
  ${(props) =>
    !props.value &&
    css`
      background: #cccccc;
      border: 1px solid #cccccc;
    `};
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

type CircleProps = {
  value: boolean;
};
const Circle = styled.div<CircleProps>`
  width: 20px;
  height: 20px;
  border: solid 2px white;
  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  ${(p) =>
    p.value
      ? css`
          right: 0px;
        `
      : css`
          left: 0px;
        `}
`;

export interface SwitchProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  onChange: (
    a: boolean,
    b: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  value: boolean;
  disabled?: boolean;
  style?: any;
}

export const Switch = ({
  value,
  onChange,
  disabled = false,
  ...props
}: SwitchProps) => {
  return (
    <Container
      {...props}
      style={{ position: "relative", textAlign: "center" }}
      value={value}
      disabled={disabled}
      onClick={(e) => onChange?.(!value, e)}
    >
      <Circle style={{ position: "absolute" }} value={value} />
    </Container>
  );
};
