import { createContext, useState, useEffect, FC, useContext } from "react";
import LogRocket from "logrocket";
import { useUser } from "auth";

interface AuthContextProps {}
//@ts-ignore
export const AuthContext = createContext<AuthContextProps>(undefined);

//@ts-ignore
export const AuthProvider = ({ children }) => {
  const { user, isLoggedIn } = useUser();

  useEffect(() => {
    //@ts-ignore
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  if (isLoggedIn && !user?.isAnonymous) {
    return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
  } else {
    if (process.env.REACT_APP_ENV !== "dev") {
      const url = new URL(
        `/user/login?redirect=${window.location.href}`,
        `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
      );
      window.location.href = url.toString();
    } else {
      //wait for authentication to work on localhost
    }
  }
  return null;
};

export const useAuthContext = () => useContext(AuthContext);
