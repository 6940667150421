import { IconLanguages } from "Images/Icons";
import { Slice } from "../Slice";
import { useBuilderContext } from "Components";
import { Modal, ModalHeader } from "melodies-source/Modal";
import { Fragment, useState } from "react";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { Body1, H3, H4, Label, Td } from "melodies-source/Text";
import { Select } from "melodies-source/Select";
import {
  TranslatableContent,
  Languages as LangOpts,
  LanguageCode,
  BuilderConfig,
} from "@max/common";
import styled, { css } from "styled-components";
import { ListItem } from "melodies-source/ListItem";
import { Link } from "melodies-source/Link";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { HTMLTextearea, Textarea } from "melodies-source/Textarea";
import { getTranslatableFields } from "../Components/getTranslatableFields";
import { useUser } from "auth";
import { RulesSelect } from "../Components/RulesSelect";
import { SetLiveRule, useSetLiveRules } from "hooks/useSetLiveRules";

export const Languages = () => {
  return (
    <Slice
      fields={["additionalLanguages"]}
      content={Internal}
      title="Languages"
      icon={<IconLanguages />}
      lastIndex
    />
  );
};

const options = Object.entries(LangOpts).map((l) => ({
  value: l[0] as LanguageCode,
  label: l[1],
}));

const Internal = ({ fields, validation, setField, isValid }: any) => {
  const [language, setLanguage] = useState<LanguageCode | null>();
  const [selectLang, setSelectLang] = useState<string>("");

  const currentLangs = options.filter((o) =>
    fields.additionalLanguages?.content?.includes(o.value),
  );

  const availableLangs = options.filter(
    (o) => !fields.additionalLanguages?.content?.includes(o.value),
  );

  const addLanguage = () => {
    if (!selectLang) {
      return;
    }
    setField(
      "additionalLanguages",
      (fields.additionalLanguages?.content || []).concat([selectLang]),
    );
    setSelectLang("");
  };

  return (
    <div>
      <FlexRow style={{ alignItems: "flex-end" }}>
        <Select
          value={selectLang}
          onChange={setSelectLang}
          label="Add New Language"
          placeholder="Select new language to support..."
          options={availableLangs}
          style={{ maxWidth: 340 }}
          disabled={!availableLangs?.length}
        />
        <Button
          onClick={addLanguage}
          style={{ marginLeft: 16, minWidth: 120 }}
          disabled={!availableLangs?.length || !selectLang}
        >
          Add
        </Button>
      </FlexRow>
      <H4 style={{ color: "#fff", margin: "32px 0 12px", fontWeight: 600 }}>
        Enabled Languages
      </H4>
      {currentLangs?.length ? (
        currentLangs?.map((lang) => (
          <ListItem style={{ borderTop: "1px solid #4c4c4c" }}>
            <EditRow>
              <Td>{lang.label}</Td>
              <Link onClick={() => setLanguage(lang.value)}>
                Edit Translation <SvgEditAlt />
              </Link>
            </EditRow>
          </ListItem>
        ))
      ) : (
        <ListItem style={{ borderTop: "1px solid #4c4c4c" }}>
          No additional languages found.
        </ListItem>
      )}
      {language && (
        <LanguageModal
          language={language}
          close={() => setLanguage(null)}
          setField={setField}
        />
      )}
    </div>
  );
};

const LanguageModal = ({
  language,
  close,
  setField,
}: {
  language: LanguageCode;
  close: () => void;
  setField: (f: string, s: any) => void;
}) => {
  const { config } = useBuilderContext();
  const { claims } = useUser();
  const { setLiveRules } = useSetLiveRules();
  const rules = setLiveRules?.docs.map((doc) => doc.data());
  const translatables = getTranslatableFields(config, claims?.isAdmin);
  const langName = LangOpts[language as keyof typeof LangOpts];
  return (
    <StyledModal
      header={`Edit ${langName} Translation`}
      isOpen={!!language}
      onClose={() => close()}
      variant="large"
    >
      <FieldRow>
        <Left>
          <H3>English</H3>
        </Left>
        <Right>
          <H3>{langName}</H3>
        </Right>
      </FieldRow>
      {Object.keys(translatables).map((group, index) => (
        <Fragment key={`translate-group-${index}`}>
          <GroupTitle style={{ ...(index === 0 && { marginTop: 20 }) }}>
            {group}
          </GroupTitle>
          {translatables[group].map(([field, value]) => {
            return (
              <TranslationRow
                label={value.label}
                field={field}
                value={value?.content}
                language={language}
                setField={setField}
                key={`translate-${field}`}
                rules={rules}
                config={config}
              />
            );
          })}
        </Fragment>
      ))}

      <Button
        style={{ alignSelf: "flex-end", marginTop: 40, minWidth: 120 }}
        onClick={() => close()}
      >
        Done
      </Button>
    </StyledModal>
  );
};

const TranslationRow = ({
  label,
  field,
  language,
  value,
  setField,
  rules,
  config,
  ...props
}: {
  label: string;
  field: string;
  language: LanguageCode;
  value: TranslatableContent | TranslatableContent[];
  setField: (f: string, s: any) => void;
  rules?: SetLiveRule[];
  config: BuilderConfig;
}) => {
  let input;

  switch (field) {
    case "voteOptions":
      const valueArray = value as TranslatableContent[];
      return (
        <FieldRow {...props}>
          <Left>
            <H4>{label}</H4>
            {valueArray.map((v, idx) => (
              <Options key={`vote-option-${idx}`}>
                <Label>Option {idx + 1}:</Label>
                <Body1>{v.en}</Body1>
              </Options>
            ))}
          </Left>
          <Right>
            <H4>{label}</H4>
            {valueArray.map((v, idx) => (
              <Options key={`vote-option-translation-${idx}`}>
                <TextInput
                  label={`Option ${idx + 1}`}
                  value={v?.[language as keyof typeof v]}
                  onChange={(val: any) => {
                    const newV = [...valueArray];
                    newV[idx] = { ...v, [language]: val };
                    setField(field, newV);
                  }}
                />
              </Options>
            ))}
          </Right>
        </FieldRow>
      );

    case "sweepstakesRulesHtml":
    case "sweepstakesRulesLink":
      const enVersionType = config.fields.sweepstakesRulesType.content;
      const selectedListRule = rules?.find(
        ({ slug }) => slug === config.fields.sweepstakesRulesLink.content?.en,
      );
      const generatedUrl =
        (process.env.REACT_APP_RULES_URL || "https://rules.set.live") +
        `/${config.fields.sweepstakesRulesLink.content?.en}`;
      return (
        <FieldRow {...props}>
          <Left>
            <Label>{label}</Label>
            {enVersionType === "html" ? (
              <StyledTextarea
                value={config.fields.sweepstakesRulesHtml.content?.en}
                onChange={() => {}}
              />
            ) : enVersionType === "link" ? (
              <Link href={generatedUrl} target="_blank">
                {selectedListRule?.name}
              </Link>
            ) : (
              "Default Rules"
            )}
          </Left>
          <Right>
            <RulesSelect
              language={language}
              key={`edit-${language}`}
              rules={rules}
            />
          </Right>
        </FieldRow>
      );

    case "postShowEmailCustomHtml":
      return (
        <FieldRow {...props}>
          <Left>
            <StyledTextarea
              label={label}
              value={config.fields.postShowEmailCustomHtml.content?.en}
              onChange={() => {}}
              $isDisabled
            />
          </Left>
          <Right>
            <StyledTextarea
              label={label}
              value={(value as TranslatableContent)?.[language]}
              onChange={(v: any) =>
                setField(field, { ...value, [language]: v })
              }
            />
          </Right>
        </FieldRow>
      );

    default:
      if (["body", "subtitle"].some((v) => field.toLowerCase().includes(v))) {
        input = (
          <Textarea
            label={label}
            value={(value as TranslatableContent)?.[language]}
            onChange={(v: any) => setField(field, { ...value, [language]: v })}
            style={{ minheight: 98 }}
          />
        );
      } else {
        input = (
          <TextInput
            label={label}
            value={(value as TranslatableContent)?.[language]}
            onChange={(v: any) => setField(field, { ...value, [language]: v })}
          />
        );
      }
      break;
  }
  const valueObject = value as TranslatableContent;
  return (
    <FieldRow {...props}>
      <Left>
        <Label>{label}</Label>
        <Body1>{valueObject?.en}</Body1>
      </Left>
      <Right>{input}</Right>
    </FieldRow>
  );
};

const StyledTextarea = styled(Textarea)<{ $isDisabled?: boolean }>`
  ${HTMLTextearea} {
    min-height: 120px;
    font-size: 15px;
    line-height: 20px;
    ${(props) =>
      props.$isDisabled &&
      css`
        overflow-y: scroll;
        color: #999999;
        background-color: transparent;
        border-color: #666666;
      `}
  }
`;

const StyledModal = styled(Modal)`
  ${ModalHeader} {
    margin-bottom: 24px;
  }
`;

const Left = styled.div`
  width: calc(50% - 11px);

  & > ${Label} {
    margin-bottom: 4px;
  }
  ${Label}, ${H4} {
    color: #999999;
  }

  ${Body1} {
    padding: 11px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Right = styled.div`
  width: calc(50% + 11px);
`;

const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 44px;
  margin-top: 16px;

  ${H3} {
    font-weight: 700;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const EditRow = styled(FlexRow)`
  padding-top: 8px;
  padding-bottom: 8px;
  ${Td} {
    flex-grow: 1;
  }

  ${Link} {
    flex-grow: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    svg {
      margin-left: 8px;
    }
  }
`;

const GroupTitle = styled(H4)`
  font-weight: 700;
  padding: 18px 0 0;
  margin-top: 32px;
  border-top: 1px solid #66666666;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  & > ${Label} {
    margin-bottom: 4px;
  }
`;
