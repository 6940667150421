import React from "react";
import styled from "styled-components";
import { Td } from "melodies-source/Text";

const Container = styled.div<CardProps>`
  padding: 16px 20px 16px 10px;
  border-radius: 12px;
  width: 100%;
  background: linear-gradient(103.71deg, #383441 28.74%, #1b1b1d 72.7%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Icon = styled.div`
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 44px;
    height: 44px;
  }
`;

const TextWrap = styled.div`
  color: #ffffff;
  margin-left: 10px;
`;

export type CardProps = {
  icon?: JSX.Element;
  text?: string | JSX.Element;
  style?: React.CSSProperties;
};
export const Card = ({ icon, text, ...props }: CardProps) => {
  return (
    <Container {...props}>
      {icon && <Icon>{icon}</Icon>}
      <TextWrap>
        <Td>{text}</Td>
      </TextWrap>
    </Container>
  );
};
