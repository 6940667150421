import { useBuilderContext, useConcerts } from "Components";
import { Body1, Caption, H2, H3 } from "melodies-source/Text";
import { ListItem } from "melodies-source/ListItem";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { Button } from "melodies-source/Button";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { SvgRight } from "melodies-source/Svgs/Right";
import { Checkbox } from "melodies-source/Selectable";
import { Spinner } from "melodies-source/Spinner";
import { PublishButton } from ".";
import { AddShowModal } from "Components/AddShowModal";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { getFirestore, collection, doc } from "firebase/firestore";
import { Concert } from "@max/common";
import { SvgCheckCircle } from "melodies-source/Svgs/CheckCircle";

type ManualConcert = Partial<Concert>;

export const PostPublish = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    builderId,
    publishStatus,
    config: { artistGroupId },
  } = useBuilderContext();
  const location = useLocation();
  const updatedEvent = !location?.state?.created;
  const [isSelected, setIsSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [customConcerts, setCustomConcerts] = useState<ManualConcert[]>([]);
  const [concertData, concertLoading, b, unfilteredConcerts] = useConcerts();

  const [event] = useDocumentOnce(
    doc(collection(getFirestore(), "set_fresh_events"), builderId)
  );
  const url = new URL(
    process.env.REACT_APP_ARTIST_PORTAL_SHOWS?.replace(
      "{id}",
      event?.get("groupId")
    ) || ""
  );
  url.search = `b=${builderId}`;
  const apURL = url.toString();

  let concerts = unfilteredConcerts
    ?.map((c: any) => {
      const luxonDate = DateTime.fromJSDate(c.date.toDate()).setZone(
        c.timeZone
      );
      return {
        icon: <SvgLocation />,
        label: c.venue,
        caption: `${c.city}, ${c.state}`,
        caption2: luxonDate.toFormat("h:mm a ZZZZ  •  cccc, LLL dd"),
        concertId: c.id,
        data: { ...c, luxonDate },
        setliveEventId: c.setliveEventId,
      };
    })
    ?.sort(
      (a: any, b: any) =>
        a.data.luxonDate.toMillis() - b.data.luxonDate.toMillis()
    )?.filter(e => e.setliveEventId !== builderId);

  if (customConcerts) {
    const formatted = customConcerts.map((c: any) => {
      const luxonDate = c.date;
      return {
        icon: <SvgLocation />,
        label: c.venue,
        // caption: `${c.city}, ${c.state}`,
        locationAddedManually: true,
        caption2: luxonDate.toFormat("h:mm a ZZZZ  •  cccc, LLL dd"),
        id: c.id,
        isSelected: c.isSelected,
        data: { ...c, luxonDate },
        setliveEventId: c.setliveEventId,
      };
    });
    //@ts-ignore
    concerts = [...(concerts || []), ...formatted];
  }

  // useEffect(() => {
  //   if (!!concerts) {
  //     setCustomConcerts(concerts);
  //   }
  // }, [unfilteredConcerts]);

  const returnToAP = () => {
    const url = new URL(
      process.env.REACT_APP_ARTIST_PORTAL_SHOWS?.replace(
        "{id}",
        artistGroupId || ""
      ) || ""
    );
    if (publishStatus === "complete") {
      url.search = `b=${builderId}`;
    }
    window.location.href = url.toString();
  };

  useEffect(() => {
    if (!!selectAll) {
      let allConcerts = concerts?.map((c) => c?.concertId);
      if (allConcerts) {
        setIsSelected(allConcerts);
        //@ts-ignore
        setCustomConcerts((c) => c?.map((c) => ({ ...c, isSelected: true })));
      }
    } else {
      setIsSelected([]);
      setCustomConcerts((c) => c?.map((c) => ({ ...c, isSelected: false })));
    }
  }, [selectAll]);

  if (!concertLoading && !concerts?.length && event) {
    window.location.href = apURL;
  }

  if (concertLoading) {
    return <Container style={{ width: "400px" }} />;
  }

  return (
    <Container>
      <Header>
        <IconContainer>
          <SvgCheckCircle />
        </IconContainer>
        <Content>
          <H2>Show {updatedEvent ? "updated" : "published"}</H2>
          <H3>
            Your experience for {event?.get("venue")} on{" "}
            {DateTime.fromJSDate(event?.get("startsAt").toDate()).toFormat(
              "LLL dd"
            )}{" "}
            {updatedEvent ? "was updated" : "is live"}
          </H3>
          <Button
            onClick={() => returnToAP()}
            variant="outlined"
            style={{ width: "fit-content", padding: "0 25px", marginTop: 15 }}
          >
            Exit to Dashboard
          </Button>
        </Content>
      </Header>
      <Body1 style={{ marginBottom: 12 }}>
        Choose any additional shows you want to{" "}
        {updatedEvent ? "add these updates to" : "use this at:"}
      </Body1>
      <SelectContainer>
        <Checkbox
          value={selectAll}
          onChange={() => {
            setSelectAll(!selectAll);
          }}
        />{" "}
        <Body1>Select/Deselect All</Body1>
      </SelectContainer>
      <ConcertWrapper>
        {concertLoading ? (
          <Spinner />
        ) : (
          concerts?.map((c: any) => (
            <ShowWrapper key={c.concertId}>
              <CheckBoxWrapper>
                <Checkbox
                  //disabled={!!c.setliveEventId}
                  value={
                    c.locationAddedManually
                      ? c.isSelected
                      : isSelected.includes(c.concertId)
                  }
                  onChange={() => {
                    if (c.locationAddedManually) {
                      const index = customConcerts.findIndex(
                        (d) => d.id === c.id
                      );
                      const update = [...customConcerts];
                      update[index] = {
                        ...update[index],
                        //@ts-ignore
                        isSelected: !update[index].isSelected,
                      };
                      setCustomConcerts(update);
                      return;
                    }
                    if (isSelected.includes(c.concertId)) {
                      let filteredAr = isSelected.filter(
                        (id) => id !== c.concertId
                      );
                      setIsSelected(filteredAr);
                    } else {
                      let idArray = [...isSelected];
                      idArray.push(c.concertId);
                      setIsSelected(idArray);
                    }
                  }}
                />
              </CheckBoxWrapper>
              <ListItem
                //@ts-ignore
                disabled={!!c.setliveEventId}
                style={{ paddingLeft: 15 }}
                caption={c?.caption}
                //@ts-ignore
                caption2={
                  <>
                    {c?.caption2}
                    {!!c.setliveEventId && (
                      <Caption style={{ color: "#999999" }}>
                        <SvgCheck
                          style={{
                            width: 12,
                            height: 12,
                            margin: "2px 0 -2px",
                          }}
                        />{" "}
                        Has SET.Live Experience
                      </Caption>
                    )}
                  </>
                }
              >
                {c?.label}
              </ListItem>
            </ShowWrapper>
          ))
        )}
      </ConcertWrapper>
      <Button
        text
        leftIcon={<SvgAddAlt />}
        style={{ width: "fit-content", margin: "15px auto -10px -10px" }}
        onClick={() => setIsOpen(true)}
      >
        Manually Add show
      </Button>
      <ButtonWrapper>
        <PublishButton
          style={{ color: "#1b0076", padding: "0 30px" }}
          disabled={!isSelected.length && !customConcerts.length}
          additional={[...isSelected]}
          manual={
            customConcerts
              //@ts-ignore
              .filter((f) => f.isSelected)
              .map((c) => {
                delete c.id;
                return c;
              }) as Concert[]
          }
          nudgeRight
          variant="primary"
          label={`${updatedEvent ? "Update" : "Publish"} ${
            isSelected?.length || ""
          } Selected`}
          status="published"
          callback={() => {
            window.location.href = apURL;
          }}
        />
      </ButtonWrapper>
      <AddShowModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setCustomConcerts={setCustomConcerts}
        customConcerts={customConcerts}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  max-width: 400px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 400px;
  gap: 10px;
  margin-bottom: 70px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
const IconContainer = styled.div`
  color: #fff;
  display: flex;
  svg {
    width: 70px;
    height: 70px;
    margin-top: 15px;
  }
`;
const ConcertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  gap: 7px;
  background: linear-gradient(0.12deg, #383441 2.65%, #1b1b1d 95.26%);
  border-radius: 12px;
  padding: 20px 10px 25px 25px;
  overflow-y: auto;
  ${scrollBarDarkCss};
`;
const ShowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 0;
  ${(props) => props.theme.mediaQueries.mobile} {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 6px;
  margin-right: -5px;
  svg {
    height: 12px;
    width: 12px;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  gap: 5px;
`;
