import {
  addDoc,
  collection,
  deleteField,
  Firestore,
  getFirestore,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

type Action =
  | "add"
  | "approve"
  | "backfill"
  | "cancel"
  | "change"
  | "connect"
  | "create"
  | "delete"
  | "download"
  | "duplicate"
  | "generate"
  | "publish"
  | "query"
  | "reject"
  | "remove"
  | "replace"
  | "request"
  | "run";

type Component = "portal" | "setlive";

type Descriptor =
  | "artist"
  | "community"
  | "entries"
  | "event"
  | "events"
  | "fans"
  | "groups"
  | "klaviyo"
  | "laylo"
  | "mailchimp"
  | "multiday"
  | "results"
  | "stripe"
  | "surveys"
  | "sweepstakes"
  | "teammate"
  | "umg";

export interface UserActionDoc {
  action: `${Component}_${Action}` | `${Component}_${Action}_${Descriptor}`;
  createdAt: Timestamp;
  metadata?: Record<string, unknown>;
  uid: string;
}

export const logUserAction = async (
  {
    action,
    artistGroupId,
    metadata,
    uid,
  }: Omit<UserActionDoc, "createdAt"> & { artistGroupId: string },
  firestore?: Firestore,
): Promise<{
  success: boolean;
}> => {
  const f = firestore ?? getFirestore();
  try {
    await addDoc(collection(f, `artist_groups/${artistGroupId}/user_actions`), {
      createdAt: serverTimestamp(),
      action,
      uid,
      metadata: metadata || deleteField(),
    });
    return { success: true };
  } catch (err) {
    console.error(
      `There was a problem logging a user action: ${(err as Error).message}`,
    );
    return { success: false };
  }
};
