import {
  BuilderConfig,
  BuilderConfigDocument,
  getColorConfig,
  getSocialLinks,
} from "@max/common";
import { Messages } from "@max/common/email";
import { firebaseApp, useBuilderContext, useConfigSlice } from "Components";
import { getFunctions, httpsCallable } from "firebase/functions";
import { HTMLAttributes, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import emailClientHeaderLight from "Images/email-header-light.png";
import emailClientHeaderDark from "Images/email-header-dark.png";
import { Spinner } from "melodies-source/Spinner";
import { Body2 } from "melodies-source/Text";

interface PreviewRequest<T extends keyof Messages> {
  templateId: T;
  data: Parameters<Messages[T]>[0];
}

type PreviewResponse = {
  success?: boolean;
  html: string;
  subject: string;
  error?: string;
};

export const EmailPreview = () => {
  const [email, setEmail] = useState("");
  const [subjectReqUpdate, setSubjectReqUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { config, builderId, baseData } = useBuilderContext();
  const [, , setField] = useConfigSlice(["postShowEmailSubject"]);
  const functions = getFunctions(firebaseApp);

  const getEmailHtml = httpsCallable<
    PreviewRequest<"setlive:postshow">,
    PreviewResponse
  >(functions, "communicationv2-preview");

  const getTemplate = async () => {
    setLoading(true);
    if (!config || !builderId || !baseData.draftId) {
      return;
    }
    const data = getData(config, builderId, baseData.draftId);
    try {
      const response = await getEmailHtml({
        templateId: "setlive:postshow",
        data,
      });

      if (response.data?.html) {
        setEmail(response.data.html);
      }

      if (
        !!response.data?.subject &&
        !config.fields.postShowEmailSubject.content?.en &&
        !subjectReqUpdate
      ) {
        setSubjectReqUpdate(true);
        setField("postShowEmailSubject", { en: response.data.subject });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => getTemplate(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [config]);

  const isDarkUi = [3, 4].includes(config.fields.type.content);
  const colors = isDarkUi
    ? { headerColor: "#fff", bodyColor: "#999", backgroundColor: "#000" }
    : { headerColor: "#333", bodyColor: "#666", backgroundColor: "#fff" };

  if (!email) {
    return (
      <LoadingContainer
        style={{
          backgroundColor: isDarkUi
            ? "rgba(0,0,0,.32)"
            : "rgba(255,255,255,.32)",
        }}
      >
        <Body2 style={{ color: colors.bodyColor }}>Generating Preview</Body2>
        <Spinner color={isDarkUi ? "#fff" : "#ccc"} />
      </LoadingContainer>
    );
  }

  const displayFrom = getFromEmail(config);

  return (
    <Container>
      <IFrame style={{ backgroundColor: colors.backgroundColor, margin: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.backgroundColor,
            backgroundImage: isDarkUi
              ? `url(${emailClientHeaderDark})`
              : `url(${emailClientHeaderLight})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "left bottom",
            paddingBottom: "6.93%",
            width: "100%",
          }}
        >
          <div style={{ minHeight: 32, width: "100%" }}>
            <h3
              style={{
                color: colors.headerColor,
                fontFamily: "Poppins",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: 500,
                margin: "8px 0 0 0",
                padding: "0 2.67%",
              }}
            >
              {config.fields.postShowEmailSubject.content?.en}
            </h3>
          </div>
          <h4
            style={{
              color: colors.headerColor,
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              margin: "13px 0 0 0",
              padding: "0 24% 0 18%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {displayFrom}
          </h4>
        </div>
        {parse(
          `<html style="background-color:${colors.backgroundColor};padding:4px;margin:0;">
            <style>
              body { margin: 0; color: ${colors.bodyColor}; }
              a { text-align: center; }
              table[align='center'] { text-align: center; }
            </style>
            <div style="pointer-events: none; user-select: none;">${email}</div>
          </html>`,
        )}
      </IFrame>
    </Container>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${Body2} {
    margin-bottom: 8px;
    font-weight: 500;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

interface IFrameProps extends HTMLAttributes<HTMLIFrameElement> {
  children: ReactNode;
}

const IFrame = ({ children, ...props }: IFrameProps) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} title="Email Preview" ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

const getFromEmail = (eventConfig: BuilderConfig) => {
  const from = (
    eventConfig.customPostShowEmail?.from ||
    `${eventConfig.artistName} and SET.Live <noreply@set.live>`
  ).trim();

  if (from.match(/\<[^\>]+\>$/)) {
    return from.replace(/<.*>/, "");
  }
  return from;
};

const getData = (
  config: BuilderConfig,
  builderId: string,
  draftId: string,
): PreviewRequest<"setlive:postshow">["data"] => {
  const { selectedColors } = getColorConfig(config);
  const imageVariant =
    config.fields.type.content === 0
      ? "c"
      : [1, 3, 4].includes(config.fields.type.content)
      ? "b"
      : "a";
  const bannerUrl = `${process.env.REACT_APP_ASSETS_ROCKS}/preview/${builderId}/${draftId}/${imageVariant}`;

  const sociallinks = getSocialLinks(config as BuilderConfigDocument);

  const filteredSocialLinks = Object.fromEntries(
    Object.entries(sociallinks).filter(([k, v]) => !!v),
  );

  return {
    artistName: config.artistName || "",
    eventId: builderId,
    selectedColors: selectedColors || { dark: "#666", light: "#fff" },
    eventType: config.fields.type.content,
    bannerUrl,
    ...(!!config.fields.postShowEmailMerchEnabled.content &&
      !!config.fields.merchandise.content?.length && {
        merch: {
          header: config.fields.postShowEmailMerchHeadline.content?.en || "",
          items: config.fields.merchandise.content?.slice(0, 3),
          ...(config.merchandiseUrl &&
            config.fields.postShowEmailMerchCtaLabel.content?.en &&
            !config.fields.postShowEmailMerchDisableCta.content && {
              ctalabel: config.fields.postShowEmailMerchCtaLabel.content?.en,
              url: config.merchandiseUrl,
            }),
        },
      }),
    main: {
      header: config.fields.postShowEmailHeadline.content?.en || "",
      body: config.fields.postShowEmailBody.content?.en,
      ctalabel: config.fields.postShowEmailCtaLabel.content?.en,
      url: config.fields.postShowEmailCta.content?.en,
    },
    ...(config.fields.postRegistrationAlbum.content?.image &&
      config.fields.postShowEmailReleaseEnabled.content && {
        streaming: {
          ...config.fields.postRegistrationAlbum.content,
          header: config.fields.postShowEmailReleaseHeadline.content?.en || "",
          image: config.fields.postRegistrationAlbum.content?.image,
        },
      }),
    socials: filteredSocialLinks,
  };
};
