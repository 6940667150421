import { useBuilderContext, useConfigSlice } from "Components";
import { H2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { GallerySelect } from "melodies-source/GallerySelect";
import { Radio } from "melodies-source/Selectable";
import { useNavigate } from "react-router-dom";
import { useDataOverrideContext } from "../DataOverrideContext";
import { SvgBack } from "melodies-source/Svgs/Back";
import SpotifyModal from "Components/SpotifyModal";
import { InfoCard } from "Components/InfoCard";

import { Release } from "@max/common/creator";
import { filterUndefined } from "@max/common/utils";
import { SvgRight } from "melodies-source/Svgs/Right";
import { UserEventStates } from "@max/common";
import { updateURL } from "Utils/helpers";
import { IconPostRegCard, IconReleaseCard } from "Images/CardIcons";
import { Warning } from "../Editor/Components/common";

export const PostRegistration = () => {
  const nav = useNavigate();
  const { setData } = useDataOverrideContext();
  const { config } = useBuilderContext();
  const [isOpen, setIsOpen] = useState(false);

  const [fields, validation, setField, isValid, save, warnings] =
    useConfigSlice([
      "postRegistration",
      "postRegistrationAlbum",
      "postRegistrationCustomAlbum",
      "postRegistrationLinkLabel",
      "postRegistrationLinkUrl",
      "postRegistrationButtonLabel",
    ]);

  const releases = [
    ...(config?.releases?.slice(0, 5) || []),
    fields?.postRegistrationCustomAlbum?.content,
  ].filter(filterUndefined);

  useEffect(() => {
    setData({
      status: UserEventStates.registered,
      values: {},
    });
    if (!fields.postRegistration.content) {
      if (releases?.length) {
        setField("postRegistration", "promo");
        setField("postRegistrationAlbum", releases[0]);
        setField("postRegistrationButtonLabel", { en: "Save" });
      } else {
        setField("postRegistration", "thank");
      }
    }
    return () => {
      setData(null);
    };
  }, []);

  return (
    <Container>
      <Button
        text
        leftIcon={<SvgBack />}
        style={{ width: "fit-content", margin: "0 0 10px -15px" }}
        onClick={() => nav("../sweepstakes")}
        nudgeLeft
      >
        Back
      </Button>
      <H2 style={{ color: "#fff" }}>
        What do you want to
        <br />
        display post registration?
      </H2>
      <RadioContainer>
        <Radio
          variant="radio"
          value={fields.postRegistration.content === "thank"}
          label="Thank you message"
          onChange={() => {
            setField("postRegistration", "thank");
          }}
        />
        <Radio
          variant="radio"
          value={fields.postRegistration.content === "promo"}
          label="Promote a release"
          onChange={() => {
            setField("postRegistration", "promo");
          }}
        />
        <Radio
          variant="radio"
          value={fields.postRegistration.content === "link"}
          label="Custom Link"
          onChange={() => setField("postRegistration", "link")}
        />
      </RadioContainer>
      {fields.postRegistration.content === "promo" && (
        <>
          <H2>Which release would you like to promote?</H2>
          {releases && (
            <GallerySelect
              style={{ margin: "15px 0 0" }}
              data={releases}
              setValue={(v: any) => {
                setField("postRegistrationAlbum", v);
              }}
              onClick={() => {
                setIsOpen(true);
              }}
              hasButton={!fields?.postRegistrationCustomAlbum?.content}
              //@ts-ignore
              checked={fields?.postRegistrationAlbum?.content?.releaseId}
              replaceItem={() => {
                setIsOpen(true);
              }}
            />
          )}
        </>
      )}
      {fields.postRegistration.content === "link" && (
        <>
          <H2>
            Where do you want to
            <br />
            direct your fans?
          </H2>
          <LabelContainer>
            <TextInput
              label="Button Label"
              placeholder="Enter a button label..."
              onChange={(v) => setField("postRegistrationLinkLabel", { en: v })}
              value={fields.postRegistrationLinkLabel.content?.en}
              secondary
              style={{ margin: "16px 0 8px" }}
              helperText={
                validation.postRegistrationLinkLabel ||
                (warnings.postRegistrationLinkLabel && (
                  <Warning>{warnings.postRegistrationLinkLabel}</Warning>
                )) ||
                "Limit 30 characters"
              }
            />

            <TextInput
              label="Button URL"
              placeholder="Enter a URL..."
              onChange={(v) => setField("postRegistrationLinkUrl", v)}
              onBlur={() => {
                if (!!fields.postRegistrationLinkUrl.content?.length) {
                  setField(
                    "postRegistrationLinkUrl",
                    updateURL(fields.postRegistrationLinkUrl.content),
                  );
                }
              }}
              value={fields.postRegistrationLinkUrl.content}
              hasError={!!validation.postRegistrationLinkUrl}
              helperText={validation.postRegistrationLinkUrl || ""}
              secondary
              style={{ margin: "16px 0 8px" }}
            />
          </LabelContainer>
        </>
      )}
      <Button
        disabled={!isValid}
        onClick={() => {
          save();
          nav(`../privacy`);
        }}
        style={{ width: 180, marginTop: 40 }}
        rightIcon={<SvgRight />}
        nudgeRight
      >
        Next
      </Button>
      <InfoCard
        text={
          fields.postRegistration.content === "promo"
            ? "Fans can save a featured release on the streaming platform of their choice. We'll find the links for you. "
            : "You can change this later in the editor"
        }
        style={{ marginTop: 40 }}
        icon={
          fields.postRegistration.content === "promo" ? (
            <IconReleaseCard />
          ) : (
            <IconPostRegCard />
          )
        }
      />
      <SpotifyModal
        onSelect={(v: Release) => {
          setField("postRegistrationCustomAlbum", v);
          setField("postRegistrationAlbum", v);
        }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 380px;
  color: #ffffff;
`;
const RadioContainer = styled.div`
  margin: 12px 0 24px;
  display: flex;
  flex-direction: column;
`;
const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 25px;
`;
