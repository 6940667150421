import {
  ActionProvider,
  MockDataProvider,
  MockEventConfigProvider,
} from "fan-app/Components";
import { Templates } from "fan-app/Routes/Event/Components/Templates";
import { demoConfigCards as demoConfig } from "fan-app/Configs";
import styled from "styled-components";
import { useBuilderContext } from "./BuilderContext";
import { useDataOverrideContext } from "Routes/Builder/DataOverrideContext";
import phoneFrameImg from "Images/phone-base.png";
import phoneStatusBarImgLight from "Images/phone-status-bar-light.png";
import phoneStatusBarImgDark from "Images/phone-status-bar-dark.png";
import { Timestamp } from "firebase/firestore";
import { generateEventVersion } from "@max/common/creator";
import { EmailPreview } from "Routes/Builder/Editor/Components/EmailPreview";

export const Preview = ({ isThumbnail, ...props }: any) => {
  const { config } = useBuilderContext();
  const { data, displayEmail } = useDataOverrideContext();

  const mock = generateEventVersion<Timestamp>(config, {
    Timestamp,
    demoConfig,
    props,
  });

  const isDarkUi = [3, 4].includes(mock.type);
  const backgroundColor =
    mock.type === 0
      ? "#ffffff"
      : [1, 2].includes(mock.type)
      ? !displayEmail
        ? mock.colors?.light
        : "#ffffff"
      : "#000000";

  return (
    <MockEventConfigProvider mock={mock} eventId="demo">
      <MockDataProvider data={data}>
        <Container {...props}>
          <PhoneStatusBarContainer style={{ backgroundColor }}>
            <PhoneStatusBar
              src={isDarkUi ? phoneStatusBarImgLight : phoneStatusBarImgDark}
            />
          </PhoneStatusBarContainer>
          <TemplateContainer isDark={isDarkUi}>
            {displayEmail && !isThumbnail ? (
              <EmailPreview />
            ) : (
              <ScrollContainer
                isEmailDisplay={displayEmail}
                isThumbnail={isThumbnail}
              >
                <ActionProvider isMock>
                  <ClickBarrier />
                  <Templates />
                </ActionProvider>
              </ScrollContainer>
            )}
          </TemplateContainer>
          <PhoneFrame src={phoneFrameImg} />
          {!isThumbnail && <DropShadow />}
        </Container>
      </MockDataProvider>
    </MockEventConfigProvider>
  );
};

const ClickBarrier = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  z-index: 20;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 396/800;
  width: 100%;
  height: 100%;
`;

const TemplateContainer = styled.div<{ isDark?: boolean }>`
  background-color: ${(p) => (p.isDark ? "#000" : "#fff")};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 5.56% 5% 5.56%;
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 11.65% 5.78%;
  border-bottom-right-radius: 11.65% 5.78%;
  overflow: hidden;
`;

type ScrollContainerPros = {
  isThumbnail?: boolean;
  isEmailDisplay?: boolean;
};
const ScrollContainer = styled.div<ScrollContainerPros>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${(p) => p.isThumbnail && "overflow: hidden;"};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PhoneFrame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const PhoneStatusBarContainer = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 11.65% 73.21%;
  border-top-right-radius: 11.65% 73.21%;
  position: relative;
  margin: 4% 5.56% 0 5.56%;
  height: 7%;
  z-index: 3;
`;

const PhoneStatusBar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const DropShadow = styled.div`
  border-radius: 10.46% 10.46% 10.46% 10.46% / 5.13% 5.13% 5.13% 5.13%;
  box-shadow: 0px 0px 150px rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 8px;
  right: 12px;
  bottom: 8px;
  left: 12px;
  z-index: 0;
`;
