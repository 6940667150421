import { Colors, getColorConfig } from "@max/common";
import { useBuilderContext } from "Components";
import { ColorPicker } from "Components/ColorPicker";
import { DateTime } from "luxon";
import { Datepicker } from "melodies-source/DatePicker";
import { SvgCalendar } from "melodies-source/Svgs/Calendar";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { SvgGear } from "melodies-source/Svgs/Gear";
import { Switch } from "melodies-source/Switch";
import { Caption, H3, H4, Label, Td } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";
import { FlexColumn, SectionDivider } from "../Components/common";
import { Slice, SliceInternal } from "../Slice";
import { generatePrivacyText } from "@max/common";
import { RulesSelect } from "../Components/RulesSelect";
import { useSetLiveRules } from "hooks/useSetLiveRules";
import { CustomRegQuestions, Disclaimers, FooterControl } from "../Components";

const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SectionContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: ${(p) => p.$disabled && 0.5};
`;

export const SectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 40px 40px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);

  ${SectionDivider} {
    margin: 12px 0 4px;
  }

  ${SectionContainer} > ${H4}, ${SectionContainer} * > ${H4} {
    color: #999999;
    font-weight: 600;
  }

  & > ${H3} {
    color: #fff;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    ${SectionContainer}, ${SectionDivider} {
      width: auto;
    }
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    border-radius: 12px;
    gap: 12px;
    padding: 16px 24px 24px;

    ${SectionDivider} {
      margin: 8px 0 4px;
    }

    ${SectionContainer} {
      gap: 12px;
    }

    ${SectionContainer} > ${H4}, ${SectionContainer} * > ${H4} {
      font-size: 14px;
      line-height: 20px;
    }

    & > ${H3} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 80%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 80%;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledSwitch = styled(Switch)`
  background: ${(p) => (p.value ? "#1489e6" : "#000000")};
`;

export const AdvancedControls = () => {
  return (
    <Slice
      fields={[
        "concert",
        "useCustomTermsAndPrivacyPolicy",
        "customTermsAndPrivacyPolicy",
        "separateLineTermsAndPrivacyPolicy",
        "useCustomSMSOptIn",
        "customSMSOptIn",
        "registerTitle",
        "registerSubtitle",
        "registerCtaLabel",
        "makePhoneNumberFieldOptional",
        "makeTextMessageOptInOptional",
        "useMultiDay",
        "multiDayDate",
        "hideMerchandise",
        "useCustomMerchTabName",
        "customMerchTabName",
        "useCustomMainTabName",
        "customMainTabName",
        "masterCampaignId",
        "sweepstakes",
        "sweepstakes",
        "sweepstakesRulesType",
        "sweepstakesRulesLink",
        "sweepstakesRulesHtml",
      ]}
      content={Internal}
      title="Advanced Controls"
      icon={<SvgGear />}
      lastIndex
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isActive,
}: SliceInternal) => {
  const { config } = useBuilderContext();
  const { selectedColors, generateUpdatedColors } = getColorConfig(config);
  const { setLiveRules } = useSetLiveRules();
  const rules = setLiveRules?.docs.map((doc) => doc.data());
  const setSelected = (colorOption: Colors) => {
    if (colorOption?.dark) {
      const updatedColors = generateUpdatedColors(colorOption);
      setField("colors", updatedColors);
    }
  };

  const { sms, terms } = generatePrivacyText(config);

  return (
    <Container>
      <SectionGroup>
        <H3>Event Config</H3>
        <SectionContainer>
          <H4>Master Campaign ID</H4>
          <Grid>
            <TextInput
              value={fields.masterCampaignId.content}
              onChange={(v) => setField("masterCampaignId", v)}
              style={{ maxWidth: 200 }}
            />
          </Grid>
        </SectionContainer>
        <SectionDivider />
        <SectionContainer>
          <H4>Multi-Day</H4>
          <SwitchContainer>
            <StyledSwitch
              value={fields.useMultiDay.content === true}
              onChange={(value) => setField("useMultiDay", value)}
            />
            <SwitchLabel>
              <Td>Enable</Td>
              <Caption>Make this a multi-day event</Caption>
            </SwitchLabel>
          </SwitchContainer>
          {fields.useMultiDay.content === true && (
            <Grid>
              <Datepicker
                minDate={DateTime.now()}
                value={fields.multiDayDate.content || DateTime.now()}
                renderInput={
                  <TextInput
                    label="Multi Day End Date/Time"
                    placeholder="Pick a date"
                    value={fields.multiDayDate.content?.toFormat("ff ZZZZ")}
                    onChange={() => {}}
                    leftIcon={<SvgCalendar />}
                    rightIcon={<SvgDropdown />}
                    style={{ pointerEvents: "none" as const }}
                  />
                }
                onChange={(v) =>
                  setField(
                    "multiDayDate",
                    v.setZone(fields.concert.content?.timeZone),
                  )
                }
              />
            </Grid>
          )}
        </SectionContainer>
      </SectionGroup>
      <SectionGroup>
        <H3>Registration Dialog</H3>
        <SectionContainer>
          <Grid>
            <Textarea
              label={fields.registerTitle.label}
              value={fields.registerTitle.content?.en}
              helperText={validation.registerTitle || ""}
              hasError={!!validation.registerTitle}
              onChange={(v) => setField("registerTitle", { en: v })}
            />
          </Grid>
          <Grid>
            <TextInput
              label={fields.registerSubtitle.label}
              value={fields.registerSubtitle.content?.en}
              helperText={validation.registerSubtitle || ""}
              hasError={!!validation.registerSubtitle}
              onChange={(v) => setField("registerSubtitle", { en: v })}
            />
          </Grid>
          <Grid>
            <TextInput
              label={fields.registerCtaLabel.label}
              value={fields.registerCtaLabel.content?.en}
              helperText={validation.registerCtaLabel || ""}
              hasError={!!validation.registerCtaLabel}
              onChange={(v) => setField("registerCtaLabel", { en: v })}
              style={{ maxWidth: 200 }}
            />
          </Grid>
        </SectionContainer>
      </SectionGroup>

      <SectionGroup>
        <H3>Sweepstakes Rules</H3>
        <SectionContainer $disabled={!fields.sweepstakes.content}>
          {isActive && <RulesSelect rules={rules} language="en" />}
        </SectionContainer>
      </SectionGroup>

      <SectionGroup>
        <H3>Form Settings</H3>
        <SectionContainer>
          <H4>Optional Fields</H4>
          <SwitchContainer>
            <StyledSwitch
              value={fields.makePhoneNumberFieldOptional.content === true}
              onChange={(value) =>
                setField("makePhoneNumberFieldOptional", value)
              }
            />
            <SwitchLabel>
              <Td>Phone Number</Td>
              <Caption>Make phone number field optional</Caption>
            </SwitchLabel>
          </SwitchContainer>
          <SwitchContainer>
            <StyledSwitch
              value={fields.makeTextMessageOptInOptional.content === true}
              onChange={(value) =>
                setField("makeTextMessageOptInOptional", value)
              }
            />
            <SwitchLabel>
              <Td>SMS Opt-in</Td>
              <Caption>Make text message opt-in optional</Caption>
            </SwitchLabel>
          </SwitchContainer>
        </SectionContainer>
        <CustomRegQuestions />
        <SectionDivider />
        <SectionContainer>
          <H4>Custom Opt-Ins</H4>
          <SwitchContainer>
            <StyledSwitch
              value={fields.useCustomTermsAndPrivacyPolicy.content === true}
              onChange={(value) => {
                setField("useCustomTermsAndPrivacyPolicy", value);
                if (value) {
                  if (
                    !fields.customTermsAndPrivacyPolicy.content?.en &&
                    terms
                  ) {
                    setField("customTermsAndPrivacyPolicy", { en: terms });
                  }
                }
              }}
            />
            <SwitchLabel>
              <Td>Terms & Privacy Policy</Td>
              <Caption>Use Custom Terms & Privacy Policy</Caption>
            </SwitchLabel>
          </SwitchContainer>
          {fields.useCustomTermsAndPrivacyPolicy.content === true && (
            <FlexContainer>
              <Textarea
                label={fields.customTermsAndPrivacyPolicy.label}
                placeholder="Enter Custom Terms & Privacy Policy"
                value={fields.customTermsAndPrivacyPolicy.content?.en}
                helperText={validation.customTermsAndPrivacyPolicy || ""}
                hasError={!!validation.customTermsAndPrivacyPolicy}
                onChange={(v) =>
                  setField("customTermsAndPrivacyPolicy", { en: v })
                }
              />
              <Textarea
                label={fields.separateLineTermsAndPrivacyPolicy.label}
                placeholder="Enter Optional Separate Line"
                value={fields.separateLineTermsAndPrivacyPolicy.content?.en}
                helperText={validation.separateLineTermsAndPrivacyPolicy || ""}
                hasError={!!validation.separateLineTermsAndPrivacyPolicy}
                onChange={(v) =>
                  setField("separateLineTermsAndPrivacyPolicy", { en: v })
                }
              />
            </FlexContainer>
          )}
          <SwitchContainer>
            <StyledSwitch
              value={fields.useCustomSMSOptIn.content === true}
              onChange={(value) => {
                setField("useCustomSMSOptIn", value);
                if (value && !fields.customSMSOptIn.content?.en && sms) {
                  setField("customSMSOptIn", { en: sms });
                }
              }}
            />
            <SwitchLabel>
              <Td>Text Messaging</Td>
              <Caption>Use Custom SMS Opt-In</Caption>
            </SwitchLabel>
          </SwitchContainer>
          {fields.useCustomSMSOptIn.content === true && (
            <FlexContainer>
              <Textarea
                style={{ flex: 1 }}
                label={fields.customSMSOptIn.label}
                placeholder="Enter Custom SMS Opt-In"
                helperText={validation.customSMSOptIn || ""}
                onChange={(v) => setField("customSMSOptIn", { en: v })}
                value={fields.customSMSOptIn.content?.en}
                hasError={!!validation.customSMSOptIn}
              />
            </FlexContainer>
          )}
        </SectionContainer>
        {config.customRegister?.en.disclaimers?.length && (
          <>
            <SectionDivider />
            <SectionContainer>
              <H4>Disclaimers</H4>
              <Disclaimers />
            </SectionContainer>
          </>
        )}
      </SectionGroup>

      <SectionGroup>
        <H3>Page Customizations</H3>
        <SectionContainer>
          <H4>Colors</H4>
          <FlexColumn style={{ gap: 8 }}>
            <div style={{ display: "flex", gap: 20 }}>
              <ColorPicker
                label={selectedColors?.light ? "Dark Color" : "Main Color"}
                value={selectedColors?.dark}
                onSelect={(v) => setSelected({ ...selectedColors, dark: v })}
              />
              {selectedColors?.light && (
                <ColorPicker
                  label="Light Color"
                  value={selectedColors?.light}
                  onSelect={(v) => setSelected({ ...selectedColors, light: v })}
                />
              )}
            </div>
          </FlexColumn>
        </SectionContainer>

        <SectionDivider />

        <SectionContainer>
          <H4>Custom Tab Names</H4>
          <FlexColumn style={{ gap: 8 }}>
            <SwitchContainer>
              <StyledSwitch
                value={fields.useCustomMainTabName.content === true}
                onChange={(value) => setField("useCustomMainTabName", value)}
              />
              <FlexColumn>
                <Td>Main Tab</Td>
                <Caption style={{ color: "#ccc" }}>
                  Enable Custom Main Tab Name
                </Caption>
              </FlexColumn>
            </SwitchContainer>
            {fields.useCustomMainTabName.content === true && (
              <Grid>
                <TextInput
                  value={fields.customMainTabName.content?.en}
                  helperText={validation.customMainTabName || ""}
                  hasError={!!validation.customMainTabName}
                  onChange={(v) => setField("customMainTabName", { en: v })}
                />
              </Grid>
            )}
          </FlexColumn>
        </SectionContainer>

        <SectionContainer
          $disabled={fields.hideMerchandise.content}
          style={{ gap: 8 }}
        >
          <SwitchContainer>
            <StyledSwitch
              disabled={fields.hideMerchandise.content}
              value={fields.useCustomMerchTabName.content === true}
              onChange={(value) => setField("useCustomMerchTabName", value)}
            />
            <FlexColumn>
              <Td>Merch Tab</Td>
              <Caption style={{ color: "#ccc" }}>
                Enable Custom Merch Tab Name
              </Caption>
            </FlexColumn>
          </SwitchContainer>
          {fields.useCustomMerchTabName.content === true && (
            <Grid>
              <TextInput
                disabled={fields.hideMerchandise.content}
                value={fields.customMerchTabName.content?.en}
                helperText={validation.customMerchTabName || ""}
                hasError={!!validation.customMerchTabName}
                onChange={(v) => setField("customMerchTabName", { en: v })}
              />
            </Grid>
          )}
        </SectionContainer>

        <SectionDivider />
        <SectionContainer>
          <H4>Footer</H4>
          <FooterControl />
        </SectionContainer>
      </SectionGroup>
    </Container>
  );
};

const SwitchLabel = styled(FlexColumn)`
  ${Caption} {
    color: #ccc;
  }
`;
