import { createContext, useContext, useState } from "react";
import { PrivacyModal, PrivacyModalData } from "./PrivacyModal";
import { Modal, ModalConfirmationProps } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { CopyChangeModal, CopyChangeModalProps } from "./CopyChangeModal";
import { RulesModal } from "./RulesModal";

type ConfirmModalProps = ModalConfirmationProps & {
  body?: string;
  header?: string;
};

export type ModalState = {
  privacy?: PrivacyModalData;
  rules?: boolean;
  confirm?: ConfirmModalProps;
  confirmCopyChange?: CopyChangeModalProps;
};

interface ContextProps {
  modal: ModalState;
  setModal: React.Dispatch<React.SetStateAction<ModalState>>;
}

export const ModalContext = createContext<ContextProps>({} as ContextProps);

interface ProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider = ({ children }: ProviderProps) => {
  const [modal, setModal] = useState<ModalState>({});
  const values = { modal, setModal };

  return (
    <ModalContext.Provider value={values}>
      {!!modal.privacy && <PrivacyModal />}
      {!!modal.confirmCopyChange && <CopyChangeModal />}
      {!!modal.confirm && (
        <Modal
          header={modal.confirm.header}
          confirmation={modal.confirm}
          isOpen
          onClose={() => setModal({})}
        >
          <div style={{ display: "flex", margin: "24px 0 16px" }}>
            <Body1>{modal.confirm.body}</Body1>
          </div>
        </Modal>
      )}
      {!!modal.rules && <RulesModal />}
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
