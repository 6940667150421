import styled from "styled-components";
import { EditorProvider } from "./EditorContext";
import {
  CheckInVote,
  PostRegistration,
  Socials,
  Location,
  Appearance,
  Merchandise,
  Languages,
  PostShowEmail,
  AdvancedControls,
} from "./Slices";
import { useUser } from "auth";
export const Editor = () => {
  const user = useUser();
  return (
    <EditorProvider>
      <Container>
        <Location />
        <Appearance />
        <Socials />
        <CheckInVote />
        <PostRegistration />
        <Merchandise />
        <Languages />
        <PostShowEmail />
        {user.claims?.isAdmin && <AdvancedControls />}
      </Container>
    </EditorProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(0.12deg, #383441 2.65%, #1b1b1d 95.26%);
  border-radius: 12px;
  position: relative;
  width: 100%;
  max-width: 800px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    border-radius: 8px;
  }
`;
