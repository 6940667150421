import { BuilderConfig, StreamingServiceNames } from "@max/common";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
import { validateURL } from "Utils/helpers";
import { checkWarnings } from "./builderConfigWarnings";
import { validateField } from "./builderConfigSchema";

export const baseConfig: BuilderConfig = {
  fields: {
    type: {
      content: 0,
      getValidation: (config: BuilderConfig) =>
        config.fields.type.content !== undefined ? false : "Select a template",
      isTranslateable: false,
      isDirty: false,
    },
    colors: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    image: {
      getValidation: (config: BuilderConfig) =>
        !!config.fields.image.content ? false : "Select an image",
      isTranslateable: false,
      isDirty: false,
    },
    concert: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    displayedName: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    startTime: {
      content: DateTime.now(),
      getValidation: (config: BuilderConfig) =>
        !!config.fields.startTime.content ? false : "Enter a time",
      isTranslateable: false,
      isDirty: false,
      saveTransform: (d) => Timestamp.fromDate(d.toJSDate()),
      loadTransform: (d, config: BuilderConfig) =>
        DateTime.fromJSDate(d.toDate()).setZone(
          config.fields.concert?.content?.timeZone,
        ),
    },
    checkInVote: {
      getValidation: (config: BuilderConfig) =>
        !!config.fields.checkInVote.content ? false : "Select an event type",
      isTranslateable: false,
      isDirty: false,
    },
    voteType: {
      getValidation: (config: BuilderConfig) =>
        config.fields.checkInVote.content !== "vote"
          ? false
          : !!config.fields.voteType.content
          ? false
          : "Select an event type",
      isTranslateable: false,
      isDirty: false,
    },
    preRegistrationHeadline: {
      getWarnings: (config) =>
        checkWarnings("fields.preRegistrationHeadline", config),
      getValidation: (config: BuilderConfig) =>
        !config.fields.preRegistrationHeadline.content?.en
          ? "This field cannot be left blank."
          : false,
      isTranslateable: true,
      label: "Headline",
      category: "Pre Check-in",
    },
    preRegistrationBody: {
      getWarnings: (config) =>
        checkWarnings("fields.preRegistrationBody", config),
      getValidation: () => false,
      isTranslateable: true,
      isDirty: false,
      label: "Body text (Optional)",
      category: "Pre Check-in",
    },
    preRegistrationSubtitle: {
      getWarnings: (config) =>
        checkWarnings("fields.preRegistrationSubtitle", config),
      getValidation: () => false,
      isTranslateable: true,
      isDirty: false,
      label: "Body text (Optional)",
      category: "Pre Check-in",
    },
    preRegistrationButtonLabel: {
      getWarnings: (config) =>
        checkWarnings("fields.preRegistrationButtonLabel", config),
      content: { en: "Check In", es: "Registrarse" },
      getValidation: (config: BuilderConfig) =>
        config.fields.checkInVote.content === "vote"
          ? false
          : !config.fields.preRegistrationButtonLabel.content?.en
          ? "This field cannot be left blank."
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Button label",
      category: "Pre Check-in",
    },
    voteOptions: {
      content: [],
      getValidation: (config: BuilderConfig) =>
        config.fields.checkInVote.content !== "vote" ||
        config.fields.voteType.content !== "vote"
          ? false
          : config.fields.voteOptions?.content?.length &&
            config.fields.voteOptions?.content?.length > 1 &&
            !config.fields.voteOptions?.content.filter((opt) => !opt.en)?.length
          ? false
          : "Add vote option",
      isTranslateable: true,
      isDirty: false,
      label: "Vote Options",
      category: "Pre Vote",
    },
    songOptions: {
      content: [],
      getValidation: (config: BuilderConfig) =>
        config.fields.checkInVote.content !== "vote" ||
        config.fields.voteType.content !== "song"
          ? false
          : config.fields.songOptions?.content?.length &&
            config.fields.songOptions?.content?.length > 1 &&
            !config.fields.songOptions?.content.filter((opt) => !opt.name)
              ?.length
          ? false
          : "Add song option",
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakes: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakes.content !== undefined
          ? false
          : "Select a sweepstakes type",
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakesPrize: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakes.content === true &&
        config.fields.sweepstakesPrize.content !== "customPrize"
          ? !!config.fields.sweepstakesPrize.content
            ? false
            : "Please select a sweepstakes prize."
          : false,
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakesPrizeTerms: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakes.content === true
          ? !!config.fields.sweepstakesPrizeTerms.content
            ? false
            : "Please agree to the terms."
          : false,
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakesPrizeCustom: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakes.content === true &&
        config.fields.sweepstakesPrize.content === "customPrize"
          ? !!config.fields.sweepstakesPrizeCustom.content?.en
            ? false
            : "Please enter a sweepstakes prize."
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "What will the prize be?",
      category: "Prize Description",
    },
    postRegistration: {
      getValidation: (config: BuilderConfig) =>
        !!config.fields.postRegistration.content
          ? false
          : "Please select a post registration type.",
      isTranslateable: false,
      isDirty: false,
    },
    postRegistrationAlbum: {
      getValidation: (config: BuilderConfig) =>
        config.fields.postRegistration.content === "promo"
          ? config.fields.postRegistrationAlbum.content?.image &&
            StreamingServiceNames.findIndex(
              (s) =>
                config.fields.postRegistrationAlbum.content?.[s.path] &&
                validateURL(
                  config.fields.postRegistrationAlbum.content?.[s.path],
                ) === false,
            ) === -1
            ? false
            : "Enter an album"
          : false,
      isTranslateable: false,
      isDirty: false,
    },
    postRegistrationHeadline: {
      getWarnings: (config) =>
        checkWarnings("fields.postRegistrationHeadline", config),
      getValidation: (config: BuilderConfig) =>
        !config.fields.postRegistrationHeadline.content?.en
          ? "This field cannot be left blank."
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Headline",
      category: "Post Check-in",
    },
    postRegistrationBody: {
      getWarnings: (config) =>
        checkWarnings("fields.postRegistrationBody", config),
      getValidation: () => false,
      isTranslateable: true,
      isDirty: false,
      label: "Body Text (Optional)",
      category: "Post Check-in",
    },
    postRegistrationButtonLabel: {
      content: { en: "Save" },
      getWarnings: (config) =>
        checkWarnings("fields.postRegistrationButtonLabel", config),
      getValidation: (config: BuilderConfig) =>
        config.fields.postRegistration.content === "promo"
          ? !config.fields.postRegistrationButtonLabel.content?.en
            ? "This field cannot be left blank."
            : false
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Button Label",
      category: "Post Check-in",
    },
    postRegistrationCustomAlbum: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    postRegistrationLinkLabel: {
      getWarnings: (config) =>
        checkWarnings("fields.postRegistrationLinkLabel", config),
      content: { en: "Click Here" },
      getValidation: (config: BuilderConfig) =>
        config.fields.postRegistration.content === "link"
          ? !config.fields.postRegistrationLinkLabel.content?.en
            ? "This field cannot be left blank."
            : false
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Button Label",
      category: "Post Check-in",
    },
    postRegistrationLinkUrl: {
      getValidation: (config: BuilderConfig) =>
        config.fields.postRegistration.content === "link"
          ? !config.fields.postRegistrationLinkUrl.content
            ? "This field cannot be left blank."
            : validateURL(config.fields.postRegistrationLinkUrl.content)
            ? false
            : "Please enter a valid URL."
          : false,
      isTranslateable: false,
      isDirty: false,
    },
    merchandise: {
      getValidation: (config: BuilderConfig) =>
        (config.fields.merchandise?.content || [])?.reduce(
          (acc, m) => acc + (m.isSelected ? 1 : 0),
          0,
        ) > 4
          ? "Only 4 products may be displayed."
          : false,
      isTranslateable: false,
      isDirty: false,
    },
    hideMerchandise: {
      content: false,
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    merchandiseUrl: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    privacyPolicyDeclined: {
      content: false,
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    privacyPolicyConfirm: {
      getValidation: (config: BuilderConfig) =>
        !!config.fields.privacyPolicyConfirm.content
          ? false
          : "Please verify your privacy policy link.",
      isTranslateable: false,
      isDirty: false,
    },
    privacyPolicies: {
      content: [],
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
      isDirty: false,
    },
    registerTitle: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: true,
      label: "Dialog Headline",
      category: "Registration Dialog",
      admin: true,
    },
    registerSubtitle: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: true,
      label: "Dialog Subtitle",
      category: "Advanced Controls",
      admin: true,
    },
    registerCtaLabel: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: true,
      label: "Dialog Button Label",
      category: "Registration Dialog",
      admin: true,
    },
    socialLinkSpotify: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkSpotify.content
          ? false
          : validateURL(config.fields.socialLinkSpotify.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkInstagram: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkInstagram.content
          ? false
          : validateURL(config.fields.socialLinkInstagram.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkTiktok: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkTiktok.content
          ? false
          : validateURL(config.fields.socialLinkTiktok.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkTwitter: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkTwitter.content
          ? false
          : validateURL(config.fields.socialLinkTwitter.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkYoutube: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkYoutube.content
          ? false
          : validateURL(config.fields.socialLinkYoutube.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkFacebook: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkFacebook.content
          ? false
          : validateURL(config.fields.socialLinkFacebook.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkShazam: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkShazam.content
          ? false
          : validateURL(config.fields.socialLinkShazam.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkPandora: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkPandora.content
          ? false
          : validateURL(config.fields.socialLinkPandora.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    socialLinkTidal: {
      getValidation: (config: BuilderConfig) =>
        !config.fields.socialLinkTidal.content
          ? false
          : validateURL(config.fields.socialLinkTidal.content)
          ? false
          : "Please enter a valid URL.",
      isTranslateable: false,
    },
    additionalLanguages: {
      content: [],
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailType: {
      content: "disabled",
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailCTAType: {
      content: "survey",
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailHeadline: {
      getValidation: (config) =>
        validateField("fields.postShowEmailHeadline", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailHeadline", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Headline",
    },
    postShowEmailSubject: {
      getValidation: (config) =>
        validateField("fields.postShowEmailSubject", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailSubject", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Subject",
    },
    postShowEmailBody: {
      getValidation: (config) =>
        validateField("fields.postShowEmailBody", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailBody", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Body (optional)",
    },
    postShowEmailCta: {
      getValidation: (config) =>
        validateField("fields.postShowEmailCta", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Button URL",
    },
    postShowEmailCtaLabel: {
      getValidation: (config) =>
        validateField("fields.postShowEmailCtaLabel", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailCtaLabel", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Button label",
    },
    postShowEmailCustomHtml: {
      getValidation: (config) =>
        validateField("fields.postShowEmailCustomHtml", config),
      isTranslateable: true,
      category: "Post Show Email",
      label: "Custom HTML",
    },
    postShowEmailMerchEnabled: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailMerchHeadline: {
      getValidation: (config) =>
        validateField("fields.postShowEmailMerchHeadline", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailMerchHeadline", config),
      isTranslateable: true,
      category: "Post Show Email Merch",
      label: "Headline",
    },
    postShowEmailMerchCtaLabel: {
      getValidation: (config) =>
        validateField("fields.postShowEmailMerchCtaLabel", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailMerchCtaLabel", config),
      isTranslateable: true,
      category: "Post Show Email Merch",
      label: "Store Button Label",
    },
    postShowEmailMerchDisableCta: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailReleaseEnabled: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: false,
    },
    postShowEmailReleaseHeadline: {
      getValidation: (config) =>
        validateField("fields.postShowEmailReleaseHeadline", config),
      getWarnings: (config) =>
        checkWarnings("fields.postShowEmailReleaseHeadline", config),
      isTranslateable: true,
      category: "Post Show Email Release",
      label: "Headline",
    },
    useCustomTermsAndPrivacyPolicy: {
      isTranslateable: false,
      isDirty: false,
    },
    customTermsAndPrivacyPolicy: {
      getValidation: (config: BuilderConfig) =>
        config.fields.useCustomTermsAndPrivacyPolicy.content === true
          ? config.fields.customTermsAndPrivacyPolicy.content?.en
            ? false
            : "Enter Custom Terms & Privacy Policy"
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Custom Terms & Privacy Policy",
      category: "Advanced Controls",
      admin: true,
    },
    separateLineTermsAndPrivacyPolicy: {
      isTranslateable: true,
      isDirty: false,
      label: "Separate Line Terms & Privacy Policy (optional)",
      category: "Advanced Controls",
      admin: true,
    },
    useCustomSMSOptIn: {
      isTranslateable: false,
      isDirty: false,
    },
    customSMSOptIn: {
      getValidation: (config: BuilderConfig) =>
        config.fields.useCustomSMSOptIn.content === true
          ? config.fields.customSMSOptIn.content?.en
            ? false
            : "Enter Custom SMS Opt In"
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Custom SMS Opt In",
      category: "Advanced Controls",
      admin: true,
    },
    makePhoneNumberFieldOptional: {
      isTranslateable: false,
      isDirty: false,
    },
    makeTextMessageOptInOptional: {
      isTranslateable: false,
      isDirty: false,
    },
    useMultiDay: {
      isTranslateable: false,
      isDirty: false,
    },
    multiDayDate: {
      isTranslateable: false,
      isDirty: false,
      saveTransform: (d) => Timestamp.fromDate(d.toJSDate()),
      loadTransform: (d, config: BuilderConfig) =>
        DateTime.fromJSDate(d.toDate()).setZone(
          config.fields.concert?.content?.timeZone,
        ),
    },
    useCustomMerchTabName: {
      isTranslateable: false,
      isDirty: false,
    },
    customMerchTabName: {
      getValidation: (config: BuilderConfig) =>
        validateField("fields.customMerchTabName", config),
      isTranslateable: true,
      isDirty: false,
      label: "Merch Tab Name",
      category: "Advanced Controls",
      admin: true,
    },
    useCustomMainTabName: {
      isTranslateable: false,
      isDirty: false,
    },
    customMainTabName: {
      getValidation: (config: BuilderConfig) =>
        validateField("fields.customMainTabName", config),
      isTranslateable: true,
      isDirty: false,
      label: "Main Tab Name",
      category: "Advanced Controls",
      admin: true,
    },
    masterCampaignId: {
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakesRulesType: {
      content: "default",
      isTranslateable: false,
      isDirty: false,
    },
    sweepstakesRulesLink: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakesRulesType.content === "link"
          ? config.fields.sweepstakesRulesLink.content?.en
            ? false
            : "Enter Sweepstakes Rules Link"
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Sweepstakes Rules Link",
      category: "Advanced Controls",
      admin: true,
    },
    sweepstakesRulesHtml: {
      getValidation: (config: BuilderConfig) =>
        config.fields.sweepstakesRulesType.content === "html"
          ? config.fields.sweepstakesRulesHtml.content?.en
            ? false
            : "Enter Sweepstakes Rules HTML"
          : false,
      isTranslateable: true,
      isDirty: false,
      label: "Sweepstakes Rules HTML",
      category: "Advanced Controls",
      admin: true,
    },
    sweepstakesRulesCustomUrl: {
      getValidation: (config: BuilderConfig) =>
        validateField("fields.sweepstakesRulesCustomUrl", config),
      isTranslateable: true,
      isDirty: false,
      label: "Sweepstakes Rules HTML",
      category: "Advanced Controls",
      admin: true,
    },
    footer: {
      getValidation: (config: BuilderConfig) => false,
      isTranslateable: true,
      isDirty: false,
      label: "Footer",
      category: "Advanced Controls",
      admin: true,
    },
  },
};
