import { UserEventStates } from "@max/common";
import { DataState } from "@max/common/src/user";
import { useBuilderContext } from "Components";
import { useDataOverrideContext } from "Routes/Builder/DataOverrideContext";
import { Button } from "melodies-source/Button";
import styled from "styled-components";

export const RegisterOverrideToggle = () => {
  const { config } = useBuilderContext();
  const checkOrVote = config?.fields?.checkInVote?.content;

  const { setData, data } = useDataOverrideContext();
  const handleClick = (regStatus: boolean) => {
    setData({
      ...data,
      status: regStatus ? UserEventStates.registered : UserEventStates.draft,
    } as DataState);
  };

  return (
    <>
      <Div>
        <Buttons
          variant="outlined"
          style={{ borderRadius: "25px 0 0 25px", padding: "0 30px" }}
          onClick={() => handleClick(false)}
          active={
            data?.status === UserEventStates.draft || (!data?.status && true)
          }
        >
          {checkOrVote === "vote" ? "Pre Vote" : "Pre Check-in"}
        </Buttons>
        <Buttons
          variant="outlined"
          style={{ borderRadius: "0 25px 25px 0", padding: "0 30px" }}
          onClick={() => handleClick(true)}
          active={data?.status === UserEventStates.registered}
        >
          {checkOrVote === "vote" ? "Post Vote" : "Post Check-in"}
        </Buttons>
      </Div>
    </>
  );
};

const Buttons = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  height: 32px;
`;

const Div = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 100%;
  left: 0;
  right: 0;
  width: 80%;
  margin: 30px auto 0 auto;
  justify-content: center;
`;
