import { Builder, Shows } from "Routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Theme } from "./melodies-source/Theme";
import { UserProvider } from "auth";
import { AuthProvider } from "Components";
import { useEffect } from "react";
import { GenerateBuilderConfig } from "Routes/GenerateConfig/GenerateBuilderConfig";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  return (
    //@ts-ignore
    <UserProvider>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <Theme>
            <Router>
              <Routes>
                {process.env.REACT_APP_ENV === "dev" && (
                  <>
                    <Route path="/config" element={<GenerateBuilderConfig />} />
                    <Route path="/" element={<Shows />} />
                  </>
                )}
                <Route path="/b/:builderId/*" element={<Builder />} />
                {process.env.REACT_APP_ENV !== "dev" && (
                  <Route path="*" element={<RedirectToAP />} />
                )}
              </Routes>
            </Router>
          </Theme>
        </DndProvider>
      </AuthProvider>
    </UserProvider>
  );
}

const RedirectToAP = () => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_ARTIST_PORTAL as string;
  }, []);
  return null;
};

export default App;
