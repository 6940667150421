import { Caption } from "melodies-source/Text";
import styled from "styled-components";

export const SectionDivider = styled.div`
  display: flex;
  flex: 0 0 1px;
  height: 1px;
  width: 100%;
  background-color: rgba(102, 102, 102, 0.4);
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Warning = styled(Caption)`
  color: #ffcf21;
  margin-top: 4px;
  font-weight: 500;
`;
