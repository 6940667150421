import { DataState } from "@max/common/src/user";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface DataOverride {
  displayEmail: boolean;
  setDisplayEmail: Dispatch<SetStateAction<boolean>>;
  data: DataState | null;
  setData: Dispatch<SetStateAction<DataState | null>>;
}

export const DataOverrideContext = createContext<DataOverride>(
  {} as DataOverride,
);

export const DataOverrideProvider = (props: any) => {
  const [data, setData] = useState<DataState | null>(null);
  const [displayEmail, setDisplayEmail] = useState(false);
  const value = { data, setData, displayEmail, setDisplayEmail };
  return <DataOverrideContext.Provider {...props} value={value} />;
};

export const useDataOverrideContext = () => useContext(DataOverrideContext);
