import { useConfigSlice } from "Components";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";

export const FooterControl = () => {
  const [fields, , setField] = useConfigSlice(["footer"]);

  return (
    <Container>
      <Textarea
        value={fields.footer.content?.en}
        onChange={(v) => setField("footer", { en: v })}
        placeholder="Add additional content to the page footer"
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 80%;
  }
`;
