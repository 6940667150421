import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { firestore } from "Components";
import {
  Timestamp,
  addDoc,
  collection,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Avatar } from "melodies-source/Avatar";
import styled from "styled-components";
import { AutocompleteBase, OptionProps } from "melodies-source/Autocomplete";
import { useState } from "react";
import { ListItem as ListItemBase } from "melodies-source/ListItem";
import { H1 } from "melodies-source/Text";
import { SvgForward } from "melodies-source/Svgs/Forward";
import { SelectMenuDiv } from "melodies-source/SelectMenu";
import { DateTime } from "luxon";

export const Shows = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const [artists, loading] = useCollectionOnce(
    query(
      collection(firestore, `artist_groups`),
      where("hasAnalysis", "==", true),
    ),
  );

  const [events, eLoading] = useCollectionOnce(
    query(
      collection(firestore, `set_fresh_builder_events`),
      orderBy("createdAt", "desc"),
      limit(5),
    ),
  );

  const builder_events = events?.docs.map((doc) => ({
    id: doc.id,
    name: doc.get("artistName"),
    artistGroupId: doc.get("artistGroupId"),
    createdAt: DateTime.fromJSDate(
      doc.get("createdAt")?.toDate(),
    ).toLocaleString(DateTime.DATETIME_SHORT),
  }));

  console.log("builder_events", builder_events);

  const start = async (artistGroupId: string) => {
    const docRef = await addDoc(
      collection(firestore, "set_fresh_builder_events"),
      {
        artistGroupId,
        createdAt: serverTimestamp(),
      },
    );
    navigate(`/b/${docRef.id}/theme`);
  };

  const artistGroups = artists?.docs
    .map((doc) => ({
      icon: (
        <Avatar
          imgUrl={doc.get("assets.icon.path")}
          style={{ width: 32, height: 32, margin: "0 12px 0 8px" }}
        />
      ),
      label: doc.get("name") as string,
      caption: doc.id,
      value: doc.id,
      data: doc.get("updatedAt") as Timestamp,
    }))
    ?.sort((a, b) => b.data?.seconds - a.data?.seconds);

  const options = artistGroups
    ?.filter(
      ({ label }) =>
        label.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1,
    )
    ?.slice(0, 50);

  return (
    <Wrapper>
      <Container>
        <H1>Recent</H1>
        <List>
          {builder_events?.map((event) => (
            <ListItem
              onClick={() => navigate(`/b/${event.id}/editor`)}
              rightIcon={<SvgForward />}
            >
              <>
                {event.name} • {event.createdAt}
              </>
            </ListItem>
          ))}
        </List>
      </Container>
      <Divider />
      <Container>
        <H1>Artist Search</H1>
        <Autocomplete
          secondary
          text={searchText}
          setText={setSearchText}
          options={options || []}
          customOption={CustomOption}
          onChange={(opt) => start(opt.value)}
          placeholder="Search by artist name..."
          isLoading={loading}
        />
      </Container>
    </Wrapper>
  );
};

export const CustomOption = ({
  label,
  avatarSrc,
  caption,
  caption2,
  isSelected,
  icon,
  ...props
}: OptionProps) => {
  return (
    <ListItem
      {...props}
      leftIcon={icon}
      isSelected={isSelected}
      caption={caption}
      caption2={caption2}
      rightIcon={<SvgForward />}
      {...(icon && { style: { paddingLeft: 0 } })}
    >
      {label}
    </ListItem>
  );
};

const Autocomplete = styled(AutocompleteBase)`
  ${SelectMenuDiv} {
    max-height: 320px;
    div {
      max-height: 320px;
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: scroll;
`;

const ListItem = styled(ListItemBase)`
  transition: ${(p) => p.theme.transitions("background-color").hover};
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 2px !important;
  width: 100%;
  svg {
    transition: ${(p) => p.theme.transitions("background-color").hover};
    transform: translateX(0);
  }
  &:hover {
    background-color: #cccccc20;
    svg {
      transform: translateX(2px);
    }
  }
  & + & {
    border-top: 1px solid rgba(102, 102, 102, 0.4);
  }
`;

const Container = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: max-content;
  max-width: 380px;
  margin-top: 35vh;
`;

const Wrapper = styled.div`
  background-color: #000000;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  place-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${H1} {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 40px;
  }
`;

const Divider = styled.div`
  display: flex;
  flex: 0 0 1px;
  height: calc(100% - 20vh);
  width: 1px;
  background-color: rgba(102, 102, 102, 0.4);
`;
