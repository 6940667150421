import { BuilderConfig, Fields } from "@max/common";

type TranslateObject = {
  [category: string]: [field: Fields, value: any][];
};

export const getTranslatableFields = (
  config: BuilderConfig,
  isAdmin?: boolean,
): TranslateObject => {
  return Object.entries(config.fields).reduce(
    (acc: TranslateObject, [field, value]) => {
      if (value.isTranslateable && value.content?.en && value.label) {
        if (shouldHideField(field, value, config, isAdmin)) {
          return acc;
        }
        acc[value.category]
          ? acc[value.category].push([field as Fields, value])
          : (acc[value.category] = [[field as Fields, value]]);
      }
      return acc;
    },
    {},
  );
};

const shouldHideField = (
  field: string,
  value: any,
  config: BuilderConfig,
  isAdmin?: boolean,
) => {
  // hide any field with admin flag if user is not admin
  if (!isAdmin && value.admin) {
    return true;
  }

  // hide custom tab name field if disabled
  if (
    !config.fields.useCustomMerchTabName.content &&
    field === "customMerchTabName"
  ) {
    return true;
  }

  // Pre Registration fields
  if (config.fields.checkInVote.content === "vote") {
    // Vote
    if (["preRegistrationBody", "preRegistrationButtonLabel"].includes(field)) {
      return true;
    }
    // hide vote options if they are songs
    if (config.fields.voteType.content === "song" && field === "voteOptions") {
      return true;
    }

    // Rename category titles based on pre reg type
    if (value.category === "Post Check-in") {
      value.category = "Post Vote";
    } else if (value.category === "Pre Check-in") value.category = "Pre Vote";
  } else {
    // Check-in
    if (
      ["preRegistrationSubtitle", "voteOptions", "songOptions"].includes(field)
    ) {
      return true;
    }
  }

  if (field.startsWith("postRegistration")) {
    // hide button label if post reg type is link
    if (
      config.fields.postRegistration.content === "link" &&
      ["postRegistrationButtonLabel"].includes(field)
    ) {
      return true;
    }
    // hide button label & link label if post reg type is thank
    if (
      config.fields.postRegistration.content === "thank" &&
      ["postRegistrationLinkLabel", "postRegistrationButtonLabel"].includes(
        field,
      )
    ) {
      return true;
    }
    // hide link label if post reg type is promo
    if (
      config.fields.postRegistration.content === "promo" &&
      ["postRegistrationLinkLabel"].includes(field)
    ) {
      return true;
    }
  }

  // sweeps rules
  if (
    (config.fields.sweepstakesRulesType.content === "link" &&
      field === "sweepstakesRulesHtml") ||
    (config.fields.sweepstakesRulesType.content === "html" &&
      field === "sweepstakesRulesLink") ||
    (config.fields.sweepstakesRulesType.content === "default" &&
      ["sweepstakesRulesHtml", "sweepstakesRulesLink"].includes(field))
  ) {
    return true;
  }

  // post show email
  if (field.startsWith("postShowEmail")) {
    const postShowEmailType = config.fields.postShowEmailType.content;
    const postShowEmailCTAType = config.fields.postShowEmailCTAType.content;

    // If email type is disabled or default, hide all email fields
    if (
      !postShowEmailType ||
      ["disabled", "default"].includes(postShowEmailType)
    ) {
      return true;
    }

    // If html type, hide all email fields except html and subject fields
    if (
      postShowEmailType === "html" &&
      !["postShowEmailCustomHtml", "postShowEmailSubject"].includes(field)
    ) {
      return true;
    }

    // hide custom html field
    if (postShowEmailType !== "html" && field === "postShowEmailCustomHtml") {
      return true;
    }

    // hide cta fields if disabled
    if (
      postShowEmailCTAType === "disabled" &&
      ["postShowEmailCtaLabel", "postShowEmailCta"].includes(field)
    ) {
      return true;
    }

    // hide cta url field if cta type is "survey"
    if (postShowEmailCTAType === "survey" && field === "postShowEmailCta") {
      return true;
    }

    // hide merch fields if disabled
    if (
      !config.fields.postShowEmailMerchEnabled.content &&
      ["postShowEmailMerchHeadline", "postShowEmailMerchCtaLabel"].includes(
        field,
      )
    ) {
      return true;
    }

    // hide merch store button label fields if disabled
    if (
      config.fields.postShowEmailMerchDisableCta.content &&
      field === "postShowEmailMerchCtaLabel"
    ) {
      return true;
    }

    // hide release fields if disabled
    if (
      !config.fields.postShowEmailReleaseEnabled.content &&
      field === "postShowEmailReleaseHeadline"
    ) {
      return true;
    }
  }
  return false;
};
