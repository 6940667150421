import { Button } from "melodies-source/Button";
import { Checkbox } from "melodies-source/Selectable";
import { SvgRight } from "melodies-source/Svgs/Right";
import { H2, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const Assets = () => {
  const [page, setPage] = useState("");
  const [checked, setChecked] = useState(false);
  const nav = useNavigate();

  return (
    <Container>
      <H2>To get started, we’ll need your artist site:</H2>
      <TextInput
        value={page}
        onChange={setPage}
        secondary
        label="Your Artist page"
        placeholder="Paste your URL here"
      />
      <H2 style={{ marginTop: 15 }}>Do you have a separate merch store?</H2>
      <TextInput
        value={page}
        onChange={setPage}
        secondary
        label="Your Merch Store (optional)"
        placeholder="Paste your URL here"
      />
      <Checkbox
        // id="termsConfirm"
        style={{ margin: "15px 0 20px" }}
        value={checked}
        onChange={() => setChecked(!checked)}
        label="Don’t have a site or merch store? We can set you up with one of our own templates."
      />
      <Button
        style={{ width: 180, marginBottom: 10 }}
        rightIcon={<SvgRight />}
        disabled={!checked}
        onClick={() => nav(`../theme`)}
        nudgeRight
      >
        Next
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #ffffff;
  max-width: 400px;
`;
