import styled from "styled-components";
import { useBuilderContext, firebaseApp } from "Components";
import { ReactElement, useState } from "react";
import { Button, ButtonType } from "melodies-source/Button";
import { Body1 } from "melodies-source/Text";
import { SvgRefreshLarge } from "melodies-source/Svgs/RefreshLarge";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Modal as ModalBase,
  ModalContainer,
  ModalWrapper,
} from "melodies-source/Modal";
import { ImageUpload } from "melodies-source/ImageUpload";
import { ReactComponent as LoaderDots } from "Images/Icons/LoaderDots.svg";

type UploadButtonProps = {
  buttonText?: string;
  variant?: ButtonType;
  icon?: ReactElement;
  text?: boolean;
};

export const ReplaceImage = ({
  buttonText,
  variant = "primary",
  text = false,
  icon,
}: UploadButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [analysisRunning, setAnalysisRunning] = useState(false);
  const functions = getFunctions(firebaseApp);
  const analysisAPI = httpsCallable(
    functions,
    "setlivev3-analysis-analyzeImage"
  );
  const { config, builderId } = useBuilderContext();

  const analysis = (customUrl: string) => {
    setAnalysisRunning(true);
    analysisAPI({
      builderId,
      url: customUrl,
    }).finally(() => {
      setIsOpen(false);
      setAnalysisRunning(false);
    });
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        variant={variant}
        text={text}
        style={{
          width: "fit-content",
          padding: "0 30px 0 20px",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
        leftIcon={icon || <SvgRefreshLarge />}
      >
        {buttonText || "Upload"}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header="Upload an image"
        style={{ maxWidth: 472 }}
      >
        <ImageUpload
          aspectRatio={1}
          baseUrl={`/artist_groups/${config.artistGroupId}/`}
          onComplete={analysis}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
        {analysisRunning && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "44px 0",
            }}
          >
            <Body1>Analysis Running</Body1>
            <LoaderDots />
          </div>
        )}
      </Modal>
    </div>
  );
};

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    display: block;
    overflow-y: scroll;
    margin-top: initial;
    width: 100%;
    height: 100%;
  }
  ${ModalWrapper} {
    display: flex;
    flex-direction: column;
    overflow: initial;
    max-height: initial;
    margin: calc(6vh + 68px) auto 6vh;
    flex-shrink: 0;
    padding: 32px 40px 24px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 20px 20px 24px;
    }
  }
`;
