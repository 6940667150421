import {
  collection,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getFirestore,
  serverTimestamp,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  createContext,
  Dispatch,
  ProviderProps,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";

import {
  BuilderConfig as BuilderConfigBase,
  BuilderFields,
  Concert,
  Fields,
  TranslatableContent,
} from "@max/common/creator";
import { getFunctions, httpsCallable } from "firebase/functions";
import { cloneDeep } from "lodash";
import { setTranslatableFields } from "Utils/helpers";
import { firebaseApp, firestore } from "./Firebase";
import { LoadingScreen } from "./LoadingHelpers/LoadingScreen";
import { UserActionDoc } from "@max/common/actions";
import { useUser } from "auth";
import { baseConfig } from "./builderBaseConfig";

type BuilderConfig = BuilderConfigBase<DateTime>;

type SaveType = (v?: string) => void;

interface BuilderI {
  config: BuilderConfig;
  setConfig: Dispatch<SetStateAction<BuilderConfig>>;
  save: SaveType;
  builderId: string;
  baseData: {
    artistGroupId: string;
    artistName: string;
    draftId?: string;
    maxArtistId?: string;
    publishedId?: string;
    spotifyArtistId?: string;
  };
  publish: (
    v?: string[],
    cb?: () => any,
    status?: "unpublished" | "deleted" | "published",
    manual?: Concert[],
  ) => void;
  publishStatus: "ready" | "publishing" | "complete" | "error";
  deleteEvent: () => Promise<any>;
}

const BuilderContext = createContext<BuilderI>({} as BuilderI);

// NOTE: This was ripped from set-common because of an issue related to
// Firestore.  The error appears to indicate that there are two versions of
// the Firebase SDK installed, but I was not able to find proof of that.
// So for now, we copy-paste...
const logUserAction = async (
  {
    action,
    artistGroupId,
    metadata,
    uid,
  }: Omit<UserActionDoc, "createdAt"> & { artistGroupId: string },
  firestore?: Firestore,
): Promise<{
  success: boolean;
}> => {
  try {
    await addDoc(
      collection(
        firestore ?? getFirestore(),
        `artist_groups/${artistGroupId}/user_actions`,
      ),
      {
        createdAt: serverTimestamp(),
        action,
        uid,
        metadata: metadata || deleteField(),
      },
    );
    return { success: true };
  } catch (err) {
    console.error(
      `There was a problem logging a user action: ${(err as Error).message}`,
    );
    return { success: false };
  }
};

const load = (
  serverConfig: any,
  localConfig: BuilderConfig,
  baseConfig: BuilderConfig,
): BuilderConfig => {
  return {
    ...localConfig,
    ...serverConfig,
    fields: {
      ...baseConfig.fields,
      ...Object.entries(serverConfig?.fields || {}).reduce(
        //@ts-ignore
        (acc, [key, val]) => {
          //@ts-ignore
          if (localConfig?.fields[key]?.isDirty) {
            //@ts-ignore
            return { ...acc, [key]: localConfig?.fields[key] };
          } else {
            //@ts-ignore
            const content = localConfig?.fields[key]?.loadTransform
              ? //@ts-ignore
                localConfig?.fields[key]?.loadTransform(
                  //@ts-ignore
                  val?.content,
                  serverConfig,
                )
              : //@ts-ignore
                val?.content;
            return {
              ...acc,
              //@ts-ignore
              [key]: { ...localConfig?.fields[key], ...val, content },
            };
          }
        },
        {},
      ),
    },
  };
};
const save = (localConfig: BuilderConfig) => {
  return {
    fields: Object.entries(localConfig.fields).reduce((acc, [key, val]) => {
      //@ts-ignore
      if (val.isDirty) {
        const v = {
          //@ts-ignore
          content: val?.saveTransform
            ? //@ts-ignore
              val.saveTransform(val.content)
            : val.content,
        };
        return { ...acc, [key]: v };
      } else {
        return acc;
      }
    }, {}),
  };
};

const isClean = (config: BuilderConfig): boolean => {
  if (!config?.fields) {
    return true;
  }
  //@ts-ignore
  return !Object.entries(config.fields).find(([key, val]) => val.isDirty);
};

export const BuilderProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<BuilderI>, "value">,
) => {
  const [baseC] = useState(cloneDeep(baseConfig));
  const { builderId } = useParams() as { builderId: string };
  //@ts-ignore
  const [config, setConfig] = useState<BuilderConfig>(baseC);
  const [force, setForce] = useState(false);
  const [publishing, setPublishing] = useState<
    "ready" | "awaiting" | "publishing" | "complete" | "error"
  >("ready");
  const [additional, setAdditional] = useState<string[] | null>(null);
  const [manual, setManual] = useState<Concert[] | null>(null);
  const [publishCallback, setPublishCallback] = useState<
    (() => any) | undefined
  >();
  const [publishStatus, setPublishStatus] = useState<string | undefined>();
  const functions = getFunctions(firebaseApp);
  const publishAPI = httpsCallable(functions, "setlivev3-event-publish");
  const [baseRef] = useState(
    doc(firestore, "set_fresh_builder_events", builderId),
  );
  const [baseData, l, e] = useDocumentData(baseRef);
  const [ref, setRef] = useState<DocumentReference<DocumentData>>();
  const [data] = useDocument(ref);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (e) {
      navigate("/");
    }
    if (!baseData) return;
    setRef(
      doc(
        firestore,
        "set_fresh_builder_events",
        builderId,
        "versions",
        baseData?.draftId,
      ),
    );
  }, [baseData?.draftId, e]);

  useEffect(() => {
    if (data) {
      setConfig((c) => load(data.data(), c, baseC));
      setLoaded(true);
    }
  }, [builderId, data]);

  useEffect(() => {
    let cancel = false;
    let publishB = publishing === "awaiting";
    /*
    if (isClean(config)) {
      setForce(false);
      if (publishB && publishing === "awaiting") {
        publishInternal();
      }
      return;
    }
    */
    const t = setTimeout(
      () => {
        if (!cancel) {
          if (isClean(config)) {
            if (force && publishB && publishing === "awaiting") {
              publishInternal();
            }
            setForce(false);
            return;
          }
          const update = save(config);
          setConfig((c) => {
            let nconfig = { ...c };
            Object.entries(update.fields).map(([key, val]) => {
              //@ts-ignore
              nconfig.fields[key].isDirty = false;
            });
            return nconfig;
          });
          setDoc(
            //@ts-ignore
            ref,
            {
              ...update,
            },
            { merge: true },
          ).then((saved) => {
            if (publishB && publishing === "awaiting") {
              publishInternal();
            }
          });
          setForce(false);
        }
      },
      force ? 0 : 5000,
    );
    return () => {
      cancel = true;
      clearTimeout(t);
    };
  }, [config, force]);

  useEffect(() => {
    if (publishing === "complete") {
      setPublishing("ready");
    }
  }, [publishing]);

  if (!loaded) {
    return <LoadingScreen />;
  }

  const handleSave = (v: string | undefined) => {
    if (v === "publish") {
      setTranslatableFields(config, setConfig);
    }
    setForce(true);
  };

  const handlePublish = (
    additional: string[],
    callback?: () => any,
    status?: string,
    manual?: Concert[],
  ) => {
    if (publishing !== "ready") {
      return;
    }
    setForce(true);
    setPublishing("awaiting");
    setPublishCallback(() => callback);
    setPublishStatus(status);
    setAdditional(additional);
    setManual(manual || null);
  };

  const publishInternal = () => {
    if (publishing !== "awaiting") {
      return;
    }
    setPublishing("publishing");
    let opts = {
      id: builderId,
      ...(!!additional?.length && { additional }),
      ...((!!additional?.length || manual) && { action: "duplicate" }),
    };
    if (publishStatus) {
      //@ts-ignore
      opts.status = publishStatus;
    }
    if (manual) {
      //@ts-ignore
      opts.manualConcerts = manual;
    }
    publishAPI(opts)
      .then(() => {
        logUserAction(
          {
            action: "setlive_publish_event",
            artistGroupId: baseData?.artistGroupId,
            metadata: { event: opts.id },
            uid: user?.uid || "",
          },
          getFirestore(),
        );
        setPublishing("complete");
        if (publishCallback) {
          publishCallback();
        }
      })
      .catch(() => setPublishing("error"))
      .finally(() => {
        setAdditional(null);
        setPublishCallback(undefined);
        setPublishStatus(undefined);
        setManual(null);
        setTimeout(() => {
          setPublishing("ready");
        }, 0);
      });
  };

  const deleteEvent = async () => {
    const response = await publishAPI({
      id: builderId,
      status: "deleted",
    });
    await logUserAction(
      {
        action: "setlive_delete_event",
        artistGroupId: baseData?.artistGroupId,
        metadata: { event: builderId },
        uid: user?.uid || "",
      },
      firestore,
    );
    return response;
  };

  const value = {
    config: config,
    baseData,
    setConfig,
    save: handleSave,
    builderId,
    publish: handlePublish,
    publishStatus: publishing,
    deleteEvent,
  } as BuilderI;

  return <BuilderContext.Provider {...props} value={value} />;
};

export const useBuilderContext = () => useContext(BuilderContext);

export type SetFieldAction = (
  fieldName: Fields,
  content: Partial<BuilderConfig["fields"][typeof fieldName]["content"]>,
) => void;

export type ValidationMessages = { [K in Fields]?: string | false };

export const getBuilderFields = (config: BuilderConfig, fields: any) => {
  return Object.entries(config.fields)
    .filter(([field]) => fields.includes(field as Fields))
    .reduce((prev, curr) => {
      return { ...prev, [curr[0]]: curr[1] };
    }, {}) as BuilderFields<DateTime>;
};

export const validate = (
  config: BuilderConfig,
  fields: BuilderFields<DateTime>,
): [ValidationMessages, boolean, ValidationMessages] => {
  let warnings: ValidationMessages = {};
  const validation: ValidationMessages = Object.entries(fields).reduce(
    (p, [key, val]) => {
      if (val.getWarnings) {
        const warning = val.getWarnings(config);
        if (warning) warnings = { ...warnings, [key]: warning };
      }
      if (!val.getValidation) {
        return p;
      }
      const validationRes: string | false = val.getValidation(config);
      return { ...p, [key]: validationRes };
    },
    {},
  );
  const isValid = !Object.values(validation).find((f) => !!f);
  return [validation, isValid, warnings];
};

interface ConfigSlice {
  (fields: Fields[]): [
    Pick<BuilderFields<DateTime>, Fields<DateTime>>,
    ValidationMessages,
    SetFieldAction,
    boolean,
    SaveType,
    ValidationMessages,
  ];
}

export const useConfigSlice: ConfigSlice = (fields) => {
  const { config, setConfig, save } = useBuilderContext();
  const filtered = getBuilderFields(config, fields);
  const [validation, isValid, warnings] = validate(config, filtered);
  const setField = (fieldName: Fields, content: any) => {
    let nconfig = { ...config };
    if (
      typeof content === "object" &&
      !Array.isArray(content) &&
      "en" in content
    ) {
      nconfig.fields[fieldName].content = {
        ...((nconfig.fields[fieldName].content as TranslatableContent) || {}),
        ...content,
      };
    } else {
      nconfig.fields[fieldName].content = content;
    }
    //@ts-ignore
    nconfig.fields[fieldName].isDirty = true;
    setConfig(nconfig);
  };
  return [filtered, validation, setField, isValid, save, warnings];
};
