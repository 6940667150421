import {
  Preview,
  firebaseApp,
  useBuilderContext,
  useConcerts,
} from "Components";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Editor } from "../Editor";
import { Assets } from "./Assets";
import { CheckInVote } from "./CheckInVote";
import { Location } from "./Location";
import { PostRegistration } from "./PostRegistration";
import { Privacy } from "./Privacy";
import { Publish } from "./Publish";
import { Sweepstakes } from "./Sweepstakes";
import { Theme } from "./Theme";
import bgMain from "Images/bg-main.jpg";
import { ColorPalette } from "./ColorPalette";
import { BtnProps, Button as ButtonBase } from "melodies-source/Button";
import { SvgBack } from "melodies-source/Svgs/Back";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { PostPublish } from "./PostPublish";
import { PublishLink } from "./PublishLink";
import { RegisterOverrideToggle } from "../Editor/Components";
import { Spinner } from "melodies-source/Spinner";
import { SvgErrorAlt } from "melodies-source/Svgs/ErrorAlt";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { Concert } from "@max/common";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  Timestamp,
  collection,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { Modal } from "melodies-source/Modal";
import { Body1, H3, H4 } from "melodies-source/Text";
import { DateTime } from "luxon";
import { ReplaceImage } from "Components/ReplaceImage";

export const Wizard = () => {
  const { pathname } = useLocation();
  const isEditor = pathname?.includes("editor");

  return (
    <OuterContainer>
      <TopContainer editor={isEditor}>
        <Controls isEditor={isEditor} />
      </TopContainer>
      <Container>
        <Left
          style={isEditor ? { flex: "0 1 800px", width: "100%" } : undefined}
        >
          <Routes>
            <Route path="/assets" element={<Assets />} />
            <Route path="/theme" element={<Theme />} />
            <Route path="/location" element={<Location />} />
            <Route path="/checkinvote" element={<CheckInVote />} />
            <Route path="/postregistration" element={<PostRegistration />} />
            <Route path="/sweepstakes" element={<Sweepstakes />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/publish" element={<Publish />} />
            <Route path="/postpublish" element={<PostPublish />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/livelink" element={<PublishLink />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
        </Left>
        <Right>
          <Preview override={{ disableDefaultCopy: isEditor }} />
          <Routes>
            <Route
              path="/theme"
              element={
                <>
                  <ReplaceImage buttonText="Replace Header Image" text={true} />
                  <ColorPalette />
                </>
              }
            />
            <Route path="/editor" element={<RegisterOverrideToggle />} />
          </Routes>
        </Right>
      </Container>
      <BackgroundImageContainer>
        <BackgroundGradient />
        <BackgroundImage src={bgMain} />
      </BackgroundImageContainer>
    </OuterContainer>
  );
};

const Controls = ({ isEditor }: { isEditor: boolean }) => {
  const {
    builderId,
    publishStatus,
    deleteEvent,
    config: { artistGroupId },
    baseData,
  } = useBuilderContext();
  const [concertData, concertLoading, b, concerts] = useConcerts();
  const nav = useNavigate();

  const [fromRef, setFromRef] = useState<string>("");
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search || "");
    if (params.get("ref") || (!params.get("ref") && !!fromRef))
      setFromRef(params.get("ref") || "");
  }, [search]);

  const handleDelete = () => {
    deleteEvent().then(returnToAP);
  };

  const returnToAP = () => {
    const url = new URL(
      process.env.REACT_APP_ARTIST_PORTAL_SHOWS?.replace(
        "{id}",
        artistGroupId || "",
      ) || "",
    );
    if (publishStatus === "complete") {
      url.search = `b=${builderId}`;
    }
    window.location.href = url.toString();
  };

  return (
    <>
      <Routes>
        <Route
          path="/publish"
          element={
            <Button
              text
              leftIcon={<SvgDelete />}
              style={{
                width: "fit-content",
                color: "#000",
              }}
              onClick={handleDelete}
            >
              Discard Draft
            </Button>
          }
        />
      </Routes>
      <Button
        text
        leftIcon={isEditor ? <SvgBack /> : <SvgCloseLarge />}
        style={{
          width: "fit-content",
          color: isEditor ? "#ffffff" : "#000000",
        }}
        onClick={returnToAP}
      >
        {!isEditor ? "Close" : fromRef ? "Back" : "Exit"}
      </Button>
      {isEditor && (
        <RightControls>
          {baseData.publishedId && (
            <PublishButton
              status="unpublished"
              variant="outlined"
              label="Unpublish"
              leftIcon={<SvgDelete />}
              style={{
                marginRight: "10px",
                color: "#000",
                borderColor: "#000",
              }}
              callback={returnToAP}
            />
          )}
          <PublishButton
            callback={
              concerts?.length ? () => nav("./postpublish") : () => returnToAP()
            }
            label={baseData.publishedId ? "Update" : "Publish"}
            status="published"
          />
        </RightControls>
      )}
    </>
  );
};

const RightControls = styled.div`
  display: flex;
`;

export const PublishButton = ({
  additional,
  label = "",
  status,
  callback,
  variant,
  manual,
  ...props
}: Partial<BtnProps> & {
  additional?: string[];
  label?: string;
  status?: "unpublished" | "deleted" | "published";
  manual?: Concert[];
  callback?: () => any;
}) => {
  const { publish, publishStatus, config, builderId } = useBuilderContext();
  const [matched, setMatched] = useState<Concert[]>([]);
  const db = getFirestore(firebaseApp);
  const [existingConcerts] = useCollectionData(
    query(
      collection(
        db,
        "artist_groups",
        config.artistGroupId || "fail",
        "concerts",
      ),
      where("deletedAt", "==", null),
    ),
  );

  const handlePublish = () => {
    const cb = () => {
      setMatched([]);
      if (callback) {
        callback();
      }
    };
    publish(additional, cb, status, manual);
  };

  const handleClick = () => {
    let matched: Concert[] = [];
    (existingConcerts as Concert[])?.map((e) => {
      if (config?.fields?.concert?.content?.id === e?.id) {
        if (e.setliveEventId && e.setliveEventId !== builderId) {
          matched.push(e);
        }
        return;
      }
      if (additional?.includes(e?.id)) {
        if (e.setliveEventId) {
          matched.push(e);
        }
      }
    });
    if (matched?.length) {
      setMatched(matched);
    } else {
      handlePublish();
    }
  };

  let rightIcon = props.rightIcon;
  let buttonText = label || "Publish";
  let buttonStyles = { width: "fit-content", color: "#fff" };
  switch (publishStatus) {
    case "complete":
      //@ts-ignore
      buttonStyles.background = "green";
      buttonText = label || "Published";
      break;
    case "publishing":
      //@ts-ignore
      buttonText = <Spinner />;
      rightIcon = undefined;
      break;
    case "error":
      //@ts-ignore
      buttonStyles.background = "red";
      buttonText = "Failed";
      rightIcon = <SvgErrorAlt />;
      break;
    default:
      break;
  }
  return (
    <>
      <Button
        variant={
          variant ||
          (publishStatus === "publishing" ? "branded" : "secondaryPrimary")
        }
        style={buttonStyles}
        {...props}
        onClick={handleClick}
        rightIcon={rightIcon}
        disabled={props?.disabled || publishStatus !== "ready"}
      >
        {buttonText}
      </Button>
      {!!matched.length && (
        <Modal
          confirmation={{
            label: "Publish",
            cancelLabel: "Cancel",
            onConfirm: () => {
              handlePublish();
              setMatched([]);
            },
          }}
          isOpen={true}
          onClose={() => setMatched([])}
        >
          <H3>
            {matched.length > 1
              ? `These events will be replaced`
              : `This event will be replaced`}
          </H3>
          {matched.map((m) => (
            <div style={{ margin: "10px 0 0 0 " }}>
              <H4>{m.venue}</H4>
              <Body1>{m.address}</Body1>
              <Body1>
                {DateTime.fromJSDate((m.date as Timestamp).toDate()).toFormat(
                  "h:mm a ZZZZ  •  cccc, LLL dd",
                )}
              </Body1>
            </div>
          ))}
        </Modal>
      )}
    </>
  );
};

const Button = styled(ButtonBase)`
  z-index: 1;
`;

const OuterContainer = styled.div`
  position: relative;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const BackgroundImage = styled.img`
  position: relative;
  height: 100%;
  max-height: 900px;
  width: auto;
  z-index: 1;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 150%;
    object-fit: cover;
    object-position: 30%;
  }
`;

const BackgroundGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 1) 100%
  );
`;

const Container = styled.div`
  background-color: #000000;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-grow: 1;
  padding: 24px 32px 40px 32px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 24px 20px 20px 20px;
  }
`;

const TopContainer = styled.div<{ editor: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.editor ? "space-between" : "flex-end")};
  padding: 0 20px;
  margin: 10px 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 20px 0 0;
    padding-left: 8px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 44px 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0;
  }
`;

const Right = styled.div`
  display: none;
  height: 857px;
  aspect-ratio: 425/857;
  position: sticky;
  top: 84px;
  z-index: 1;
  margin: 0 24px;
  ${(props) => props.theme.mediaQueries.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};
