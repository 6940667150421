import { firestore } from "Components";
import { Timestamp, collection, query, where } from "firebase/firestore";
import styled from "styled-components";
import { useState } from "react";
import { H2 } from "melodies-source/Text";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { Avatar } from "melodies-source/Avatar";
import { AutocompleteBase } from "melodies-source/Autocomplete";
import { CustomOption } from "Routes/Shows";
import { SelectMenuDiv } from "melodies-source/SelectMenu";
import { DateTime } from "luxon";
import { useConfigGen } from "Components/hooks/useConfigGen";
import { Spinner } from "melodies-source/Spinner";

export const GenerateBuilderConfig = () => {
  const [searchText, setSearchText] = useState("");

  const { generateConfig, loading } = useConfigGen();

  const [bEvents] = useCollectionOnce(
    query(collection(firestore, "set_fresh_builder_events"))
  );
  const [sEvents] = useCollectionOnce(
    query(
      collection(firestore, "set_fresh_events"),
      where("deletedAt", "==", null)
    )
  );

  const builderIds = bEvents?.docs?.map((d) => d.id);
  const nonBuilderEvents = sEvents?.docs
    .filter((d) => !builderIds?.includes(d.id))
    ?.map((d) => {
      const startsAt = d.get("startsAt") as Timestamp;
      const time = DateTime.fromJSDate(startsAt.toDate());
      return {
        icon: (
          <Avatar
            imgUrl={d.get("image")}
            style={{ width: 32, height: 32, margin: "0 12px 0 8px" }}
          />
        ),
        label: d.get("artistName"),
        caption: time.toFormat("cccc, LLL dd • h:mm a (ZZZZ)"),
        caption2: d.get("venue"),
        value: d.id,
      };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const options = nonBuilderEvents
    ?.filter(
      ({ label, caption2, value }) =>
        label.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1 ||
        caption2.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1 ||
        value.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1
    )
    ?.slice(0, 50);

  return (
    <Wrapper>
      <Container>
        <H2>Generate Builder Config</H2>
        {loading ? (
          <Spinner />
        ) : (
          <Autocomplete
            secondary
            text={searchText}
            setText={setSearchText}
            options={options || []}
            customOption={CustomOption}
            onChange={(opt) =>
              generateConfig(opt.value, { redirectToEvent: true })
            }
            placeholder="Find an event to build..."
          />
        )}
      </Container>
    </Wrapper>
  );
};
const Autocomplete = styled(AutocompleteBase)`
  ${SelectMenuDiv} {
    max-height: 320px;
    div {
      max-height: 320px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: max-content;
  max-width: 380px;
  margin-top: 35vh;
`;

const Wrapper = styled.div`
  background-color: #000000;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${H2} {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 12px;
  }
`;
