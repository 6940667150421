import { IconMerch, IconReload } from "Images/Icons";
import { Button } from "melodies-source/Button";
import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { MerchModal } from "../Components";
import { Slice } from "../Slice";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { useBuilderContext } from "Components";
import { Merchandise as MerchType } from "@max/common";
import styled from "styled-components";
import { Checkbox } from "melodies-source/Selectable";
import { H4, Label } from "melodies-source/Text";
import { Body2 } from "melodies-source/Text";
import { SectionDivider } from "../Components/common";
import { UpdateMerchStore } from "../Components/UpdateMerchStore";
import { MerchCard } from "Components/MerchCard";

export const Merchandise = () => {
  return (
    <Slice
      fields={["merchandise", "hideMerchandise"]}
      content={Internal}
      title="Merchandise"
      icon={<IconMerch />}
      lastIndex
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isValid,
  isActive,
  activeSlice,
  request,
}: any) => {
  const { builderId, config } = useBuilderContext();
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<MerchType | null>(null);
  const [showReplace, setShowReplace] = useState<"replace" | "new" | undefined>(
    undefined
  );

  const handleSave = (item: MerchType) => {
    let update;
    const existing = fields.merchandise.content?.findIndex(
      (m: MerchType) => m.id === item.id
    );
    if (existing > -1) {
      update = [...fields.merchandise.content];
      update[existing] = item;
    } else {
      update = [...(fields.merchandise.content || []), item];
    }
    setField("merchandise", update);
    setEditItem(null);
    setOpen(false);
  };

  const handleOpen = (b: boolean) => {
    if (!b) {
      setEditItem(null);
    }
    setOpen(b);
  };

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    setField(
      "merchandise",
      update(fields.merchandise.content, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, fields.merchandise.content[dragIndex] as MerchType],
        ],
      })
    );
  };

  const renderCard = (m: MerchType, idx: number) => {
    return (
      <MerchCard
        key={m.id}
        index={idx}
        id={m.id}
        moveCard={moveCard}
        text={
          <Item>
            <Checkbox
              value={m.isSelected}
              onChange={() => {
                if (!m.isSelected) {
                  if (
                    //@ts-ignore
                    fields.merchandise.content.reduce((count, item) => {
                      return count + (item.isSelected ? 1 : 0);
                    }, 0) > 3
                  ) {
                    return;
                  }
                }
                const update = [...fields.merchandise.content];
                update[idx] = { ...m, isSelected: !m.isSelected };
                setField("merchandise", update);
              }}
            />
            <Image src={m.img} />
            <ItemColumn>
              <Name style={{ color: m.isSelected ? "#fff" : "#666" }}>
                {m.text}
              </Name>
              {m?.isSelected && (
                <EditButton
                  variant="outlined"
                  onClick={() => {
                    setEditItem(m);
                    setOpen(true);
                  }}
                >
                  Edit
                </EditButton>
              )}
            </ItemColumn>
          </Item>
        }
      />
    );
  };

  return (
    <SectionWrapper>
      <Label style={{ marginBottom: 6 }}>
        Displaying Items Found on this URL:
      </Label>
      <Row>
        <Row style={{ flexGrow: 1 }}>
          <MerchLink>{config.merchandiseUrl}</MerchLink>
          <SmallLink onClick={() => setShowReplace("replace")}>
            <IconReload />
            Replace Source URL
          </SmallLink>
        </Row>
        <Checkbox
          value={fields.hideMerchandise?.content}
          onChange={() =>
            setField("hideMerchandise", !fields.hideMerchandise?.content)
          }
          label={`Don't Display Merch`}
          style={{ margin: 0, flexShrink: 0 }}
        />
      </Row>
      <SectionDivider style={{ margin: "24px 0 24px 0" }} />
      <ButtonContainer>
        <div>
          <H4>Select up to four items to display.</H4>
          <Body2>
            If four items are selected, please unselect one before choosing
            another.
          </Body2>
        </div>
        <div>
          <Button
            variant="outlined"
            leftIcon={<SvgAddAlt />}
            onClick={() => setOpen(true)}
          >
            Add an item manually
          </Button>
        </div>
      </ButtonContainer>
      {fields.merchandise?.content?.length ? (
        <>
          {fields?.merchandise?.content?.map((m: MerchType, idx: number) => {
            return renderCard(m, idx);
          })}
        </>
      ) : (
        <>
          <H4 style={{ color: "#ffffff", padding: "25px 0" }}>
            We weren’t able to gather any merchandise for this artist. If you
            want to display merch, please link your merch store or add an item
            manually.
          </H4>
          {/* hidden until has functionality */}
          {/* <Button>Link a store</Button> */}
        </>
      )}
      {open && (
        <MerchModal
          open={open}
          setOpen={handleOpen}
          onComplete={handleSave}
          editItem={editItem}
        />
      )}
      {showReplace && (
        <UpdateMerchStore
          artistGroupId={config.analysisId}
          analysisId={config.analysisId}
          builderId={builderId}
          type={showReplace}
          onClose={() => setShowReplace(undefined)}
        />
      )}
    </SectionWrapper>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
  ${Label} {
    color: rgba(255, 255, 255, 0.5);
  }
  & > ${Label} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const SmallLink = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 16px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 8px;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

const MerchLink = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const Image = styled.img`
  max-width: 80px;
  max-height: 80px;
  margin: 0 10px;
  background-color: #f2f5f7;
  border-radius: 6px;
  flex-shrink: 0;
`;

const Name = styled(H4)`
  flex-grow: 1;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 16px;
  button {
    width: fit-content;
    padding: 10px 25px 10px 20px;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    flex-direction: column;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 16px;
    button {
      width: 100%;
    }
  }
`;

const EditButton = styled(Button)`
  width: 115px;
  margin-left: 24px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
    line-height: 18px;
    border: none;
    padding: 0;
    text-decoration: underline;
    margin-left: 0;
    margin-top: 8px;
    height: auto;
    width: auto;
  }
`;

const ItemColumn = styled(Row)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: space-between;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${Image} {
      width: 60px;
      height: 60px;
    }
    ${Name} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
