import {
  AutocompleteBase,
  Option,
  OptionProps,
} from "melodies-source/Autocomplete";
import { usePlacesAutocomplete } from "./hooks/usePlacesAutocomplete";

interface PlacesAutocompleteProps {
  label?: string;
  onChange: (result: google.maps.GeocoderResult, name: string) => void;
  selected?: Option;
  style?: React.CSSProperties;
  placeholder?: string;
  secondary?: boolean;
}

export const PlacesAutocomplete = ({
  label,
  onChange,
  selected,
  secondary,
  ...props
}: PlacesAutocompleteProps) => {
  const { loading, setQuery, query, results, getAddressDetails } =
    usePlacesAutocomplete() || {};

  const handleSelect = async (option: OptionProps) => {
    const places = await getAddressDetails(option);
    if (places?.place) {
      onChange(places.place, places.placeName);
    }
  };

  return (
    <AutocompleteBase
      setText={setQuery}
      text={query}
      label={label}
      secondary={secondary}
      options={results || []}
      onChange={handleSelect}
      isLoading={loading}
      value={selected}
      {...props}
    />
  );
};
