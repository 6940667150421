import { useState } from "react";
import { Modal } from "melodies-source/Modal";
import { Button } from "melodies-source/Button";
import { Checkbox } from "melodies-source/Selectable";
import { TextInput } from "melodies-source/TextInput";

const AddSongModal = ({ fields, setField, openIndex, setOpenIndex }: any) => {
  const [customSongName, setCustomSongName] = useState<string>("");
  const [customAlbum, setCustomAlbum] = useState<string>("");
  const [isSelected, setIsSelected] = useState(false);

  return (
    <Modal
      isOpen={!!openIndex}
      onClose={() => {
        setOpenIndex(0);
        setIsSelected(false);
        setCustomAlbum("");
        setCustomSongName("");
      }}
      header="Manually add a song"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 25,
          margin: "25px 0 35px",
        }}
      >
        <Checkbox
          label="Is this a cover?"
          value={isSelected}
          onChange={(v) => {
            setIsSelected(v);
          }}
        />
        <TextInput
          placeholder="Type song name"
          secondary
          label="Song Name"
          onChange={(v) => {
            setCustomSongName(v);
          }}
          value={customSongName}
        />
        <TextInput
          placeholder={isSelected ? "Type artist name" : "Type album name"}
          secondary
          label={isSelected ? "Artist Name" : "Album Name"}
          onChange={(v) => {
            setCustomAlbum(v);
          }}
          value={customAlbum}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 25,
          width: "100%",
          margin: "25px 0 0",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            width: 120,
          }}
          text
          onClick={() => {
            setOpenIndex(0);
            setCustomAlbum("");
            setCustomSongName("");
            setIsSelected(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            width: 120,
          }}
          disabled={!customSongName || !customAlbum}
          onClick={() => {
            let update = [...fields.songOptions?.content!];
            update?.splice(openIndex - 1, 1, {
              name: customSongName,
              album: customAlbum,
              isCover: isSelected ? true : false,
            });
            setField("songOptions", update);
            setOpenIndex(0);
            setCustomAlbum("");
            setCustomSongName("");
            setIsSelected(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default AddSongModal;
