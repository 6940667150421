import { Button } from "melodies-source/Button";
import styled, { css } from "styled-components";
import { Modal } from "melodies-source/Modal";
import { TextInput } from "melodies-source/TextInput";
import { useEffect, useState } from "react";
import { SpinnerRing } from "Components/LoadingHelpers/SpinnerRing";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp, useBuilderContext } from "Components";
import { Merchandise } from "@max/common";
import { Label, H4 } from "melodies-source/Text";
import { ImageUpload } from "melodies-source/ImageUpload";
import { SvgAddLarge } from "melodies-source/Svgs/AddLarge";

interface IMerchModal {
  open: boolean;
  setOpen: (b: boolean) => void;
  onComplete: (a: any) => void;
  editItem?: any;
}

export const MerchModal = ({
  open,
  setOpen,
  onComplete,
  editItem,
}: IMerchModal) => {
  const [visible, setVisible] = useState(false);
  const [customImage, setCustomImage] = useState("");
  const [item, setItem] = useState<Merchandise>(editItem || {});
  const [loading, setLoading] = useState(false);
  const [addUrl, setAddUrl] = useState(editItem?.url || "");
  const functions = getFunctions(firebaseApp);
  const merchSearch = httpsCallable(functions, "setlivev3-builder-product");
  const header = editItem ? "Edit merch" : "Add merch";
  const { config } = useBuilderContext();
  const handleSearch = () => {
    setLoading(true);
    const limitReached =
      (config?.fields?.merchandise?.content?.reduce(
        (count: number, item: Merchandise) => {
          return count + (item.isSelected ? 1 : 0);
        },
        0,
      ) || 0) > 3;
    merchSearch({ url: addUrl })
      .then(({ data }) => {
        //@ts-ignore
        if (data?.id) {
          //@ts-ignore
          setItem({
            ...data,
            isSelected: !limitReached,
            //@ts-ignore
            id: editItem?.id || data.id,
          });
          //@ts-ignore
          setAddUrl(data.url);
        } else {
          setItem({
            url: addUrl,
            isSelected: !limitReached,
            id: crypto.randomUUID(),
            img: "",
            text: "",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    const update = item;
    update.url = addUrl;
    if (!!customImage) {
      item.img = customImage;
      setItem(update);
      onComplete(update);
    } else {
      onComplete(update);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      //@ts-ignore
      style={{ minWidth: "350px", width: "40%", maxWidth: "550px" }}
      header={header}
    >
      <div>
        <TextInput
          value={addUrl}
          onChange={setAddUrl}
          label={"Item URL"}
          placeholder={"Enter the URL for this product"}
          style={{ marginTop: 30 }}
        />
        {loading && (
          <div
            style={{
              width: 300,
              height: 300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <SpinnerRing
              textArray={[
                "Analyzing merch URL...",
                "Finding merch image...",
                "Locating merch title...",
              ]}
              svg={
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.5536 29.8952L59 20.4167L41.6 7C41.1362 8.32192 40.4584 9.43016 39.6535 10.3554C36.7239 13.7231 32.1101 14.6667 30 14.6667C25.7759 14.6667 22.497 12.4783 20.4838 10.2495C19.4203 9.0721 18.7099 7.88337 18.4 7L1 20.4167L8.46267 29.885L14.5333 25.2083V53H45.4667V25.2083L51.5536 29.8952ZM50.8758 24.5195L53.5719 21.0875L42.709 12.7114C41.2381 14.4477 39.467 15.692 37.7461 16.5544C34.8786 17.9915 31.9141 18.5 30 18.5C25.6954 18.5 22.1845 16.7885 19.6756 14.7656C18.8436 14.0947 18.0885 13.3628 17.4261 12.6072L6.43291 21.0838L9.13515 24.5123L18.4 17.3749V49.1667H41.6V17.3774L50.8758 24.5195Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
        )}
        {!loading && !item.id && (
          <Label style={{ marginTop: 20 }}>
            Paste the URL for the product you want to add here, and we will try
            to gather an image and a description for you.
          </Label>
        )}
        {addUrl !== item.url && !loading && !item?.id && (
          <ButtonContainer style={{ margin: "30px 0 30px auto" }}>
            <Button
              text
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSearch}>Search</Button>
          </ButtonContainer>
        )}
        {item.id && (
          <TextInput
            value={item.text}
            onChange={(text) => setItem({ ...item, text })}
            label={"Description"}
            placeholder={"Describe your product here"}
            style={{ marginTop: 20 }}
          />
        )}
        {visible ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Label>Upload an image</Label>
              <Label
                onClick={() => {
                  setVisible(false);
                }}
                style={{ textDecoration: "underline" }}
              >
                Cancel
              </Label>
            </div>
            <ImageUpload
              autosave={true}
              onComplete={(img: any) => {
                setCustomImage(img);
                setVisible(false);
              }}
              onCancel={() => setVisible(false)}
              baseUrl={`/artist_groups/${config.artistGroupId}/`}
            />
          </div>
        ) : (
          item.id && (
            <Wrapper>
              {(customImage || item.img) && (
                <ImageContainer
                  image={customImage || item?.img}
                ></ImageContainer>
              )}

              <AddImage
                onClick={() => {
                  setVisible(true);
                }}
              >
                <AddWrap>
                  <SvgAddLarge />
                  <H4>Update image</H4>
                </AddWrap>
              </AddImage>
            </Wrapper>
          )
        )}
        {!!item?.id && (
          <ButtonContainer>
            <Button text onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={
                (!customImage && !item?.img) ||
                !item?.text ||
                !item?.url ||
                !addUrl
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </ButtonContainer>
        )}
      </div>
    </Modal>
  );
};
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  button {
    width: 120px;
  }
`;
const ImageContainer = styled.div<{ isSelected?: boolean; image?: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  aspect-ratio: 1/1;
  background: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
  border: 0.5px solid #666666;
  ${({ image, isSelected }) =>
    isSelected &&
    image &&
    css`
      background: -webkit-linear-gradient(
          rgba(255, 255, 255, 0.7),
          rgba(253, 255, 255, 0.4)
        ),
        url(${image});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
`;
const AddImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 6px;
  border: 0.5px solid #666666;
  cursor: pointer;
`;

const AddWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    color: #ffffff;
    margin-bottom: 1px;
  }
  h4 {
    width: 70%;
  }
`;
const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin: 25px 0;
`;
