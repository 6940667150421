import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Dropdown as DropdownBase } from "../Dropdown";
import { SvgCalendar } from "../Svgs/Calendar";
import { SvgLeft } from "../Svgs/Left";
import { SvgRight } from "../Svgs/Right";
import { Caption, Selected } from "../Text";
import { TextInput } from "melodies-source/TextInput";

const CalendarIcon = styled(SvgCalendar)`
  color: #ffffff;
`;

const Dropdown = styled(DropdownBase)`
  margin: 1px auto;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.14);
  padding: 22px 22px 12px;
  width: 100%;
  max-width: 345px;
  border: none;
  background-color: #383441;
`;

const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
`;

export const ActionIcon = styled.div`
  color: #ffffff;
  border-radius: 100%;
  padding: 5px;
  :hover {
    cursor: pointer;
    background: #f2f5f7;
    color: #383441;
  }
`;

const MonthDateWrapper = styled.div`
  height: 36px;
  width: 36px;
  margin: 2px;
`;

const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #6a6a6a;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0;
  margin-top: 5px;
  color: #ffffff;
  gap: 10px;

  ${Selected} {
    font-size: 14px;
    & > span {
      font-weight: 300;
    }
  }
`;
const TimeStyles = `
  height: 45px;
  padding: 10px;
  border: 1px solid #ffffff;
  background-color: transparent;
  font-size: 17px;
  color: #cccccc;
`;
const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const HourInput = styled.input`
  ${TimeStyles}
  width: 55px;
  border-radius: 6px;
  margin-right: 5px;
  // padding-left: 17px;
  text-align: center;
  &::placeholder {
    color: #999999;
    text-align: center;
    padding-left: 5px;
  }
  &:focus {
    background-color: #ffffff;
    color: #1b0076;
    outline: none;
    &::placeholder {
      color: #ffffff;
    }
  }
`;
const MinuteInput = styled.input`
  ${TimeStyles}
  max-width: 55px;
  border-radius: 6px;
  margin-left: 5px;
  //  padding-left: 17px;
  text-align: center;
  &::placeholder {
    color: #999999;
    text-align: center;
    padding-left: 5px;
  }
  &:focus {
    background-color: #ffffff;
    color: #1b0076;
    outline: none;
    &::placeholder {
      color: #ffffff;
    }
  }
`;

const LeftButton = styled.button<{ active?: boolean }>`
  ${TimeStyles}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 10px 17px;
  font-family: "Poppins";
  font-weight: 500;
  ${({ active }) =>
    active &&
    css`
      background-color: #ffffff;
      color: #1b0076;
    `}
`;

const RightButton = styled.button<{ active?: boolean }>`
  ${TimeStyles}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  padding: 10px 17px;
  font-family: "Poppins";
  font-weight: 500;
  ${({ active }) =>
    active &&
    css`
      background-color: #ffffff;
      color: #1b0076;
    `}
`;

type ValueProps = {
  isPickerOpen: boolean;
  hasError?: boolean;
};
const Value = styled.div<ValueProps>`
  display: flex;
  align-items: center;
  background: transparent;
  border-bottom: solid 1px #cccccc;
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  border-radius: 0px;
  padding: 10px 12px 0px 2px;
  width: 100%;
  ${(p) => p.isPickerOpen && css``}
  ${(p) =>
    p.hasError &&
    css`
      border: solid #e71e3d;
    `}
`;

type DayContainerProps = {
  isPast?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
};
const MonthDateContainer = styled(MonthDateWrapper)<DayContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  border-radius: 100%;
  color: #ffffff;

  ${(p) =>
    p.isPast &&
    css`
      color: #6f6f6f;
    `}
  ${(p) => p.isToday && css``};
  ${(p) =>
    p.isSelected
      ? css`
          background-color: #ffffff;
          color: #1b0076;
        `
      : css`
          :hover {
            background-color: #f2f5f7;
            color: #1b0076;
          }
        `};
`;

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const weekHeaderUI = (
  <div style={{ display: "flex", marginTop: 17 }}>
    {days.map((day, index) => (
      <div
        style={{ width: 40, height: 24, margin: "0 auto", color: "#ffffff" }}
        key={index}
      >
        <Caption>{day}</Caption>
      </div>
    ))}
  </div>
);

type Props = {
  value: DateTime;
  label?: string;
  onChange: (v: DateTime) => void;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  minDate?: DateTime;
  hasError?: boolean;
  isRangePicker?: boolean;
  style?: any;
  renderInput?: React.ReactNode;
  disabled?: boolean;
};

interface InputValueProps {
  date?: DateTime;
  hours: string;
  minutes: string;
  meridiem: string;
}

export const Datepicker = ({
  label,
  value,
  onChange,
  onClose,
  onOpen,
  hasError,
  minDate,
  renderInput,
  disabled,
  ...props
}: Props) => {
  const [refDate, setRefDate] = useState<DateTime>(value || DateTime.now());
  const [timeState, setTimeState] = useState<InputValueProps>({
    hours: "00",
    minutes: "00",
    meridiem: "AM",
  });
  const [isPickerOpen, setIsPickerOpenState] = useState(false);
  const isAM = timeState.meridiem === "AM";

  useEffect(() => {
    const [hours, minutes, meridiem] = value?.toFormat("h:mm:a")?.split(":");
    setTimeState({ hours, minutes, meridiem, date: value });
  }, []);

  const setIsPickerOpen = (v: boolean) => {
    setIsPickerOpenState(v);
    onClose && !v && onClose();
    onOpen && v && onOpen();
  };

  const monthFirstDay = refDate.startOf("month");
  const monthLastDay = refDate.endOf("month");

  const monthDates = [];
  for (let i = 0; i < monthFirstDay.weekday; i++) monthDates.push(null);
  for (let d = 0; d < refDate.daysInMonth; d++)
    monthDates.push(monthFirstDay.plus({ days: d }));

  const gotoNextMonth = () => setRefDate(monthLastDay.plus({ days: 1 }));
  const gotoPreviousMonth = () => setRefDate(monthFirstDay.minus({ days: 1 }));
  const monthHeaderUI = (
    <ActionWrap>
      <ActionIcon onClick={gotoPreviousMonth}>
        <SvgLeft />
      </ActionIcon>
      <Selected>
        {refDate.monthLong} {refDate.year}
      </Selected>
      <ActionIcon onClick={gotoNextMonth}>
        <SvgRight />
      </ActionIcon>
    </ActionWrap>
  );

  const valueUI = (
    <>
      <span onClick={() => !disabled && setIsPickerOpen(true)}>
        {renderInput || (
          <TextInput
            label={label}
            placeholder="Pick a date"
            value={value?.toFormat("ff ZZZZ")}
            onChange={() => {}}
            leftIcon={<CalendarIcon />}
            rightIcon={<SvgDropdown />}
            secondary
            style={{ pointerEvents: "none" as const }}
          />
        )}
      </span>
    </>
  );

  const handleHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueString = e.target.value;
    const value = parseInt(valueString);
    if (valueString === "" || (/^[0-9\b]+$/.test(valueString) && value <= 12)) {
      if (valueString.length === 2 && value < 1) {
        return;
      } else {
        setTimeState({
          ...timeState,
          hours: valueString,
        });
      }
      if (value > 1) {
        const nextField = document.getElementById("minutes");
        nextField && nextField.focus();
      }
    }
  };

  const handleMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueString = e.target.value;
    const value = parseInt(valueString);
    if (valueString === "" || (/^[0-9\b]+$/.test(valueString) && value <= 59)) {
      if (valueString.length === 1 && value > 5) {
        setTimeState({
          ...timeState,
          minutes: `0${valueString}`,
        });
      } else {
        setTimeState({
          ...timeState,
          minutes: valueString,
        });
      }
    }
  };

  const handleMinutesBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length === 1) {
      setTimeState({
        ...timeState,
        minutes: `0${value}`,
      });
    }
  };

  const handleDateChange = () => {
    if (!timeState.hours) {
      return;
    }
    let hours = parseInt(timeState.hours);
    let dateState = value;

    if (!isAM && hours !== 12) {
      hours = hours + 12;
    }
    if (isAM && hours === 12) {
      hours = 0;
    }

    dateState = dateState.set({
      hour: hours,
      minute: parseInt(timeState.minutes || "0"),
    });

    onChange(dateState);
  };

  useEffect(() => {
    handleDateChange();
  }, [timeState]);

  const timePickerUi = (
    <TimeWrapper>
      <Selected>
        Time <span>{value && value.toFormat("(ZZZZ)")}</span>
      </Selected>
      <TimeContainer>
        <InputContainer>
          <HourInput
            type="tel"
            maxLength={2}
            placeholder="00"
            onChange={handleHours}
            value={timeState.hours}
          />
          :
          <MinuteInput
            type="tel"
            min="0"
            max="59"
            placeholder="00"
            onChange={handleMinutes}
            onBlur={handleMinutesBlur}
            value={timeState.minutes}
            maxLength={2}
            id="minutes"
          />
        </InputContainer>
        <div>
          <LeftButton
            active={isAM}
            onClick={() =>
              setTimeState({
                ...timeState,
                meridiem: "AM",
              })
            }
          >
            AM
          </LeftButton>
          <RightButton
            active={!isAM}
            onClick={() =>
              setTimeState({
                ...timeState,
                meridiem: "PM",
              })
            }
          >
            PM
          </RightButton>
        </div>
      </TimeContainer>
      <Button
        style={{
          width: "25%",
          height: 24,
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: 8,
        }}
        variant="round"
        onClick={() => setIsPickerOpen(false)}
      >
        Done
      </Button>
    </TimeWrapper>
  );

  return (
    <div {...props}>
      {valueUI}
      <Dropdown
        placement="bottom-start"
        isOpen={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      >
        <div>
          {monthHeaderUI}
          {weekHeaderUI}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "2px",
            }}
          >
            {monthDates.map((date, index) => (
              <MonthDateWrapper key={index}>
                {date && (
                  <MonthDate
                    {...{
                      date,
                      value,
                      hour: parseInt(timeState.hours),
                      minute: parseInt(timeState.minutes),
                      isAM,
                      minDate,
                      onChange,
                      setIsPickerOpen,
                    }}
                  />
                )}
              </MonthDateWrapper>
            ))}
          </div>
        </div>
        {timePickerUi}
      </Dropdown>
    </div>
  );
};

type MonthDateProps = {
  date: DateTime;
  hour: number;
  minute: number;
  isAM: boolean;
  setIsPickerOpen: (v: boolean) => void;
} & Pick<Props, "value" | "onChange" | "minDate">;
const MonthDate = ({
  value,
  date,
  hour,
  minute,
  isAM,
  setIsPickerOpen,
  minDate,
  onChange,
}: MonthDateProps) => {
  const isSelected = date.toISODate() === value?.toISODate();
  const isToday = date.toISODate() === DateTime.local().toISODate();
  const isPast = minDate ? date < minDate.startOf("day") : false;

  const onSelect = () => {
    if (isPast) return;
    onChange(
      date.set({ hour: hour + (isAM || hour === 12 ? 0 : 12), minute: minute })
    );
  };

  return (
    <MonthDateContainer onClick={onSelect} {...{ isSelected, isToday, isPast }}>
      {date.day}
    </MonthDateContainer>
  );
};
