import { collection, getFirestore, query, where } from "firebase/firestore";
import { useBuilderContext } from "./BuilderContext";
import { firebaseApp } from "Components";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Concert } from "@max/common";
import { useState } from "react";

export const useConcerts: () => [
  Concert[] | undefined,
  boolean,
  any,
  Concert[] | undefined,
] = () => {
  const {
    config: { artistGroupId },
  } = useBuilderContext();
  const db = getFirestore(firebaseApp);
  const [now] = useState(new Date());

  const [concertsData, l, e] = useCollectionData(
    query(
      collection(db, "artist_groups", artistGroupId!, "concerts"),
      where("date", ">", now),
      where("deletedAt", "==", null),
    ),
  );

  const concerts = concertsData?.filter((c) => !c.setliveEventId) as
    | Concert[]
    | undefined;

  return [concerts, l, e, concertsData as Concert[]];
};
