import { firebaseApp } from "Components";
import { validateURL } from "Utils/helpers";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button } from "melodies-source/Button";
import { Spinner } from "melodies-source/Spinner";
import { H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import styled from "styled-components";

interface UpdateMerchStoreProps {
  artistGroupId?: string;
  analysisId?: string;
  builderId: string;
  onClose: VoidFunction;
  type?: "replace" | "new";
}

export const UpdateMerchStore = ({
  builderId,
  onClose,
  type,
}: UpdateMerchStoreProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const functions = getFunctions(firebaseApp);

  const replaceStore = httpsCallable(
    functions,
    "setlivev3-builder-replacemerch"
  );

  const handleReplace = () => {
    if (!validateURL(url)) {
      setError(true);
      return;
    }
    setLoading(true);
    if (loading) {
      return;
    }
    replaceStore({ id: builderId, url }).finally(() => {
      setLoading(false);
      onClose();
      setUrl("");
    });
  };

  return (
    <ReplaceViewWrapper>
      {loading ? (
        <>
          <H4>Please wait while we search for merchandise...</H4>
          <Spinner style={{ marginTop: 20 }} />
        </>
      ) : (
        <>
          <Wrapper>
            <H4>
              {type === "new" ? "Enter" : "Replace"} the URL to your online
              store
            </H4>

            <TextInput
              placeholder="Paste your URL here..."
              style={{ width: "100%" }}
              hasError={error}
              value={url}
              onChange={(v) => {
                setUrl(v);
                if (validateURL(url)) {
                  setError(false);
                }
              }}
              helperText={error ? "Please enter a valid URL." : undefined}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 16,
              }}
            >
              <Button
                style={{ marginRight: 20 }}
                disabled={loading}
                onClick={onClose}
                text
              >
                Cancel
              </Button>
              <Button
                disabled={loading || !url || error}
                onClick={handleReplace}
              >
                {type === "new" ? "Link" : "Replace"}
              </Button>
            </div>
          </Wrapper>
          <OuterClick onClick={onClose} />
        </>
      )}
    </ReplaceViewWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  max-width: 400px;
`;

const OuterClick = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const ReplaceViewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -24px;
  right: -24px;
  bottom: -32px;
  z-index: 101;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0.8) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  backdrop-filter: blur(5px);
  ${H4} {
    color: #ffffff;
    margin-bottom: 6px;
  }
  button {
    width: 140px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    left: -16px;
    right: -16px;
    bottom: -32px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
