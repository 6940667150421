import { Body1, Body2, Caption, H4, Label } from "melodies-source/Text";
import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, SectionDivider } from "./common";
import { Button } from "melodies-source/Button";
import { HTMLAttributes, useEffect, useState } from "react";
import { Modal, ModalHeader } from "melodies-source/Modal";
import { Checkbox } from "melodies-source/Selectable";
import { Switch } from "melodies-source/Switch";
import { DragLong, Pointer } from "Images/Icons";
import { DndContainer } from "Components/Sortable";
import { DragEndEvent, DragOverlay } from "@dnd-kit/core";
import { arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore, useBuilderContext } from "Components";
import {
  Question as QuestionBaseProps,
  Questions,
  generateCustomFields,
} from "@max/common/creator";
import { FormField } from "@max/common";
import { SectionContainer } from "../Slices";

interface Question extends QuestionBaseProps {
  internalId: string;
  disabled?: boolean;
}

export const CustomRegQuestions = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { baseData, builderId, config, setConfig } = useBuilderContext();
  const [questions, setQuestions] = useState<Question[]>();
  const [regionDocId, setRegionDocId] = useState<string>();

  const getRegionDoc = async (groupId: string) => {
    try {
      const regionRef = await getDocs(
        query(
          collection(firestore, "gtb_regions"),
          where(`programs.${groupId}`, "!=", null),
        ),
      );
      const doc = regionRef.docs.shift();
      if (!doc) {
        return Promise.reject();
      }
      return doc.id;
    } catch (error) {
      console.error(error);
    }
  };

  const findRegionDoc = async () => {
    const groupIds = [baseData.artistGroupId].concat(
      config.additionalGroups || [],
    );
    const regionIdPromises = groupIds.map(getRegionDoc);
    try {
      const regionId = await Promise.any(regionIdPromises);
      setRegionDocId(regionId);
    } catch (error) {
      console.error(
        `No region document found for any group id. Error: ${error}`,
      );
    }
  };

  useEffect(() => {
    findRegionDoc();
  }, []);

  const customFieldIds =
    config.customRegister?.en.customFields?.map(({ id }) => id) || [];

  const getCustomRegister = async (docId: string) => {
    try {
      const regionOverridesSnap = await getDoc(
        doc(firestore, "gtb_regions", docId, "/private/questions"),
      );

      if (regionOverridesSnap.exists()) {
        const customizationsDoc = await getDoc(
          doc(firestore, "brand_customizations", "gtb"),
        );
        const regionOverrides = regionOverridesSnap.data();

        const customizations = customizationsDoc?.get("questions");
        if (customizations) {
          const questions: Question[] = Object.entries(customizations)
            .map(([key, value]) => {
              const regionOverride: Partial<FormField> | undefined =
                regionOverrides[key];
              const question = value as Question;
              const localOverride =
                config.customRegister?.en.customFields?.find(
                  ({ id }) => id === question.id,
                );
              return {
                ...question,
                ...regionOverride,
                ...localOverride,
                internalId: key,
                disabled: !localOverride,
              };
            })
            .sort((a, b) => a.defaultSortOrder - b.defaultSortOrder)
            .map((q, idx) => ({ ...q, defaultSortOrder: idx }));
          setQuestions(questions);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = (q: Question[]) => {
    if (!baseData?.draftId) {
      return;
    }
    setQuestions(q);

    const updatedFormat = q.reduce((acc: Questions, curr, idx) => {
      if (!curr.disabled) {
        acc[curr.internalId] = { ...curr, defaultSortOrder: idx };
      }
      return acc;
    }, {});

    const customRegister = generateCustomFields(updatedFormat);
    setConfig((c) => ({ ...c, customRegister }));
    try {
      setDoc(
        doc(
          firestore,
          "set_fresh_builder_events",
          builderId,
          "versions",
          baseData?.draftId,
        ),
        { customRegister },
        { merge: true },
      );
    } catch (error) {
      console.error(error);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (regionDocId) {
      getCustomRegister(regionDocId);
    }
  }, [regionDocId]);

  if (!questions) {
    return null;
  }

  const enabledQuestions = questions.filter(({ id }) =>
    customFieldIds.includes(id),
  );

  return (
    <>
      <SectionDivider />
      <SectionContainer style={{ gap: 0 }}>
        <Header>
          <H4>Custom Form Questions</H4>
          <Button variant="outlined" onClick={() => setIsOpen((v) => !v)}>
            Edit Questions
          </Button>
          {isOpen && (
            <EditQuestionsModal
              onClose={() => setIsOpen((v) => !v)}
              onSave={handleSave}
              initialQuestions={questions}
            />
          )}
        </Header>
        {!enabledQuestions.length ? (
          <Body1>All custom questions are disabled.</Body1>
        ) : (
          <Container>
            {enabledQuestions.map((q, i) => (
              <Li key={`question-list-${i}`}>
                <ItemContent>
                  {q.internalLabel}
                  {!q.required && <Caption>Optional</Caption>}
                </ItemContent>
              </Li>
            ))}
          </Container>
        )}
      </SectionContainer>
    </>
  );
};

const Header = styled(FlexRow)`
  justify-content: space-between;

  & > button {
    padding: 10px 16px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: disc;
  padding-left: 24px;
`;

const ItemContent = styled(FlexRow)`
  display: flex;
  gap: 6px;
  ${Caption} {
    line-height: 22px;
    color: #ccc;
  }
`;

const Li = styled(Body1).attrs({ as: "li" })`
  margin: 4px 0;
`;

type QuestionModalProps = {
  onClose: VoidFunction;
  initialQuestions?: Question[];
  onSave: (q: Question[]) => void;
};

const EditQuestionsModal = ({
  onClose,
  initialQuestions,
  onSave,
}: QuestionModalProps) => {
  const [questions, setQuestions] = useState<Question[]>(
    initialQuestions || [],
  );

  const handleUpdate = (question: Question) => {
    setQuestions((c) => {
      let newValue = [...c];
      const thisIdx = c.findIndex((q) => q.internalId === question.internalId);
      newValue[thisIdx] = question;
      return newValue;
    });
  };

  const handleSave = () => {
    onSave(questions);
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const from = questions.findIndex(
        ({ internalId }) => internalId === active.id,
      );
      const to = questions.findIndex(
        ({ internalId }) => internalId === over?.id,
      );
      setQuestions(arrayMove(questions, from, to));
    }
  }

  return (
    <StyledModal
      header="Edit Default Questions"
      subtitle="Please select which questions you would like to appear on check-in forms for users."
      isOpen
      onClose={onClose}
    >
      <Content>
        <Description>
          These questions will appear for this event only.
        </Description>
        <Divider />
        <FlexRow style={{ alignItems: "center", marginBottom: 12, gap: 10 }}>
          <Pointer />
          <Body2>Click and drag to rearrange questions</Body2>
        </FlexRow>
        <DndContainer
          items={questions}
          onDragEnd={handleDragEnd}
          variant="column"
        >
          {({ activeId }) => (
            <>
              {questions.map(
                (q, idx) =>
                  q.internalId && (
                    <SortableItem
                      key={q.internalId}
                      id={q.internalId}
                      onUpdate={handleUpdate}
                      question={q}
                      disabled={q.disabled}
                    />
                  ),
              )}
              <DragOverlay>
                {activeId ? (
                  <SortableItem
                    id={activeId}
                    question={questions.find(
                      ({ internalId }) => internalId === activeId,
                    )}
                  />
                ) : null}
              </DragOverlay>
            </>
          )}
        </DndContainer>
        <Actions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Actions>
      </Content>
    </StyledModal>
  );
};

const Actions = styled(FlexRow)`
  gap: 20px;
  justify-content: flex-end;
  margin-top: 24px;
  button {
    min-width: 120px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 16px;
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

const StyledModal = styled(Modal)`
  ${ModalHeader} {
    gap: 20px;
  }
`;

const Description = styled(Body2)`
  margin: 20px 0 0;
`;

const Content = styled(FlexColumn)`
  ${H4} {
    margin-bottom: 8px;
  }
`;

const Handle = styled.div``;

const DragContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 16px 0;
  & > svg {
    color: #999999;
    cursor: grab;
  }

  ${(p) =>
    p.disabled &&
    css`
      ${Handle}, ${H4} {
        opacity: 0.2;
      }
    `};
`;

const Divider = styled.div`
  background-color: #999;
  width: 100%;
  height: 1px;
  margin: 24px 0 12px;
`;

interface SortableItemProps extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  id: string | number;
  question?: Question;
  onUpdate?: (v: Question) => void;
  disabled?: boolean;
}

const SortableItem = ({
  id,
  question,
  onUpdate,
  disabled,
}: SortableItemProps) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled: disabled,
  });

  const style = {
    opacity: isDragging ? 0 : 1,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  if (!question) {
    return null;
  }

  return (
    <DragContainer ref={setNodeRef} style={style} disabled={disabled}>
      <Handle {...attributes} {...listeners}>
        <DragLong />
      </Handle>
      <Checkbox
        value={!question.disabled}
        onChange={(v) => onUpdate?.({ ...question, disabled: !v })}
        style={{ margin: 0 }}
        label={
          <FlexColumn>
            <H4>{question.internalLabel}</H4>

            <FlexRow
              onClick={(e) => {
                e.stopPropagation();
                onUpdate?.({ ...question, required: !question.required });
              }}
              style={{
                alignItems: "center",
                opacity: !question.disabled ? 1 : 0.2,
                pointerEvents: !question.disabled ? "auto" : "none",
              }}
            >
              <Switch
                value={!!question?.required}
                disabled={question.disabled}
                onChange={() => {}}
              />
              <Label style={{ marginLeft: 8 }}>Required</Label>
            </FlexRow>
          </FlexColumn>
        }
      />
    </DragContainer>
  );
};
