import { useBuilderContext } from "Components";
import { H2 } from "melodies-source/Text";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import styled from "styled-components";
import { Button } from "melodies-source/Button";

export const PublishLink = () => {
  const { builderId } = useBuilderContext();

  return (
    <Container>
      <H2>Your event is now available here:</H2>
      <Button
        text
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_PUBLISHED_URL}/e/${builderId}`,
            "_blank",
            "noopener,noreferrer"
          )
        }
        style={{
          paddingLeft: 25,
          textDecoration: "underline",
          fontWeight: 400,
          fontSize: 18,
        }}
      >
        {process.env.REACT_APP_PUBLISHED_URL}/e/{builderId}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  max-width: 400px;
  text-align: left;

  ${H2} {
    margin: 0 0 24px -5px;
  }
`;
