import {
  CollectionReference,
  Timestamp,
  collection,
  getFirestore,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

export interface SetLiveRule {
  name: string;
  slug: string;
  createdAt: Timestamp;
}

export const useSetLiveRules = () => {
  const [setLiveRules] = useCollection(
    collection(
      getFirestore(),
      "setlive_rules",
    ) as CollectionReference<SetLiveRule>,
  );

  return { setLiveRules };
};
