import { useUser } from "auth";
import { User } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const UserMenu = () => {
  const { user, signOut } = useUser();
  const [open, setOpen] = useState(false);
  if (!user) {
    return null;
  }
  return (
    <Container>
      <Image onClick={() => setOpen(!open)} name={user.displayName || ""} />
      {open && (
        <Menu>
          <OptionLink to="/">Artist Select</OptionLink>
          <Option onClick={() => signOut()}>Sign Out</Option>
          <Info>
            {user.displayName}&lt;{user.email}&gt;
          </Info>
        </Menu>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Menu = styled.div`
  width: 300px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
`;

const optionStyles = css`
  display: block;
  text-decoration: none;
  font-family: Poppins;
  padding: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #e6e9eb;
  }
`;

const OptionLink = styled(Link)`
  ${optionStyles}
`;

const Option = styled.a`
  ${optionStyles}
`;

const Info = styled.div`
  font-family: Poppins;
  padding: 8px;
  font-size: 12px;
`;

const Image = ({ name = "", ...props }) => {
  let safeName = name ? name : "SET.Live User";
  return (
    <ImageContainer {...props}>
      {safeName.substr(0, 1).toLocaleUpperCase()}
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background: #3795bd;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    height: 24px;
    width: 24px;
  }
`;
