import React from "react";
import styled, { css } from "styled-components";

type RadioContainerProps = {
  isSelected: boolean;
  disabled?: boolean;
};
export const RadioContainer = styled.div<RadioContainerProps>`
  height: 16px;
  width: 16px;
  border: 1px solid #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  ${(props) =>
    props.isSelected &&
    !props.disabled &&
    css`
      border: 1px solid #ffffff;
    `}
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid #999999;
    `}
`;

export const RadioSelect = styled.div`
  background: #ffffff;
  border: 1px solid #ffffff;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  animation: grow 0.25s ease-in-out;
  @keyframes grow {
    0% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
`;

type Props = {
  isSelected: boolean;
  style?: any;
  disabled?: boolean;
};
export const RadioInput = ({ isSelected, disabled, ...props }: Props) => {
  return (
    <RadioContainer {...props} isSelected={isSelected} disabled={disabled}>
      {isSelected && !disabled && <RadioSelect />}
    </RadioContainer>
  );
};
