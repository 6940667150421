import { Preview, useBuilderContext, useConfigSlice } from "Components";
import { H2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { SelectMerchMenu } from "melodies-source/SelectMerchMenu";
import { useNavigate } from "react-router-dom";
import { SvgBack } from "melodies-source/Svgs/Back";
import { Checkbox } from "melodies-source/Selectable";
import { InfoCard } from "Components/InfoCard";
import { generatePreRegText } from "@max/common/creator";
import { SvgRight } from "melodies-source/Svgs/Right";
import { IconSweepsCard } from "Images/CardIcons";
import { Link } from "melodies-source/Link";
import { useModalContext } from "Components/ModalContext";

export const Sweepstakes = () => {
  const nav = useNavigate();
  const { config } = useBuilderContext();
  const { setModal } = useModalContext();

  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "voteType",
    "checkInVote",
    "sweepstakes",
    "sweepstakesPrize",
    "sweepstakesPrizeTerms",
    "sweepstakesPrizeCustom",
  ]);

  const { prizeOptions } = generatePreRegText(config);

  const handleCustomOpen = () => {
    setField("sweepstakesPrize", "customPrize");
  };
  const handleSelectNone = () => {
    setField("sweepstakes", false);
    setField("sweepstakesPrize", "");
    setField("sweepstakesPrizeCustom", "");
  };

  const handleSelectPrize = (v: string) => {
    setField("sweepstakesPrize", v);
    setField("sweepstakesPrizeCustom", "");
  };

  const handleCheck = () => {
    setField("sweepstakesPrizeTerms", !fields.sweepstakesPrizeTerms?.content);
  };

  const customOpen = fields.sweepstakesPrize.content === "customPrize";

  return (
    <Container>
      <Button
        text
        leftIcon={<SvgBack />}
        style={{ width: "fit-content", margin: "0 0 10px -15px" }}
        onClick={() => nav("../checkinvote")}
        nudgeLeft
      >
        Back
      </Button>
      <H2>
        Will you offer a<br />
        sweepstakes prize?
      </H2>
      <ButtonContainer>
        <Button
          variant="round"
          style={{ width: 80 }}
          active={fields.sweepstakes.content ? true : false}
          onClick={() => setField("sweepstakes", true)}
        >
          Yes
        </Button>
        <Button
          variant="round"
          style={{ width: 72 }}
          active={fields.sweepstakes.content !== false ? false : true}
          onClick={handleSelectNone}
        >
          No
        </Button>
      </ButtonContainer>
      {fields.sweepstakes.content && (
        <Section>
          <SelectMerchMenu
            value={
              fields.sweepstakesPrizeCustom.content?.en || customOpen
                ? "Custom option"
                : fields.sweepstakesPrize.content
            }
            onChange={handleSelectPrize}
            setCustom={handleCustomOpen}
            label={"Prize Selection"}
            buttonText="Custom option"
            placeholder="Select the prize you will offer"
            options={prizeOptions}
            secondary
          />
          {(customOpen || fields.sweepstakesPrizeCustom.content?.en) && (
            <TextInput
              secondary
              placeholder="What will the prize be?"
              label="Prize Description"
              onChange={(v) => setField("sweepstakesPrizeCustom", { en: v })}
              value={fields.sweepstakesPrizeCustom.content?.en}
              style={{ margin: "24px 0 0" }}
            />
          )}
          <Checkbox
            style={{ margin: "24px 0 0" }}
            value={fields.sweepstakesPrizeTerms?.content}
            onChange={handleCheck}
            label={
              <p style={{ fontSize: 13, lineHeight: "160%" }}>
                I have reviewed and accept the{" "}
                <Link
                  style={{ fontSize: 13, lineHeight: "160%" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${process.env.REACT_APP_PUBLISHED_URL}/artist-terms`,
                      "_blank"
                    );
                  }}
                >
                  terms of use
                </Link>{" "}
                and will only operate promotions (including sweepstakes) in
                accordance with applicable laws. The{" "}
                <Link
                  style={{ fontSize: 13, lineHeight: "160%" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal({ rules: true });
                  }}
                >
                  official rules template
                </Link>{" "}
                is provided “as is” without warranty of any kind. SET/MAX makes
                no representations that the official rules are legally
                sufficient or otherwise appropriate for your promotion. You are
                expected and encouraged to have your legal counsel review and
                such promotion terms or rules and you hereby agree to indemnify
                and hold harmless MAX from any liability arising out of your
                promotions."
              </p>
            }
          />
        </Section>
      )}
      <Button
        style={{ width: 180, marginTop: 40 }}
        disabled={!isValid}
        onClick={() => {
          save();
          nav(`../postregistration`);
        }}
        rightIcon={<SvgRight />}
        nudgeRight
      >
        Next
      </Button>
      <InfoCard
        text="Your fans are more likely to participate when offered a prize. Terms and conditions apply."
        icon={<IconSweepsCard />}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  color: #ffffff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 13px;
  width: fit-content;
  margin: 20px 0 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 32px;
`;
