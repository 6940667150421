import { CreateAuthentication } from "@musicaudienceexchange/firebase-auth-ui";
import { firebaseApp } from "Components";

export const { useUser, UserContext, UserProvider } = CreateAuthentication({
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  firebaseApp,
  loadClaims: (data) => ({
    isAdmin: typeof data.admin === "boolean" && data.admin === true,
  }),
});
