import {
  MutableRefObject,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

type AnimationProps = {
  className?: string;
  introKeyframes?: Keyframe[];
  outroKeyframes?: Keyframe[];
  introEasing?: string;
  outroEasing?: string;
  duration: number;
  iterations?: number;
  display: boolean;
  onIntroAnimationEnd?: () => void;
  onOutroAnimationEnd?: () => void;
};

export const AnimationConditional = ({
  alternativeRef,
  children,
  className,
  introKeyframes,
  introEasing,
  outroKeyframes,
  outroEasing,
  duration,
  iterations = 1,
  display,
  onIntroAnimationEnd,
  onOutroAnimationEnd,
}: PropsWithChildren<
  AnimationProps & {
    alternativeRef?: MutableRefObject<HTMLDivElement | null>;
  }
>) => {
  const localRef = useRef<HTMLDivElement>(null);
  const ref = alternativeRef ?? localRef;

  const [removeComponent, setRemoveComponent] = useState(
    introKeyframes ? true : false,
  );

  useLayoutEffect(() => {
    const childElement = ref.current;
    if (display) {
      setRemoveComponent(false);
      if (!childElement) return;
      if (introKeyframes?.length && introKeyframes.length > 1) {
        const animation = childElement.animate(introKeyframes, {
          duration,
          iterations,
          easing: introEasing ?? "linear",
        });
        animation.onfinish = () => {
          onIntroAnimationEnd?.();
        };
      }
    } else {
      if (!childElement) return;
      if (outroKeyframes?.length && outroKeyframes.length > 1) {
        const animation = childElement.animate(outroKeyframes, {
          duration,
          iterations,
          easing: outroEasing ?? "linear",
        });
        animation.onfinish = () => {
          setRemoveComponent(true);
          onOutroAnimationEnd?.();
        };
      } else {
        setRemoveComponent(true);
      }
    }
  }, [display, duration, iterations, ref, removeComponent]);

  return (
    <>
      {!removeComponent && (
        <div className={className} style={{ display: "inherit" }} ref={ref}>
          {children}
        </div>
      )}
    </>
  );
};
