import { Modal } from "melodies-source/Modal";
import { useBuilderContext, useConfigSlice } from "./BuilderContext";
import { EditableContent } from "./EditableContent";
import { Body1, H2, H3, H4, Label } from "melodies-source/Text";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Concert } from "@max/common";
import { Datepicker } from "melodies-source/DatePicker";
import { PlacesAutocomplete } from "./PlacesAutocomplete";
import {
  GOOGLE_MAP_PIN_ICON,
  GOOGLE_PLACES_ESTABLISHMENT_TYPES,
  getTimeZoneIdByGeocode,
} from "Utils/maps";
import { DateTime } from "luxon";
import { SvgCalendar } from "melodies-source/Svgs/Calendar";
import { EditIconButton } from "./EditButton";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { TextInput } from "melodies-source/TextInput";

const EditStates = ["time", "venue", "address", "places", "listing"] as const;

type EditState = (typeof EditStates)[number];

type ManualConcert = Partial<Concert>;

export const AddShowModal = ({
  isOpen,
  setIsOpen,
  setCustomConcerts,
  customConcerts,
}: any) => {
  const [edit, setEdit] = useState<EditState | null>(null);
  const { config } = useBuilderContext();

  const [location, setLocation] = useState<ManualConcert | null>(null);
  const [showTime, setShowTime] = useState<any>(null);

  useEffect(() => {
    if (!location?.address) {
      setEdit("address");
    }
    if (location?.addressType === "address" && !location?.venue) {
      setEdit("venue");
    } else {
      // setEdit(null);
    }
  }, [edit]);

  const handlePlaceSelect = async (
    place: google.maps.GeocoderResult,
    placeName: string
  ) => {
    if (place && placeName) {
      const { lat, lng } = place.geometry.location;
      //@ts-ignore
      let update: Concert = {
        locationAddedManually: true,
        address: place.formatted_address,
        coords: [lat(), lng()],
        deletedAt: null
      };
      const timeZone = await getTimeZoneIdByGeocode(lat(), lng());
      if (timeZone) {
        update.timeZone = timeZone;
      }
      const checkPlaceType = place.types.findIndex((type) =>
        GOOGLE_PLACES_ESTABLISHMENT_TYPES.includes(type)
      );
      if (checkPlaceType > -1) {
        update.venue = placeName;
        update.addressType = "establishment";
      } else {
        update.venue = "";
        update.addressType = "address";
      }
      update.geocode = place;
      setLocation(update);
      setEdit(null);
    }
  };

  const handleReset = () => {
    setLocation(null);
    setShowTime(DateTime.now());
    setEdit("address");
    setIsOpen(false);
  };

  const handleSelect = () => {
    let update = [...customConcerts];
    update.push({
      ...location,
      date: showTime || DateTime.now(),
      isSelected: true,
      id: crypto.randomUUID(),
    });
    setCustomConcerts(update);
    handleReset();
  };
  console.log("shows", customConcerts);

  return (
    <Modal
      header="Manually add a show"
      isOpen={isOpen}
      onClose={() => {
        handleReset();
      }}
      variant="small"
      style={{ height: "100%", position: "relative" }}
      confirmation={{
        onConfirm: handleSelect,
        isDisabled: location?.venue && location?.address ? false : true,
        label: "Add show",
      }}
    >
      <>
        <Label style={{ marginTop: 20 }}>Please tell us about your show:</Label>
        <EditableContent
          secondary
          name="address"
          edit={edit}
          setEdit={setEdit}
          placeholder="Type a venue or address..."
          label="Location"
          onCancel={() => setEdit(null)}
          editState={
            <PlacesAutocomplete
              placeholder="Type a venue or address..."
              onChange={handlePlaceSelect}
              style={{ marginTop: -4 }}
              secondary
            />
          }
        >
          {location?.address &&
            (location?.addressType !== "address" ? (
              <div>
                <H3>{location?.venue}</H3>
                <H4>{location?.address}</H4>
              </div>
            ) : (
              <div>
                <H3>{location?.address?.split(",")[0]}</H3>
                <H4>
                  {location?.address?.slice(location?.address.indexOf(",") + 1)}
                </H4>
              </div>
            ))}
        </EditableContent>

        {location?.addressType === "address" && (
          <EditableContent
            label="Venue Name"
            name="venue"
            edit={edit}
            setEdit={setEdit}
            secondary
            placeholder="Type a venue or address..."
            onConfirm={() => setEdit(null)}
            onCancel={() => setEdit(null)}
            editState={
              <TextInput
                secondary
                onChange={(v: any) => {
                  let update = { ...location };
                  update.venue = v;
                  setLocation(update);
                }}
                value={location?.venue || ""}
                placeholder="Enter venue name..."
              />
            }
          >
            {location?.venue && <H3>{location?.venue}</H3>}
          </EditableContent>
        )}

        <Datepicker
          label="Date and time"
          minDate={DateTime.now()}
          value={showTime || DateTime.now()}
          onChange={(v) => setShowTime(v)}
          onClose={() => setEdit(null)}
          onOpen={() => setEdit("time")}
          style={{ margin: "16px 0 0" }}
          disabled={!!(edit && edit !== "time")}
          renderInput={
            <>
              <Label style={{ color: "#ffffff80", marginBottom: 4 }}>
                Date and time
              </Label>
              <H3 style={{ display: "flex", alignItems: "center" }}>
                <SvgCalendar style={{ marginRight: 12, color: "#ffffff80" }} />
                {showTime?.toFormat("LL/dd/yyyy • h:mm a (ZZZZ)")}
                <IconButton
                  style={{
                    position: "relative",
                    marginLeft: 12,
                    bottom: "initial",
                  }}
                  disabled={!!(edit && edit !== "time")}
                >
                  <SvgEditAlt />
                </IconButton>
              </H3>
            </>
          }
        />
      </>
    </Modal>
  );
};

const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    min-width: 120px;
    & + button {
      margin-left: 12px;
    }
  }
`;
const IconButton = styled(EditIconButton)`
  position: absolute;
  bottom: 4px;
  right: 0;
`;
