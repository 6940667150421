import { BuilderFields, Fields } from "@max/common";
import {
  ValidationMessages,
  SetFieldAction,
  getBuilderFields,
  useBuilderContext,
  useConfigSlice,
  validate,
} from "Components";
import { DateTime } from "luxon";
import { createContext, useContext, useEffect, useState } from "react";

export const EditorContext = createContext<any>(undefined);

export const EditorProvider = (props: any) => {
  const [activeSlice, setActiveSlice] = useState<string[] | null>();
  const { config } = useBuilderContext();
  const [, setSlices] = useState<string[][]>([]);

  const register = (slice: string[]) => {
    setSlices((slices) => {
      let update = [...slices];
      update.push(slice);
      return update;
    });
    return () => {
      setSlices((slices) => slices.filter((s) => s !== slice));
    };
  };
  const requestView = (slice: string[] | null) => {
    if (activeSlice) {
      const [, isValid] = validate(
        config,
        //@ts-ignore
        getBuilderFields(config, activeSlice),
      );
      if (isValid) {
        setActiveSlice(slice);
      }
    } else {
      setActiveSlice(slice);
    }
  };
  const value = {
    register,
    activeSlice,
    requestView,
  };

  return <EditorContext.Provider {...props} value={value} />;
};

export const useEditorContext = () => useContext(EditorContext);

export interface EditorSlice {
  (fields: Fields[]): [
    Pick<BuilderFields<DateTime>, Fields>,
    ValidationMessages,
    SetFieldAction,
    boolean,
    (b?: boolean) => void,
    string[],
    ValidationMessages,
  ];
}

export const useEditorSlice: EditorSlice = (fields) => {
  const { register, requestView, activeSlice } = useEditorContext();
  const [fieldsVals, validation, setField, isValid, , warnings] =
    useConfigSlice(fields);
  useEffect(() => {
    return register(fields);
  }, [fields]);
  const request = (close?: boolean) => requestView(close ? null : fields);
  return [
    fieldsVals,
    validation,
    setField,
    isValid,
    request,
    activeSlice,
    warnings,
  ];
};
