import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { useEditorSlice } from "./EditorContext";
import { Error, H3 } from "melodies-source/Text";
import { IconChevronDown } from "Images/Icons";
import { BuilderFields, Fields } from "@max/common";
import { SvgErrorAlt } from "melodies-source/Svgs/ErrorAlt";
import { ValidationMessages, SetFieldAction } from "Components";
import { DateTime } from "luxon";

export interface SliceInternal {
  fields: Pick<BuilderFields<DateTime>, Fields>;
  validation: ValidationMessages;
  setField: SetFieldAction;
  isValid: boolean;
  isActive: boolean;
  activeSlice: string[];
  request: VoidFunction;
  warnings: ValidationMessages;
}

export const Slice = ({
  fields,
  title,
  icon,
  lastIndex,
  content: Content,
  ...props
}: {
  fields: Fields[];
  title: ReactNode;
  icon: ReactNode;
  lastIndex?: boolean;
  content: React.FC<SliceInternal>;
}) => {
  const [
    fieldsVals,
    validation,
    setField,
    isValid,
    request,
    activeSlice,
    warnings,
  ] = useEditorSlice(fields);
  const isActive = activeSlice === fields;

  const errorLength = Object.values(validation).filter((v) => !!v).length;

  return (
    <Container>
      <Header onClick={isActive ? () => request(true) : () => request()}>
        <IconContainer>{icon}</IconContainer>
        <Title>{title}</Title>
        {!isValid && (
          <ErrorContainer>
            <SvgErrorAlt />
            <Error>
              Please fix the highlighted error{errorLength > 1 && "s"}.
            </Error>
          </ErrorContainer>
        )}
        <DropdownIcon open={isActive} />
      </Header>
      <Inner open={isActive} lastIndex={lastIndex}>
        <Content
          fields={fieldsVals}
          validation={validation}
          setField={setField}
          isValid={isValid}
          isActive={isActive}
          activeSlice={activeSlice}
          request={request}
          warnings={warnings}
        />
      </Inner>
      {isActive && !lastIndex && <InnerShadow />}
    </Container>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  margin-right: 16px;

  svg {
    color: #ffffff;
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-right: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 32px;
  svg {
    color: #ff0000;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  ${Error} {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${Error} {
      display: block;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid #404040;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 16px 14px 16px 12px;
  }
`;

const Title = styled(H3)`
  display: flex;
  color: #ffffff;
  flex: 1 1 auto;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
`;

const Inner = styled.div<{ open: boolean; lastIndex?: boolean }>`
  height: 0;
  overflow: hidden;
  ${({ open, lastIndex }) =>
    open &&
    css`
      height: auto;
      padding: 0 24px ${lastIndex ? 32 : 8}px;
      overflow: initial;
      ${({ theme }) => theme.mediaQueries.mobile} {
        padding: 0 16px ${lastIndex ? 24 : 8}px;
      }
    `}
`;

const InnerShadow = styled.div`
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.26) 0%,
    rgba(0, 0, 0, 0) 70.26%
  );
  height: 30px;
  width: 100%;
`;

const DropdownIcon = styled(IconChevronDown)<{ open?: boolean }>`
  color: #ffffff;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  ${(p) => p.open && `transform: rotate(180deg);`};
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 20px;
    height: 20px;
  }
`;
