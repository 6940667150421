import React from "react";
import styled, { css } from "styled-components";
import { Body1, Subtitle2, Selected, Body2 } from "../Text";
import { CheckboxInput } from "./CheckboxInput";
import { RadioInput } from "./RadioInput";

const variants = ["radio", "checkbox"] as const;
type Variant = (typeof variants)[number];

const HoverIndicator = styled.div`
  background: #f2f5f7;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: none;
`;

const SelectableContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  border-radius: 100%;
  :hover {
    cursor: pointer;
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;
      `}
    ${HoverIndicator} {
      display: block;
    }
  }
`;

export type SelectableProps = {
  label?: string | JSX.Element;
  value: any;
  description?: string | JSX.Element;
  variant?: Variant;
  disabled?: boolean;
  style?: any;
  onChange?: (v: boolean) => void;
};
export const Selectable = ({
  label,
  value,
  onChange,
  disabled = false,
  variant = "radio",
  description,
  ...props
}: SelectableProps) => {
  const toggle = () => onChange?.(!value);

  const textUI = (
    <Label onClick={() => toggle()} disabled={disabled}>
      {label && <Body1>{label}</Body1>}
      {description && <Body2>{description}</Body2>}
    </Label>
  );

  return (
    <Container {...props}>
      <SelectableContainer
        disabled={disabled}
        onClick={() => {
          !disabled && toggle();
        }}
      >
        <div
          style={{ zIndex: 1 }}
          tabIndex={0}
          onKeyDown={({ code }) => {
            if (code === "Space" || code === "Enter") toggle();
          }}
        >
          {variant === "radio" && (
            <RadioInput isSelected={value} disabled={disabled} />
          )}
          {variant === "checkbox" && (
            <CheckboxInput isSelected={value} disabled={disabled} />
          )}
        </div>
      </SelectableContainer>
      {label && textUI}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 6px 0;
`;

const Label = styled.label<{ disabled?: boolean }>`
  width: 100%;
  cursor: pointer;
  margin-left: 12px;
  color: #ffffff;
  ${Body1} {
    font-weight: 500;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: #999999;
      ${Subtitle2} {
        color: #999999;
      }
    `}
`;

type RadioInputWrapperProps = {
  isSelected: boolean;
  disabled?: boolean;
};
export const RadioInputWrapper = styled.div<RadioInputWrapperProps>`
  margin-top: 18px;
  display: flex;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 400;
    `}
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    border: solid 1px #1b0076;
    border-radius: 4px;
    padding: 10px 13px;
    display: flex;
    :hover {
      cursor: pointer;
      opacity: 0.8;
      border: solid 1px #1b0076;
    }
  }
`;
// pass disabled
export const Checkbox = ({ ...props }: SelectableProps) => (
  <Selectable variant="checkbox" {...props} />
);

export const Radio = ({ ...props }: SelectableProps) => (
  <Selectable variant="radio" {...props} />
);
