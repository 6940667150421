import React from "react";
import styled, { css } from "styled-components";
import { Body1, Caption, H3, Li as ListItemBase } from "../Text";
import { SvgCheck } from "../Svgs/Check";

const Li = styled(ListItemBase)`
  line-height: 20px;
`;

type IconContainerProps = {
  isAvatar?: boolean;
  dimensions: number;
  hasCaption?: boolean;
};
const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: ${(p) => p.dimensions}px;
  //max-width: ${(p) => p.dimensions}px;
  height: calc(${(p) => p.dimensions}px - 8px - 8px);
  color: #ffffff;
  svg {
    height: 20px;
    width: 20px;
    ${(p) =>
      p.isAvatar &&
      css`
        height: 44px;
        width: 44px;
        ${p.hasCaption &&
        css`
          height: 32px;
          width: 32px;
        `}
      `}
  }
  ${(p) =>
    p.isAvatar &&
    css`
      overflow: hidden;
      border-radius: 6px;
      min-width: unset;
      max-width: unset;
      height: unset;
      margin: 10px 0;
      min-width: 62px;
    `}
  img {
    width: 44px;
    border-radius: 6px;
    margin-right: 12px;
  }
`;

type ListItemContainerProps = {
  onClick?: any;
};
export const ListItemContainer = styled.div<ListItemContainerProps>`
  display: flex;
  align-items: center;
  color: var(--max-text);

  ${(p) =>
    p.onClick &&
    css`
      :hover {
        cursor: pointer;
        //  background: #4c4c4c7b;
      }
    `}
  padding: 8px 12px;
  min-width: 180px;
  user-select: none;
  ${Caption} {
    color: #cccccc;
    line-height: 16px;
    font-size: 10px;
  }
  ${Li} {
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    flex-grow: 1;
  }
`;

const Overlay = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  svg {
    color: #000000;
    width: 30px;
  }
`;

export const LIST_ITEM_PADDING_LEFT = "12px";

type ListItemProps = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  avatar?: JSX.Element;
  caption?: string;
  caption2?: React.ReactNode;
  isLarge?: boolean;
  disabled?: boolean;
  checked?: boolean;
  data?: any;
  setValue?: (data: any) => void;
  isSelected?: boolean;
  children: JSX.Element | string;
} & React.BaseHTMLAttributes<HTMLDivElement>;
export const ListItem = ({
  leftIcon,
  rightIcon,
  avatar,
  checked,
  children,
  disabled,
  data,
  setValue,
  caption,
  caption2,
  isSelected = false,
  isLarge = false,
  ...props
}: ListItemProps) => {
  const leftUI = leftIcon || avatar;

  if (!caption && !caption2) {
    return (
      <ListItemContainer {...props}>
        {leftUI && (
          <IconContainer dimensions={44} isAvatar={!!avatar}>
            {leftUI}
          </IconContainer>
        )}
        <Li disabled={disabled}>{children}</Li>

        {rightIcon && (
          <IconContainer dimensions={44}>{rightIcon}</IconContainer>
        )}
      </ListItemContainer>
    );
  } else {
    if (!avatar) {
      const textML = leftUI ? 7 : 0;

      return (
        <ListItemContainer
          {...props}
          //style={{ minHeight: 44, paddingLeft: pl }}
        >
          {leftUI && <IconContainer dimensions={44}>{leftUI}</IconContainer>}
          {isLarge ? (
            <FlexColumn disabled={disabled} style={{ marginLeft: textML }}>
              <H3>{children}</H3>
              <Body1 style={{ marginTop: "4px" }}>{caption}</Body1>
              {caption2 && (
                <Body1 style={{ marginTop: "4px" }}>{caption2}</Body1>
              )}
            </FlexColumn>
          ) : (
            <FlexColumn>
              <Li
                style={disabled ? { color: "#999999" } : {}}
                disabled={disabled}
              >
                {children}
              </Li>
              <Caption>{caption}</Caption>
              <Caption>{caption2}</Caption>
            </FlexColumn>
          )}
          {rightIcon && (
            <IconContainer dimensions={44}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      );
    } else {
      return setValue ? (
        <ListItemContainer {...props} onClick={() => setValue(data)}>
          <IconContainer dimensions={52} isAvatar={true} hasCaption={true}>
            {checked && (
              <Overlay>
                <SvgCheck />
              </Overlay>
            )}
            {avatar}
          </IconContainer>
          <FlexColumn>
            <Li disabled={disabled}>{children}</Li>
            <Caption>{caption}</Caption>
          </FlexColumn>
          {rightIcon && (
            <IconContainer dimensions={52}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      ) : (
        <></>
      );
    }
  }
};

const FlexColumn = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${({ disabled }) =>
    disabled &&
    css`
      ${H3} {
        color: red;
      }
    `}
`;
