// @ts-nocheck
import React, { CSSProperties } from "react";
import { useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
  srcImg: string;
  aspect: number;
  onCrop: (blob: Blob | null) => void;
  Wrapper: React.FC;
};
const ImageCrop = ({
  srcImg,
  options = {},
  onCrop,
  Wrapper = div,
  aspect,
}: Props) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 80,
    x: 10,
    y: 10,
    aspect: aspect || 1,
    ...options,
  });

  const onCropEnd = (_crop: Crop) => {
    const image = imageRef.current;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = _crop.width * scaleX;
    canvas.height = _crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      _crop.x * scaleX,
      _crop.y * scaleY,
      _crop.width * scaleX,
      _crop.height * scaleY,
      0,
      0,
      _crop.width * scaleX,
      _crop.height * scaleY
    );
    canvas.toBlob((blob) => {
      onCrop(blob);
    }, "image/jpeg");
  };

  return (
    <Wrapper>
      <ReactCrop
        src={srcImg}
        crop={crop}
        onImageLoaded={(img) => (imageRef.current = img)}
        onChange={(c, crop) => setCrop(crop)}
        onComplete={(c) => onCropEnd(c)}
      />
    </Wrapper>
  );
};

export default ImageCrop;
