import * as React from "react";
import { SVGProps } from "react";
export const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11,2 L16,7 L16,17 C16,17.5522847 15.5522847,18 15,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,3 C4,2.44771525 4.44771525,2 5,2 L11,2 Z M10,3 L5,3 L5,17 L15,17 L15,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,3 Z M14,14 L14,15 L6,15 L6,14 L14,14 Z M14,12 L14,13 L6,13 L6,12 L14,12 Z M14,10 L14,11 L6,11 L6,10 L14,10 Z M9,8 L9,9 L6,9 L6,8 L9,8 Z M11,3.415 L11,7 L14.586,7 L11,3.415 Z M9,4 L9,7 L6,7 L6,4 L9,4 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
