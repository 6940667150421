import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ReactNode, useState } from "react";
import styled from "styled-components";

type Variant = "grid" | "column" | "row";

/*
 * Exposes a prop `activeId` to show drag overlay if needed
 */
export function DndContainer<T extends { internalId: UniqueIdentifier }>({
  activationConstraint,
  items,
  onDragEnd,
  options,
  variant,
  children,
}: {
  activationConstraint?: PointerActivationConstraint;
  items: T[];
  onDragEnd: (event: DragEndEvent) => void;
  variant: Variant;
  options?: { columns?: number };
  children: (props: { activeId: UniqueIdentifier | null }) => ReactNode;
}) {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={(e) => {
        document.documentElement.removeAttribute("style");
        onDragEnd(e);
        setActiveId(null);
      }}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={items.map((item) => item.internalId)}
        strategy={
          variant === "grid"
            ? rectSortingStrategy
            : variant === "column"
            ? verticalListSortingStrategy
            : horizontalListSortingStrategy
        }
      >
        {variant === "grid" && (
          <StyledGrid columns={options?.columns || 4}>
            {children({ activeId })}
          </StyledGrid>
        )}
        {variant === "column" && (
          <TouchContainer>{children({ activeId })}</TouchContainer>
        )}
        {variant === "row" && (
          <TouchContainer>{children({ activeId })}</TouchContainer>
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    if (!event.active) {
      return;
    }
    document.documentElement.setAttribute("style", "scroll-behavior: auto");
    setActiveId(event.active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
}

const TouchContainer = styled.div`
  & > div {
    touch-action: manipulation;
  }
`;

const StyledGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns}, minmax(0, 1fr))`};
  gap: 10px;

  ${({ theme }) => theme.media.mobile} {
    gap: 5px;
  }
  & > div {
    touch-action: manipulation;
  }
`;
