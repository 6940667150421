import { IconAppearance } from "Images/Icons";
import { Slice } from "../Slice";
import styled from "styled-components";
import { Preview, useBuilderContext } from "Components";
import { MouseEventHandler } from "react";
import { Button as StyledButton } from "melodies-source/Button";
import { Label, H4 } from "melodies-source/Text";
import { SvgUpload } from "melodies-source/Svgs/Upload";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { ColorOptions } from "Components/ColorOptions";
import {
  Colors as ColorsOption,
  EventTypes,
  getColorConfig,
} from "@max/common";
import { ReplaceImage } from "Components/ReplaceImage";

export const Appearance = () => {
  return (
    <Slice
      fields={["type", "colors", "image"]}
      content={Internal}
      title="Appearance"
      icon={<IconAppearance />}
    />
  );
};

const Internal = ({ fields, validation, setField, isValid }: any) => {
  const { config, save } = useBuilderContext();

  const handleClick = (option: EventTypes) => {
    setField("type", option);
  };

  const template = fields?.type?.content;
  const {
    imageColors,
    templateColors,
    imageIndex,
    selectedColors,
    generateUpdatedColors,
  } = getColorConfig(config);

  const setSelected = (colorOption: ColorsOption) => {
    if (colorOption?.dark) {
      const updatedColors = generateUpdatedColors(colorOption);
      setField("colors", updatedColors);
    }
  };

  const templateCheck = !!selectedColors?.light;

  const isSelected = (color1: ColorsOption, color2: ColorsOption) => {
    if (color1.dark !== color2.dark) {
      return false;
    }
    if ([1, 2].includes(template)) {
      if (color1.light !== color2.light) {
        return false;
      }
    }
    return true;
  };

  return (
    <Container>
      <Content>
        <H4 style={{ marginBottom: 4 }}>Templates</H4>
        <Label>
          You can switch templates at any time - your event details will be
          saved.
        </Label>
        <TemplateContainer>
          {(
            Object.keys(EventTypes).filter((v) =>
              isNaN(Number(v)),
            ) as (keyof typeof EventTypes)[]
          ).map((key) => {
            const type = EventTypes[key];
            const colors =
              fields.colors.content?.[imageIndex]?.[type] ||
              imageColors?.[type]?.[0];
            return (
              <Option
                key={`preview-${key}`}
                override={{
                  type,
                  colors,
                }}
                selected={template === type}
                onClick={() => handleClick(type)}
              />
            );
          })}
        </TemplateContainer>
        <H4 style={{ margin: "25px 0 12px" }}>Color Palette</H4>
        <ColorsContainer templateCheck={templateCheck}>
          {templateColors?.slice(0, 10)?.map((colorOption) => {
            const selected = !!(
              selectedColors && isSelected(colorOption, selectedColors)
            );
            return (
              colorOption.dark && (
                <ColorOptions
                  key={`${template}-${Object.values(colorOption).join("")}`}
                  darkColor={colorOption.dark}
                  lightColor={colorOption.light}
                  row={true}
                  templateCheck={templateCheck}
                  checked={selected}
                  setColor={() => setSelected(colorOption)}
                />
              )
            );
          })}
        </ColorsContainer>
        <H4 style={{ margin: "25px 0 12px" }}>Header Image</H4>
        <ImageContainer>
          {config?.images?.map((img: { src: string }) => (
            <ImageWrapper>
              <img
                src={img?.src || ""}
                onClick={() => {
                  setField("image", img?.src);
                  save();
                }}
                alt="artist"
                key={img?.src}
              />
              {fields?.image?.content === img?.src && (
                <ImageOverlay>
                  <SvgCheck />
                </ImageOverlay>
              )}
            </ImageWrapper>
          ))}
        </ImageContainer>
        <ReplaceImage icon={<SvgUpload />} variant="primary" />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;
const ColorsContainer = styled.div<{ templateCheck?: boolean }>`
  display: grid;
  width: 420px;
  grid-template-columns: repeat(auto-fit, 66px);
  gap: 20px;
  & > div {
    margin: ${({ templateCheck }) => (templateCheck ? -13 : -8)}px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 66px);
    gap: 12px;
  }
`;
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(1, auto);
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 25px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    object-position: top center;
  }
`;
const Button = styled(StyledButton)``;
const Content = styled.div`
  color: #ffffff;
  width: 100%;
  ${Button} {
    width: 180px;
    margin: 35px 0 25px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    & > ${Label} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
const TemplateContainer = styled.div`
  display: grid;
  width: 400px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(1, 160px);
  column-gap: 17px;
  margin: 16px 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 76px);
    grid-template-rows: 160px 160px;
    gap: 20px;
  }
`;

const Prev = styled(Preview)`
  transform: scale(0.203);
  transform-origin: top left;
  width: 396px;
  height: 800px;
  min-height: 800px;
`;

const Option = ({
  selected,
  override,
  ...props
}: {
  onClick: MouseEventHandler<HTMLDivElement>;
  selected: boolean;
  override: any;
}) => {
  return (
    <Container>
      <Overlay {...props}>
        <OverlayHelper style={{ opacity: selected ? 1 : 0 }}>
          <SvgCheck />
        </OverlayHelper>
        <Prev {...{ override, isThumbnail: true }} />
      </Overlay>
      <Shadow />
    </Container>
  );
};

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 76px;
  height: 245px;
  overflow: hidden;
  z-index: 1;
`;

const Shadow = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 1px;
  right: 2px;
  bottom: 1px;
  left: 2px;
  border-radius: 12px;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.08);
  z-index: 0;
`;

const OverlayHelper = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 1;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0px;
  bottom: 0;
  left: 2px;
  border-color: #ffffff;
  border-style: solid;
  svg {
    width: 60px;
    height: 60px;
    opacity: 0.9;
    color: #000000;
  }
`;
const ImageOverlay = styled.div<{ row?: boolean; templateCheck?: boolean }>`
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  //z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: black;
    width: 60px;
    height: 60px;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    object-position: top center;
  }
`;
