import { Radio } from "melodies-source/Selectable";
import { Body2, H4, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";
import { Slice, SliceInternal } from "../Slice";
import { IconPostReg } from "Images/Icons";
import { Release } from "@max/common/creator";
import { filterUndefined } from "@max/common/utils";
import { useBuilderContext } from "Components";
import { useEffect, useState } from "react";
import { GallerySelect } from "melodies-source/GallerySelect";
import SpotifyModal from "Components/SpotifyModal";
import { useDataOverrideContext } from "Routes/Builder/DataOverrideContext";
import { UserEventStates, StreamingServiceNames } from "@max/common";
import { updateURL, validateURL } from "Utils/helpers";
import { useModalContext } from "Components/ModalContext";
import { Warning } from "../Components/common";

export const PostRegistration = () => {
  return (
    <Slice
      fields={[
        "postRegistration",
        "postRegistrationAlbum",
        "postRegistrationCustomAlbum",
        "postRegistrationBody",
        "postRegistrationButtonLabel",
        "postRegistrationLinkLabel",
        "postRegistrationLinkUrl",
        "postRegistrationHeadline",
      ]}
      content={Internal}
      title="Post Registration"
      icon={<IconPostReg />}
    />
  );
};

const Internal = ({
  fields,
  validation,
  setField,
  isValid,
  isActive,
  warnings,
}: SliceInternal) => {
  const { config } = useBuilderContext();
  const { setData } = useDataOverrideContext();
  const { setModal } = useModalContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isActive) {
      setData({
        status: UserEventStates.registered,
        values: {},
      });
      return () => {
        setData(null);
      };
    }
  }, [isActive]);

  const releases = [
    ...(config?.releases?.slice(0, 5) || []),
    fields?.postRegistrationCustomAlbum?.content,
  ].filter(filterUndefined);

  useEffect(() => {
    if (isActive) {
      setModal({ confirmCopyChange: {} });
    }
  }, [fields.postRegistration.content]);

  useEffect(() => {
    if (isActive) {
      setModal({ confirmCopyChange: { fields: ["postRegistrationBody"] } });
    }
  }, [fields.postRegistrationAlbum.content?.name]);

  return (
    <Wrapper>
      <Label style={{ margin: "8px 0" }}>
        Select what you want your fans to see
      </Label>
      <Radio
        variant="radio"
        value={fields.postRegistration.content === "promo"}
        label="Promote a release"
        onChange={() => setField("postRegistration", "promo")}
      />
      <Radio
        variant="radio"
        value={fields.postRegistration.content === "thank"}
        label="Thank you message"
        onChange={() => setField("postRegistration", "thank")}
      />
      <Radio
        variant="radio"
        value={fields.postRegistration.content === "link"}
        label="Custom Link"
        onChange={() => setField("postRegistration", "link")}
      />
      <Container>
        <LabelContainer>
          <TextInput
            placeholder="Thank you!"
            label="Headline"
            helperText={
              validation.postRegistrationHeadline ||
              (warnings.postRegistrationHeadline && (
                <Warning>{warnings.postRegistrationHeadline}</Warning>
              )) ||
              "Limit 50 characters"
            }
            onChange={(v) => setField("postRegistrationHeadline", { en: v })}
            value={fields.postRegistrationHeadline.content?.en}
            hasError={!!validation.postRegistrationHeadline}
          />
          <Textarea
            helperText={
              validation.postRegistrationBody ||
              (warnings.postRegistrationBody && (
                <Warning>{warnings.postRegistrationBody}</Warning>
              )) ||
              "Limit 200 characters"
            }
            placeholder="You can save now my recent release"
            label="Body Text (Optional)"
            onChange={(v) => setField("postRegistrationBody", { en: v })}
            value={fields.postRegistrationBody.content?.en}
            hasError={!!validation.postRegistrationBody}
          />
          {fields.postRegistration.content === "promo" && (
            <TextInput
              placeholder="Save"
              helperText={
                validation.postRegistrationButtonLabel ||
                (warnings.postRegistrationButtonLabel && (
                  <Warning>{warnings.postRegistrationButtonLabel}</Warning>
                )) ||
                "Limit 15 characters"
              }
              label="Button label (e.g. Save or Pre-Save)"
              onChange={(v) =>
                setField("postRegistrationButtonLabel", { en: v })
              }
              value={fields.postRegistrationButtonLabel.content?.en}
              hasError={!!validation.postRegistrationButtonLabel}
            />
          )}
          {fields.postRegistration.content === "link" && (
            <>
              <TextInput
                placeholder="Button Label"
                label="Button Label"
                helperText={
                  validation.postRegistrationLinkLabel ||
                  (warnings.postRegistrationLinkLabel && (
                    <Warning>{warnings.postRegistrationLinkLabel}</Warning>
                  )) ||
                  "Limit 30 characters"
                }
                onChange={(v) =>
                  setField("postRegistrationLinkLabel", { en: v })
                }
                value={fields.postRegistrationLinkLabel.content?.en}
                hasError={!!validation.postRegistrationLinkLabel}
              />
              <TextInput
                placeholder="Enter URL"
                label="Button URL"
                onChange={(v) => setField("postRegistrationLinkUrl", v)}
                onBlur={() => {
                  if (!!fields.postRegistrationLinkUrl.content?.length) {
                    setField(
                      "postRegistrationLinkUrl",
                      updateURL(fields.postRegistrationLinkUrl.content),
                    );
                  }
                }}
                value={fields.postRegistrationLinkUrl.content}
                hasError={!!validation.postRegistrationLinkUrl}
                helperText={validation.postRegistrationLinkUrl || ""}
              />
            </>
          )}
        </LabelContainer>

        {fields.postRegistration.content === "promo" && (
          <PromoWrapper>
            <div>
              {releases && (
                <>
                  <Label>Choose featured release</Label>
                  <GallerySelect
                    style={{ margin: "5px 0" }}
                    data={releases}
                    setValue={(v: any) => {
                      setField("postRegistrationAlbum", v);
                    }}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    hasButton={!fields?.postRegistrationCustomAlbum?.content}
                    checked={fields?.postRegistrationAlbum?.content?.releaseId}
                    replaceItem={() => {
                      setIsOpen(true);
                    }}
                  />
                </>
              )}
              <SpotifyModal
                onSelect={(v: Release) => {
                  setField("postRegistrationCustomAlbum", v);
                  setField("postRegistrationAlbum", v);
                }}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
          </PromoWrapper>
        )}
        {fields.postRegistration.content === "promo" && (
          <Streaming>
            <Divider />
            <H4>Connect your release</H4>
            <Body2>
              Add links for any of the following services to allow fans direct
              access to stream or pre-save your release.
            </Body2>
            <FieldList>
              {StreamingServiceNames.map((s) => {
                const { name, path } = s;
                const album = fields.postRegistrationAlbum.content;
                const value = album?.[path];
                const hasError = !!(
                  validation.postRegistrationAlbum &&
                  value &&
                  validateURL(value) === false
                );
                return (
                  <TextInput
                    label={name}
                    placeholder="Paste your URL here..."
                    onChange={(v) =>
                      setField("postRegistrationAlbum", {
                        ...album,
                        [path]: v,
                      } as Release)
                    }
                    onBlur={() => {
                      if (!!value?.length) {
                        setField("postRegistrationAlbum", {
                          ...album,
                          [path]: updateURL(value),
                        } as Release);
                      }
                    }}
                    hasError={hasError}
                    helperText={hasError ? "Please enter a valid URL." : ""}
                    value={value}
                  />
                );
              })}
            </FieldList>
          </Streaming>
        )}
      </Container>
    </Wrapper>
  );
};

const Divider = styled.div`
  display: flex;
  flex: 0 0 1px;
  height: 1px;
  width: 100%;
  background-color: rgba(102, 102, 102, 0.4);
  margin: 24px 0 16px;
`;

const Streaming = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
`;

const FieldList = styled.div`
  display: grid;
  grid-template-columns: 80%;
  row-gap: 20px;
  margin-top: 24px;

  ${H4} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  margin-top: 20px;
  ${(props) => props.theme.mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const PromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 32px;
    & > div > ${Label} {
      margin-bottom: 12px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #ffffff;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
