import {
  EditIconButton,
  EditIconButtonContainer as ButtonContainer,
} from "Components";
import { H4, Label } from "melodies-source/Text";
import styled, { css } from "styled-components";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { IconCheckBold, IconSave, IconUndo } from "Images/Icons";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { useState } from "react";
import { TextInput } from "melodies-source/TextInput";

type EditableContentProps = {
  name?: string;
  placeholder?: string;
  edit?: string | any;
  setEdit?: React.Dispatch<React.SetStateAction<string | any>>;
  //
  children?: React.ReactNode;
  label?: string;
  onConfirm?: (v?: any) => void;
  value?: any;
  editState?: JSX.Element;
  onCancel?: VoidFunction;
  onRemove?: VoidFunction;
  style?: React.CSSProperties;
  secondary?: boolean;
  disabled?: boolean;
  disableEdit?: boolean;
  onChange?: (v: any) => void;
};

export const EditableContent = (props: EditableContentProps) => {
  const {
    children,
    label,
    name,
    placeholder,
    edit,
    setEdit,
    disabled,
    disableEdit,
  } = props;

  const isEdit = !name || edit === name;
  const isDisabled = (!!edit && name !== edit) || disabled;

  return isEdit ? (
    <EditStateComponent {...props} />
  ) : (
    <EditableContentContainer {...props}>
      {label && <Label>{label}</Label>}
      <SelectedContent
        {...(!!setEdit &&
          !isDisabled && {
            onClick: () => setEdit(name),
            style: { cursor: "pointer" },
          })}
        disabled={isDisabled}
      >
        {children || <H4>{placeholder}</H4>}
        {!!setEdit && !disableEdit && (
          <EditButton disabled={isDisabled}>
            <SvgEditAlt />
          </EditButton>
        )}
      </SelectedContent>
    </EditableContentContainer>
  );
};

type EditStateComponentProps = EditableContentProps & {};

const EditStateComponent = ({
  onConfirm,
  onCancel,
  placeholder,
  onRemove,
  editState,
  secondary,
  label,
  setEdit,
  value,
  onChange,
  ...props
}: EditStateComponentProps) => {
  const [localValue, setLocalValue] = useState<any>(value);
  const handleConfirm = () => {
    onConfirm && onConfirm(localValue);
    setEdit && setEdit(null);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setEdit && setEdit(null);
  };

  return (
    <EditableContentContainer
      {...props}
      style={{
        alignItems: "stretch",
        justifyContent: "center",
        ...props.style,
      }}
      confirmAction={!!onConfirm}
      outerAction={!!(onCancel || onRemove)}
    >
      {label && <Label>{label}</Label>}
      <Container>
        {editState || (
          <TextInput
            {...{
              secondary,
              placeholder,
              onChange: onChange || setLocalValue,
              value: onChange ? value : localValue,
            }}
          />
        )}
        {onConfirm && (editState || (!editState && localValue)) && (
          <DoneButtonContainer>
            <DoneButton onClick={handleConfirm} secondary={secondary}>
              <IconSave />
            </DoneButton>
          </DoneButtonContainer>
        )}
        {(onCancel || onRemove) && (
          <OuterActions>
            {onCancel && (
              <EditIconButton onClick={handleCancel}>
                <IconUndo />
              </EditIconButton>
            )}
            {onRemove && (
              <EditIconButton onClick={() => onRemove()}>
                <SvgDelete />
              </EditIconButton>
            )}
          </OuterActions>
        )}
      </Container>
    </EditableContentContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  position: relative;
`;

const DoneButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 4px;
  z-index: 10;
`;

const DoneButton = styled(EditIconButton)<{ secondary?: boolean }>`
  svg {
    width: 22px;
    height: 22px;
  }
  ${(p) =>
    p.secondary
      ? css`
          color: #ffffff;
        `
      : css`
          margin-right: 4px;
          color: #4c4c4c;
          &:hover {
            color: #000000;
          }
        `};
`;

type ContentContainer = {
  outerAction?: boolean;
  confirmAction?: boolean;
  disabled?: boolean;
};

const EditButton = styled(EditIconButton)<{ disabled?: boolean }>`
  margin-left: 8px;
  ${(p) =>
    p.disabled &&
    css`
      color: #4c4c4c;
      cursor: default;
      pointer-events: none;
      touch-action: none;
    `};
`;

export const EditableContentContainer = styled.div<ContentContainer>`
  display: flex;
  flex-direction: column;
  margin: 16px 0 8px;
  position: relative;
  ${Label} {
    color: #ffffff80;
    margin-bottom: 4px;
  }
  ${(p) => p.outerAction && `margin-right: 40px;`};
  ${(p) =>
    p.confirmAction &&
    css`
      input[type="text"] {
        padding-right: 44px;
      }
    `};
`;

const SelectedContent = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: column;
  }
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        ${EditButton} {
          color: #ffffff80;
        }
      }
    `};
`;

const OuterActions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: calc(100% + 8px);
  bottom: 0;
  height: 100%;

  ${ButtonContainer} + ${ButtonContainer} {
    margin-left: 8px;
  }
`;
