import { Label as StyledLabel } from "melodies-source/Text";
import { TextInput as StyledTextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { Slice, SliceInternal } from "../Slice";
import { IconSocial } from "Images/Icons";
import { updateURL } from "Utils/helpers";
import { Fields } from "@max/common";

export const Socials = () => {
  return (
    <Slice
      fields={[
        "socialLinkSpotify",
        "socialLinkInstagram",
        "socialLinkYoutube",
        "socialLinkTiktok",
        "socialLinkTwitter",
        "socialLinkFacebook",
      ]}
      content={Internal}
      title="Social Links"
      icon={<IconSocial />}
    />
  );
};

const Internal = ({ fields, validation, setField, isValid }: SliceInternal) => {
  const handleBlur = (n: Fields) => {
    let value = fields?.[n]?.content;
    if (value) {
      value = updateURL(value as string);
      setField(n, value);
    }
  };
  return (
    <Container>
      <Label>
        Paste any social links you want to display below. Leave a field empty if
        you do not want that social icon to appear.
      </Label>
      <Label>
        Spotify
        <TextInput
          placeholder="Spotify"
          onChange={(v) => setField("socialLinkSpotify", v)}
          value={fields.socialLinkSpotify?.content}
          onBlur={() => handleBlur("socialLinkSpotify")}
          helperText={validation.socialLinkSpotify || ""}
          hasError={!!validation.socialLinkSpotify}
        />
      </Label>
      <Label>
        Instagram
        <TextInput
          placeholder="Instagram"
          onChange={(v) => setField("socialLinkInstagram", v)}
          value={fields.socialLinkInstagram?.content}
          onBlur={() => handleBlur("socialLinkInstagram")}
          helperText={validation.socialLinkInstagram || ""}
          hasError={!!validation.socialLinkInstagram}
        />
      </Label>
      <Label>
        YouTube
        <TextInput
          placeholder="YouTube"
          onChange={(v) => setField("socialLinkYoutube", v)}
          value={fields.socialLinkYoutube?.content}
          onBlur={() => handleBlur("socialLinkYoutube")}
          helperText={validation.socialLinkYoutube || ""}
          hasError={!!validation.socialLinkYoutube}
        />
      </Label>
      <Label>
        TikTok
        <TextInput
          placeholder="TikTok"
          onChange={(v) => setField("socialLinkTiktok", v)}
          value={fields.socialLinkTiktok?.content}
          onBlur={() => handleBlur("socialLinkTiktok")}
          helperText={validation.socialLinkTiktok || ""}
          hasError={!!validation.socialLinkTiktok}
        />
      </Label>
      <Label>
        X <span style={{ fontStyle: "italic" }}>(Formerly Twitter)</span>
        <TextInput
          placeholder="Twitter"
          onChange={(v) => setField("socialLinkTwitter", v)}
          value={fields.socialLinkTwitter?.content}
          onBlur={() => handleBlur("socialLinkTwitter")}
          helperText={validation.socialLinkTwitter || ""}
          hasError={!!validation.socialLinkTwitter}
        />
      </Label>
      <Label>
        Facebook
        <TextInput
          placeholder="Facebook"
          onChange={(v) => setField("socialLinkFacebook", v)}
          value={fields.socialLinkFacebook?.content}
          onBlur={() => handleBlur("socialLinkFacebook")}
          helperText={validation.socialLinkFacebook || ""}
          hasError={!!validation.socialLinkFacebook}
        />
      </Label>
    </Container>
  );
};

const TextInput = styled(StyledTextInput)``;
const Label = styled(StyledLabel)``;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;
  position: relative;
  color: #ffffff;
  width: 80%;

  ${TextInput} {
    width: 100%;
    margin: 4px 0 0;
  }
  ${Label} {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    & > ${Label} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
