import styled from "styled-components";
import { useBuilderContext, useConfigSlice } from "Components/BuilderContext";
import { ColorOptions } from "Components/ColorOptions";
import { Colors as ColorsOption } from "@max/common/event";
import { getColorConfig } from "@max/common/creator";

export const ColorPalette = () => {
  const { config } = useBuilderContext();
  const [fields, validation, setField] = useConfigSlice(["colors", "image"]);
  const { templateColors, selectedColors, generateUpdatedColors } =
    getColorConfig(config);

  const setSelected = (colorOption: ColorsOption) => {
    if (colorOption?.dark) {
      const updatedColors = generateUpdatedColors(colorOption);
      setField("colors", updatedColors);
    }
  };

  const templateType = config.fields.type.content;

  const isSelected = (color1: ColorsOption, color2: ColorsOption) => {
    if (color1.dark !== color2.dark) {
      return false;
    }
    if ([1, 2].includes(templateType)) {
      if (color1.light !== color2.light) {
        return false;
      }
    }
    return true;
  };

  return (
    <Container>
      {templateColors?.slice(0, 6)?.map((colorOption) => {
        const selected = !!(
          selectedColors && isSelected(colorOption, selectedColors)
        );
        return (
          colorOption.dark && (
            <ColorOptions
              key={`${templateType}-${Object.values(colorOption).join("")}`}
              darkColor={colorOption.dark}
              lightColor={colorOption.light}
              checked={selected}
              setColor={() => setSelected(colorOption)}
            />
          )
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 30%;
  right: -65px;
`;
