import { Modal } from "melodies-source/Modal";
import { useModalContext } from "./ModalContext";
import { useConfigSlice } from "./BuilderContext";
import { updateURL } from "Utils/helpers";
import { EditableContent } from "./EditableContent";
import { useState } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Checkbox } from "melodies-source/Selectable";
import { Select } from "melodies-source/Select";
import { PolicyEntities, PrivacyPolicy } from "@max/common";
import { TextInput } from "melodies-source/TextInput";

const entities = PolicyEntities.map((e) => ({ label: e.name, value: e.path }));

type Policy = Partial<PrivacyPolicy>;

export interface PrivacyModalData {
  index?: number;
}

export const PrivacyModal = () => {
  const { setModal, modal } = useModalContext();
  const { privacy } = modal;

  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "privacyPolicyConfirm",
    "privacyPolicies",
    "privacyPolicyDeclined",
  ]);
  const policies = fields.privacyPolicies.content || [];
  const policy =
    privacy?.index !== undefined
      ? fields.privacyPolicies?.content?.[privacy?.index]
      : undefined;

  const [policyValue, setPolicyValue] = useState<Policy>(
    policy || { entity: undefined, url: "", name: "" },
  );

  const setValue = (n: keyof Policy, v: any) => {
    setPolicyValue((state) => (state = Object.assign({}, state, { [n]: v })));
  };

  const updatePrivacyPolicies = () => {
    if (policyValue.entity && policyValue.name && policyValue.url) {
      let uPolicies = [...policies];
      const uPolicy = {
        ...policyValue,
        entity: policyValue.entity,
        url: updateURL(policyValue.url),
      } as PrivacyPolicy;

      if (privacy?.index !== undefined) {
        uPolicies.splice(privacy.index, 1, uPolicy);
      } else {
        if (
          policyValue.entity === "artist" &&
          policies.findIndex((p) => p.entity === "artist") === -1
        ) {
          uPolicies.unshift(uPolicy);
        } else {
          uPolicies.push(uPolicy);
        }
      }
      setField("privacyPolicies", uPolicies);
      setModal({});
    }
  };

  const invalidFields =
    !policyValue.entity || !policyValue.name || !policyValue.url;

  return (
    <Modal
      header="Add a privacy policy"
      isOpen
      onClose={() => setModal({})}
      variant="small"
    >
      <EditableContent
        label="Entity"
        placeholder="Select an option..."
        style={{ marginTop: 32 }}
        editState={
          <Select
            secondary
            options={entities}
            value={policyValue.entity}
            onChange={(v) => setValue("entity", v)}
          />
        }
      />

      <EditableContent
        label="Name"
        placeholder="Entity name..."
        secondary
        onChange={(v) => setValue("name", v)}
        value={policyValue.name}
      />

      <TextInput
        label="Policy URL"
        placeholder="Enter a url..."
        secondary
        onChange={(v) => setValue("url", v)}
        onBlur={() => {
          if (!!policyValue.url) {
            setValue("url", updateURL(policyValue.url));
          }
        }}
        value={policyValue.url}
        style={{ margin: "16px 0 8px" }}
      />

      <Checkbox
        value={policyValue.isSeparate}
        onChange={(v) => setValue("isSeparate", v)}
        label="Display this agreement as a separate checkbox on the registration form."
        style={{ margin: "18px 0 8px 0" }}
        // TODO: Remove this restriction once fan app can handle more than 2 separate checkboxes
        disabled={
          policies?.filter((p) => p.isSeparate)?.length > 1 &&
          !policy?.isSeparate
        }
      />

      <Actions>
        <Button text onClick={() => setModal({})}>
          Cancel
        </Button>
        <Button disabled={invalidFields} onClick={updatePrivacyPolicies}>
          Save
        </Button>
      </Actions>
    </Modal>
  );
};

const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    min-width: 120px;
    & + button {
      margin-left: 12px;
    }
  }
`;
