import { useBuilderContext, useConfigSlice } from "Components";
import { H2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button as StyledButton } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { SvgMusicAlt } from "melodies-source/Svgs/MusicAlt";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { SvgBack } from "melodies-source/Svgs/Back";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import useSpotify from "Components/hooks/useSpotify";
import { InfoCard } from "Components/InfoCard";
import { SvgRight } from "melodies-source/Svgs/Right";
import { IconCheckInCard } from "Images/CardIcons";
import AddSongModal from "Components/AddSongModal";

export const CheckInVote = () => {
  const nav = useNavigate();
  const { config } = useBuilderContext();
  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "checkInVote",
    "voteType",
    "voteOptions",
    "songOptions",
  ]);

  const [searchInput, setSearchInput] = useState<string[]>([]);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (!fields?.songOptions?.content?.length) {
      setField("songOptions", [
        { name: "", album: "" },
        { name: "", album: "" },
      ]);
    }
    if (!fields?.voteOptions?.content?.length) {
      setField("voteOptions", [{ en: "" }, { en: "" }]);
    }
  }, [fields?.voteOptions?.content, fields?.songOptions?.content]);

  const token = useSpotify();

  const getOptions = async (searchInput: string) => {
    let artistName = config?.artistName;
    if (!searchInput) return [];
    if (token && artistName) {
      const artistParameters = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = new URL("https://api.spotify.com/v1/search");
      const params = new URLSearchParams({
        q: `${searchInput} artist:${artistName}`,
        type: "track",
      });
      url.search = params.toString();
      return fetch(url.toString(), artistParameters)
        .then((res) => res.json())
        .then((data) => {
          return data?.tracks?.items?.map((track: any) => ({
            value: track.name,
            label: track.name,
            caption: track.album.name,
            icon: (
              <SvgMusicAlt
                style={{ width: 15, height: 15, marginTop: "-5px" }}
              />
            ),
            spotifyId: track.id,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Container>
      <Button
        text
        leftIcon={<SvgBack />}
        style={{ width: "fit-content", margin: "0 0 10px -15px" }}
        onClick={() => nav("../location")}
        nudgeLeft
      >
        Back
      </Button>
      <SectionToggle>
        <H2>Do you want your fans to check in or vote?</H2>
        <ButtonContainer>
          <Button
            variant="round"
            active={fields.checkInVote.content === "checkin" ? true : false}
            onClick={() => {
              setField("checkInVote", "checkin");
              if (fields.voteType) {
                setField("voteType", "");
              }
            }}
          >
            Check-In
          </Button>
          <Button
            variant="round"
            active={fields.checkInVote.content === "vote" ? true : false}
            onClick={() => setField("checkInVote", "vote")}
          >
            Vote
          </Button>
        </ButtonContainer>
      </SectionToggle>

      {fields.checkInVote.content === "vote" && (
        <>
          <SectionToggle>
            <H2>Select what your fans will be voting on:</H2>
            <ButtonContainer>
              <Button
                variant="round"
                active={fields.voteType.content === "song" ? true : false}
                onClick={() => {
                  setField("voteType", "song");
                }}
              >
                Songs
              </Button>
              <Button
                variant="round"
                active={fields.voteType.content === "vote" ? true : false}
                onClick={() => setField("voteType", "vote")}
              >
                Something Else
              </Button>
            </ButtonContainer>
          </SectionToggle>
          {fields.voteType.content === "song" && (
            <Section>
              {fields?.songOptions?.content?.map((o, idx: number) => {
                return (
                  <VoteContainer key={idx}>
                    <AutocompleteAsync
                      secondary
                      text={o.name || searchInput?.[idx]}
                      setText={(v: string) => {
                        let copy = [...searchInput!] || [];
                        copy[idx] = v;
                        setSearchInput(copy);
                      }}
                      onChange={(v: any) => {
                        let update = [...fields.songOptions.content!];
                        update[idx] = {
                          name: v.label,
                          album: v.caption || "",
                          spotifyId: v.spotifyId,
                        };
                        setField("songOptions", update);
                      }}
                      label={`Song ${idx + 1}`}
                      placeholder="Search for a song"
                      getOptions={getOptions}
                      //@ts-ignore
                      hideDropdown={false}
                      buttonText="Manually add a song"
                      //@ts-ignore
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      setCustom={() => setOpenIndex(idx + 1)}
                    />

                    <Button
                      variant="primary"
                      text
                      style={{
                        visibility:
                          (idx === 0 || idx === 1) &&
                          fields?.songOptions?.content!.length < 3
                            ? "hidden"
                            : "visible",
                      }}
                      onClick={() => {
                        let update = [...fields.songOptions.content!];
                        update.splice(idx, 1);
                        setField("songOptions", update);
                        let listUpdate = [...searchInput];
                        listUpdate.splice(idx, 1);
                        setSearchInput(listUpdate);
                      }}
                    >
                      <SvgDelete style={{ width: 50, height: 50 }} />
                    </Button>
                  </VoteContainer>
                );
              })}

              {!fields.songOptions?.content?.find(
                (song) => song.name === ""
              ) && (
                <AddButton
                  variant="primary"
                  text
                  style={{
                    textDecoration: "underline",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    let update = [...fields?.songOptions?.content!];
                    update.push({ name: "", album: "" });
                    setField("songOptions", update);
                  }}
                >
                  + Add a song
                </AddButton>
              )}
            </Section>
          )}
          {fields.voteType.content === "vote" && (
            <Section>
              {fields.voteOptions.content?.map((o, idx: number) => {
                return (
                  <VoteContainer key={idx}>
                    <TextInput
                      placeholder="Option"
                      secondary
                      label={`Option ${idx + 1} ${
                        idx === 0 ? "(15 char max)" : ""
                      }`}
                      onChange={(v) => {
                        let update = [...fields.voteOptions.content!];
                        update[idx] = { en: v };
                        setField("voteOptions", update);
                      }}
                      value={o.en}
                    />
                    {!(
                      (idx === 0 || idx === 1) &&
                      fields?.voteOptions?.content!.length < 3
                    ) && (
                      <Button
                        variant="primary"
                        text
                        onClick={() => {
                          let update = [...fields.voteOptions.content!];
                          update.splice(idx, 1);
                          setField("voteOptions", update);
                        }}
                      >
                        <SvgDelete style={{ width: 50, height: 50 }} />
                      </Button>
                    )}
                  </VoteContainer>
                );
              })}
              {!fields.voteOptions?.content?.find((song) => song.en === "") && (
                <AddButton
                  variant="primary"
                  text
                  style={{
                    width: "fit-content",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    let update = [
                      ...(fields.voteOptions?.content || []),
                      { en: "" },
                    ];
                    setField("voteOptions", update);
                  }}
                >
                  + Add Item
                </AddButton>
              )}
            </Section>
          )}
        </>
      )}
      <Button
        style={{ width: 180, marginTop: 40 }}
        disabled={!isValid}
        onClick={() => {
          save();
          nav(`../sweepstakes`);
        }}
        rightIcon={<SvgRight />}
        nudgeRight
      >
        Next
      </Button>
      <InfoCard
        text="For check-in and voting options, you will be able to change the text from what is displayed here. Fans will not be able to see the results of a vote. "
        icon={<IconCheckInCard />}
      />
      <AddSongModal
        setField={setField}
        fields={fields}
        openIndex={openIndex}
        setOpenIndex={setOpenIndex}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 380px;
  color: #ffffff;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const Button = styled(StyledButton)``;

const AddButton = styled(StyledButton)`
  margin-top: 16px;
`;

const VoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > button {
    width: 45px;
    margin: 23px 0px -23px 0;
  }

  & + & {
    margin-top: 24px;
  }
`;

const ScrollContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 13px;
  width: fit-content;
  margin: 20px 0 0;
`;

const SectionToggle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  & + & {
    margin-top: 32px;
  }
`;
