import { Label, Selected } from "melodies-source/Text";
import styled from "styled-components";
import { HTMLTextearea, Textarea } from "melodies-source/Textarea";
import { Select } from "melodies-source/Select";
import { LanguageCode, SweepstakesRulesType } from "@max/common";
import { SetLiveRule } from "hooks/useSetLiveRules";
import { EditIconButton, useConfigSlice } from "Components";
import { AutocompleteBase } from "melodies-source/Autocomplete";
import { useState } from "react";
import { SvgDocument } from "melodies-source/Svgs/Document";
import { Link } from "melodies-source/Link";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { TextInput } from "melodies-source/TextInput";
import { SectionContainer } from "../Slices/AdvancedControls";

export const RulesSelect = ({
  language,
  rules,
  ...props
}: {
  language: LanguageCode | "en";
  rules?: SetLiveRule[];
} & React.HTMLAttributes<HTMLDivElement>) => {
  const [query, setQuery] = useState("");
  const [fields, validation, setField] = useConfigSlice([
    "sweepstakes",
    "sweepstakesRulesType",
    "sweepstakesRulesLink",
    "sweepstakesRulesHtml",
    "sweepstakesRulesCustomUrl",
  ]);

  const formattedRules =
    rules
      ?.map((r) => ({
        label: r.name,
        value: r.slug,
        icon: <SvgDocument />,
      }))
      ?.filter((o) => o.label.toLowerCase().includes(query.toLowerCase())) ||
    [];

  const selectedRule = formattedRules?.find(
    (r) => r.value === fields.sweepstakesRulesLink.content?.[language],
  );
  const generatedUrl =
    (process.env.REACT_APP_RULES_URL || "https://rules.set.live") +
    `/${fields.sweepstakesRulesLink.content?.en}`;

  return (
    <StyledSectionContainer {...props}>
      {language === "en" && (
        <StyledSelect
          label="Sweepstakes Rules Type"
          value={fields.sweepstakesRulesType.content}
          disabled={!fields.sweepstakes.content}
          onChange={(v) => setField("sweepstakesRulesType", v)}
          options={RULES_TYPE_OPTIONS}
        />
      )}
      {fields.sweepstakesRulesType.content === "link" &&
        (selectedRule ? (
          <FlexColumn>
            <Label style={{ marginBottom: 4 }}>Sweepstakes Rules Link</Label>
            <FlexRow>
              <Link href={generatedUrl} target="_blank">
                {selectedRule?.label}
              </Link>

              <EditIconButton
                onClick={() => {
                  setField("sweepstakesRulesLink", {
                    ...fields.sweepstakesRulesLink.content,
                    [language]: "",
                  });
                  setQuery("");
                }}
                style={{ marginLeft: 8 }}
              >
                <SvgEditAlt />
              </EditIconButton>
            </FlexRow>
          </FlexColumn>
        ) : (
          <AutocompleteBase
            label={fields.sweepstakesRulesLink.label}
            placeholder="Search for a rule..."
            value={selectedRule}
            setText={setQuery}
            text={query}
            hasError={!!validation.sweepstakesRulesLink}
            onChange={(v) =>
              setField("sweepstakesRulesLink", {
                ...fields.sweepstakesRulesLink.content,
                [language]: v.value,
              })
            }
            options={formattedRules}
            {...(!!validation.sweepstakesRulesLink && {
              helperText: validation.sweepstakesRulesLink,
            })}
          />
        ))}
      {fields.sweepstakesRulesType.content === "html" && (
        <StyledTextarea
          label={fields.sweepstakesRulesHtml.label}
          placeholder="Enter HTML"
          value={fields.sweepstakesRulesHtml.content?.[language]}
          disabled={!fields.sweepstakes.content}
          helperText={validation.sweepstakesRulesHtml || ""}
          hasError={!!validation.sweepstakesRulesHtml}
          onChange={(v) => setField("sweepstakesRulesHtml", { [language]: v })}
        />
      )}
      {fields.sweepstakesRulesType.content === "custom" && (
        <TextInput
          label={fields.sweepstakesRulesCustomUrl.label}
          value={fields.sweepstakesRulesCustomUrl.content?.[language] || ""}
          disabled={!fields.sweepstakes.content}
          helperText={validation.sweepstakesRulesCustomUrl || ""}
          hasError={!!validation.sweepstakesRulesCustomUrl}
          onChange={(v) =>
            setField("sweepstakesRulesCustomUrl", { [language]: v })
          }
        />
      )}
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled(SectionContainer)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 80%;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  ${HTMLTextearea} {
    font-size: 15px;
    line-height: 20px;
    min-height: 120px;
  }
`;

const StyledSelect = styled(Select)`
  ${Selected} {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
`;

const RULES_TYPE_OPTIONS: { label: string; value: SweepstakesRulesType }[] = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Saved Rules",
    value: "link",
  },
  {
    label: "HTML",
    value: "html",
  },
  {
    label: "Custom URL",
    value: "custom",
  },
];
