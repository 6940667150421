import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Subtitle1 } from "melodies-source/Text";

interface LoadingTypes {
  svg?: ReactElement;
  text?: string | ReactElement | boolean;
  svgArray?: ReactElement[];
  textArray?: string[];
}
export const SpinnerRing = ({
  svg,
  text,
  svgArray,
  textArray,
}: LoadingTypes) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!!textArray) {
        setSeconds((second) =>
          second < textArray?.length - 1 ? second + 1 : textArray?.length - 1,
        );
      }
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoadingContainer>
      <SpinnerContainer>
        {svgArray && svgArray[seconds]}
        {svg ? (
          svg
        ) : (
          <Svg
            width="80"
            height="80"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.9016 0.600098C50.0614 0.600098 51.0016 1.5403 51.0016 2.7001V4.8001H55.2016C57.5212 4.8001 59.4016 6.6805 59.4016 9.0001V55.2001C59.4016 57.5197 57.5212 59.4001 55.2016 59.4001H4.80156C2.48197 59.4001 0.601562 57.5197 0.601562 55.2001V9.0001C0.601562 6.6805 2.48197 4.8001 4.80156 4.8001H9.00156V2.7001C9.00156 1.5403 9.94177 0.600098 11.1016 0.600098C12.2614 0.600098 13.2016 1.5403 13.2016 2.7001V4.8001H46.8016V2.7001C46.8016 1.5403 47.7418 0.600098 48.9016 0.600098ZM55.2008 21.6001H4.80078V55.2001H55.2008V21.6001ZM51.0016 42.6001V51.0001H42.6016V42.6001H51.0016ZM9.00078 9H4.80078V17.4H55.2008V9H51.0008V11.1C51.0008 12.2598 50.0606 13.2 48.9008 13.2C47.741 13.2 46.8008 12.2598 46.8008 11.1V9H13.2008V11.1C13.2008 12.2598 12.2606 13.2 11.1008 13.2C9.94098 13.2 9.00078 12.2598 9.00078 11.1V9Z"
              fill="white"
            />
          </Svg>
        )}

        <Svg
          width="170"
          height="170"
          viewBox="0 0 200 200"
          color="#7275d0"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="spinnerSecondHalf">
              <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
            </linearGradient>
            <linearGradient id="spinnerFirstHalf">
              <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
            </linearGradient>
          </defs>

          <g strokeWidth="8">
            <path
              stroke="url(#spinnerSecondHalf)"
              d="M 4 100 A 96 96 0 0 1 196 100"
            />
            <path
              stroke="url(#spinnerFirstHalf)"
              d="M 196 100 A 96 96 0 0 1 4 100"
            />

            <path
              stroke="currentColor"
              strokeLinecap="round"
              d="M 4 100 A 96 96 0 0 1 4 98"
            />
          </g>
          <animateTransform
            from="0 0 0"
            to="360 0 0"
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1300ms"
          />
        </Svg>
      </SpinnerContainer>
      {text && text}
      {textArray && <Subtitle1>{textArray[seconds]}</Subtitle1>}
    </LoadingContainer>
  );
};

const Svg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: auto;
`;
const SpinnerContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 220px;
  align-items: center;
  justify-content: center;
`;
