import { EditableContent, useBuilderContext, useConfigSlice } from "Components";
import { H2, Body2, Body1, H3 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Link } from "melodies-source/Link";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { SvgBack } from "melodies-source/Svgs/Back";
import { Checkbox } from "melodies-source/Selectable";
import { SvgRight } from "melodies-source/Svgs/Right";
import { updateURL } from "Utils/helpers";
import { Spinner } from "melodies-source/Spinner";

export const Privacy = () => {
  const nav = useNavigate();
  const { config, publish, publishStatus } = useBuilderContext();
  const [edit, setEdit] = useState<"policy" | null>(null);
  const [manualEntry, setManualEntry] = useState(false);
  const [fields, validation, setField, isValid, save] = useConfigSlice([
    "privacyPolicyConfirm",
    "privacyPolicies",
    "privacyPolicyDeclined",
  ]);
  const artistPolicy = fields.privacyPolicies.content.find(
    ({ entity }) => entity === "artist"
  );

  const updatePrivacyPolicies = (v?: string, remove?: boolean) => {
    let filteredPolicies =
      fields.privacyPolicies.content?.filter(
        ({ entity }) => entity !== "artist"
      ) || [];

    if (!remove) {
      filteredPolicies.push({
        name: config.artistName || "",
        url: updateURL(v),
        entity: "artist",
        source: "manual",
        isSeparate: false,
      });
      setField("privacyPolicyConfirm", true);
    }

    setField("privacyPolicies", filteredPolicies);
    setEdit(null);
  };

  useEffect(() => {
    if (
      (!artistPolicy?.url || !artistPolicy?.source) &&
      !fields.privacyPolicyDeclined.content
    ) {
      setEdit("policy");
      setManualEntry(true);
    }
  }, []);

  const handleNext = () => {
    if (publishStatus === "publishing") {
      return;
    }
    if (fields.privacyPolicyDeclined.content) {
      updatePrivacyPolicies(undefined, true);
    }
    save("publish"); //todo: fix this double call nonesense
    publish(undefined, () => {
      nav(`../publish`);
    });
  };

  const isManual =
    artistPolicy?.source === "manual" || fields.privacyPolicyDeclined.content;

  return (
    <Container>
      <Button
        text
        leftIcon={<SvgBack />}
        style={{ width: "fit-content", margin: "0 0 10px -15px" }}
        onClick={() => nav("../postregistration")}
        nudgeLeft
      >
        Back
      </Button>
      {manualEntry || isManual ? (
        <>
          {!fields.privacyPolicyDeclined.content && (
            <>
              <H2>Add your privacy policy</H2>
              <EditableContent
                label="Privacy Policy Link"
                placeholder="Paste your URL here"
                name="policy"
                secondary
                onConfirm={(v) => updatePrivacyPolicies(v)}
                onCancel={isManual ? () => setEdit(null) : undefined}
                edit={edit}
                setEdit={setEdit}
                disabled={fields.privacyPolicyDeclined.content}
                value={
                  artistPolicy?.source === "manual"
                    ? artistPolicy?.url
                    : undefined
                }
                style={{ marginBottom: 40 }}
              >
                {artistPolicy?.url && <H3>{artistPolicy?.url}</H3>}
              </EditableContent>
            </>
          )}
          <H2>Don't have a policy?</H2>
          <StyledCheckbox
            value={fields.privacyPolicyDeclined.content}
            onChange={(v) => {
              setManualEntry(true);
              if (v || (!v && artistPolicy?.source === "manual")) {
                setEdit(null);
              } else {
                setEdit("policy");
              }
              setField("privacyPolicyDeclined", v);
            }}
            label={
              <Body1 style={{ marginBottom: 4 }}>
                Check to decline providing a privacy policy.
              </Body1>
            }
            description="By checking this box you acknowledge and agree that you are solely responsible for ensuring that your use of SET.Live complies with all applicable laws, including privacy laws and that you will provide all necessary privacy notices to your site visitors in accordance with applicable laws. SET.Live makes no representations or guarantees that the SET.Live privacy policy is sufficient for your use and is provided “as is”. You further agree to indemnify, hold harmless, and at our option, defend SET.Live and its affiliates from and against any claim, demand, suit or proceeding made or brought against SET.Live or its affiliates by a third party related to your use of the SET.Live page in violation of privacy rights or laws."
          />
        </>
      ) : (
        <>
          <H2 style={{ color: "#fff" }}>
            Please confirm that this is your current privacy policy
          </H2>
          <Link
            style={{ margin: "15px 0", fontWeight: 400 }}
            // @ts-ignore
            target="_blank"
            href={artistPolicy?.url}
          >
            {artistPolicy?.url}
          </Link>
          <Body1 style={{ color: "#999999" }}>
            Found on {artistPolicy?.source}
          </Body1>
          <ButtonContainer>
            <Button
              variant="round"
              style={{ width: 80 }}
              active={fields.privacyPolicyConfirm.content ? true : false}
              onClick={() => setField("privacyPolicyConfirm", true)}
            >
              Yes
            </Button>
            <Button
              variant="round"
              style={{ width: 80 }}
              onClick={() => {
                setField("privacyPolicyConfirm", false);
                setManualEntry(true);
                setEdit("policy");
              }}
            >
              No
            </Button>
          </ButtonContainer>
        </>
      )}
      <Button
        onClick={handleNext}
        style={{ width: 180 }}
        rightIcon={publishStatus !== "publishing" ? <SvgRight /> : undefined}
        nudgeRight
        disabled={
          !(
            !edit &&
            (fields.privacyPolicyDeclined.content ||
              fields.privacyPolicyConfirm.content)
          ) || publishStatus === "publishing"
        }
      >
        {publishStatus === "publishing" ? (
          <Spinner color={"rgb(27, 0, 118)"} />
        ) : (
          "Next"
        )}
      </Button>
      {!!fields.privacyPolicyConfirm.content && !isManual && (
        <Body2 style={{ margin: "20px 35px 0 0" }}>
          By selecting “YES” you agree that the linked privacy policy is current
          and active, and that the artist assumes all responsibility for any
          issues arising from this policy.
        </Body2>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 400px;
  color: #ffffff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 13px;
  width: fit-content;
  margin: 20px 0 40px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 12px 0 40px;
  h4 {
    margin: 10px 20px 0 0;
    font-size: 12px;
    font-weight: 400;
  }
`;
