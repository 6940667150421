import { SvgCheck } from "melodies-source/Svgs/Check";
import styled from "styled-components";

export type ColorPicker = {
  darkColor: string;
  lightColor?: string;
  checked: boolean;
  row?: boolean;
  templateCheck?: boolean;
  setColor: () => void;
};

export const ColorOptions = ({
  darkColor,
  lightColor,
  checked,
  setColor,
  row,
  templateCheck,
}: ColorPicker) => {
  return (
    <Content onClick={() => setColor()}>
      {checked && (
        <Overlay
          row={row}
          templateCheck={templateCheck}
          style={{
            transform: "rotate(315deg)",
            margin: "7px 0 0 7px",
          }}
        >
          <SvgCheck />
        </Overlay>
      )}
      {lightColor ? (
        <>
          <Right color={lightColor} row={row}></Right>
          <Left color={darkColor} row={row}></Left>
        </>
      ) : (
        <Square color={darkColor} row={row} />
      )}
    </Content>
  );
};

const Content = styled.div`
  color: #ffffff;
  transform: rotate(45deg);
`;
const Left = styled.div<{ color: string; row?: boolean }>`
  width: 0;
  height: 0;
  border-left: ${({ row }) =>
    row ? "45px solid transparent" : "28px solid transparent"};
  border-right: ${({ row }) =>
    row ? "45px solid transparent" : "28px solid transparent"};
  border-top: ${({ row }) => (row ? "45px" : "28px")} solid
    ${({ color }) => color};
`;
const Right = styled.div<{ color: string; row?: boolean }>`
  width: 0;
  height: 0;
  border-left: ${({ row }) =>
    row ? "45px solid transparent" : "28px solid transparent"};
  border-right: ${({ row }) =>
    row ? "45px solid transparent" : "28px solid transparent"};
  border-bottom: ${({ row }) => (row ? "45px" : "28px")} solid
    ${({ color }) => color};
`;
const Square = styled.div<{ color: string; row?: boolean }>`
  width: ${({ row }) => (row ? "64px" : "40px")};
  height: ${({ row }) => (row ? "64px" : "40px")};
  margin: 8px;
  background-color: ${({ color }) => color};
  position: relative;
  transform: rotate(315deg);
`;
const Overlay = styled.div<{ row?: boolean; templateCheck?: boolean }>`
  height: ${({ row }) => (row ? "65px" : "40px")};
  width: ${({ row }) => (row ? "65px" : "40px")};
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid white;
  z-index: 10;
  position: absolute;
  top: ${({ templateCheck }) => (templateCheck ? "6px" : "0")};
  left: ${({ templateCheck }) => (templateCheck ? "6px" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: white;
    width: 28px;
    height: 28px;
    filter: drop-shadow(0px 0px 1.5px rgb(0 0 0 / 0.9));
  }
`;
