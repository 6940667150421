import { Card as CardBase, CardProps } from "melodies-source/Card";
import { SvgInformationAlt } from "melodies-source/Svgs/InformationAlt";
import { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";

const Card = (props: CardProps) => {
  useEffect(() => {
    Aos.init({
      duration: 400,
      delay: 1000,
      anchorPlacement: "top-top",
      offset: -50,
    });
  }, []);

  const propsFade = {
    "data-aos": "fade-up",
    "data-aos-easing": "ease-in-out",
    "data-aos-once": "true",
  };

  return (
    <CardBase
      {...propsFade}
      {...props}
      icon={
        props.icon ? (
          props.icon
        ) : (
          <SvgInformationAlt
            style={{ width: 44, height: 44, color: "#057BAD" }}
          />
        )
      }
    />
  );
};

export const InfoCard = styled(Card)`
  background: #383441;
  max-width: 380px;
  margin-top: 32px;
`;
