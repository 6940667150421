import {
  BuilderConfig,
  Fields,
  generatePreRegText,
  generatePostRegText,
  TranslatableContent,
} from "@max/common/creator";

export const allFields = [
  "preRegistrationHeadline",
  "preRegistrationBody",
  "preRegistrationSubtitle",
  "registerTitle",
  "registerCtaLabel",
  "postRegistrationHeadline",
  "postRegistrationBody",
] as Fields[];

export type CopyField = (typeof allFields)[number];

export const setTranslatableFields = (
  config: BuilderConfig,
  setConfig: React.Dispatch<React.SetStateAction<BuilderConfig>>,
  fields?: CopyField[],
) => {
  const setField = (fieldName: Fields, content: TranslatableContent) => {
    let nconfig = { ...config };
    nconfig.fields[fieldName].content = content;
    //@ts-ignore
    nconfig.fields[fieldName].isDirty = true;
    setConfig(nconfig);
  };

  const fieldList = fields || allFields;

  const { preRegText } = generatePreRegText(config);
  const postRegText = generatePostRegText(config);

  const renamedFields: { [K in Fields]?: string } = {
    preRegistrationHeadline: preRegText.headline,
    preRegistrationBody: preRegText.body,
    preRegistrationSubtitle: preRegText.subtitle,
    registerTitle: preRegText.registerTitle,
    registerCtaLabel: preRegText.ctalabel,
    postRegistrationHeadline: postRegText.headline,
    postRegistrationBody: postRegText.body,
  };

  for (const index in fieldList) {
    const key = fieldList[index];
    if (key in renamedFields) {
      const stringValue = renamedFields?.[key];
      if (stringValue) {
        setField(key, {
          en: stringValue,
        });
      }
    }
  }
};

export const validateURL = (url?: string) => {
  return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(
    url || "",
  );
};

export const updateURL = (url?: string) => {
  if (!url) return "";
  return /(http(s?)):\/\//i.test(url) ? url : "https://" + url;
};
