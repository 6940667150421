import styled, { css } from "styled-components";

export const EditIconButtonContainer = styled.div<{ disabled?: boolean }>`
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${(p) => p.theme.transitions("color").hover};
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  max-height: 28px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.7;
  }

  ${(p) =>
    p.disabled &&
    css`
      color: #4c4c4c;
      cursor: default;
      pointer-events: none;
    `};
`;

interface EditIconButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const EditIconButton = ({ children, ...props }: EditIconButtonProps) => {
  return (
    <EditIconButtonContainer {...props}>{children}</EditIconButtonContainer>
  );
};
